// @flow
import { createAction } from 'redux-actions';

import { type RestfulAPIsList, type NetworkServicesList } from '@dt/horizon-api';

export const restfulAPIsReceived = createAction<
  'restful_apis/received',
  [RestfulAPIsList, NetworkServicesList],
  {| restful_apis: RestfulAPIsList, network_services: NetworkServicesList |},
>('restful_apis/received', (restful_apis, network_services) => ({
  restful_apis,
  network_services,
}));

export const restfulAPIDetailsMounted = createAction<
  'mounted/restful_api_details',
  [string],
  {
    restfulAPIId: string,
    key: 'restful_api_details',
    ...
  },
>('mounted/restful_api_details', restfulAPIId => ({
  restfulAPIId: restfulAPIId,
  key: 'restful_api_details',
}));

export const reachedEndOfRestfulApisList = createAction<'restful_apis/list/reached_end', [], void>(
  'restful_apis/list/reached_end',
  () => undefined,
);

export const reachedEndOfRestfulApisListForAssetSearchId = createAction<
  'restful_apis/list/search/reached_end',
  [string],
  string,
>('restful_apis/list/search/reached_end', search_id => search_id);

export const restfulAPIsReceivedIdsForAssetSearch = createAction<
  'restful_apis/search/ids/received',
  [string, Array<string>],
  {|
    searchId: string,
    ids: Array<string>,
  |},
>('restful_apis/search/ids/received', (searchId, ids) => ({
  searchId,
  ids,
}));
