// @flow
import { type RestfulAPIDecorated } from '../restful_apis/types';
import type { APIOperation } from '@dt/horizon-api';

export type IncludedOperationList = $ReadOnlyArray<APIOperation>;
export type ExcludedOperationList = $ReadOnlyArray<string>;

export const DETECT_INJECT_POLICY_RULE_TYPE_ID = '92eb7e9e-9daa-5922-8167-6a219e0f7287';

export type resultsType =
  | {|
      enabled: false,
      sampleClicked: boolean,
    |}
  | {|
      enabled: true,
      clicked: boolean,
      date_completed: ?string,
      sampleClicked: boolean,
    |};

export type detectType =
  | {|
      enabled: false,
    |}
  | {|
      enabled: true,
      started: boolean,
    |};

export type injectType =
  | {|
      enabled: false,
    |}
  | {|
      enabled: true,
      started: boolean,
    |};

export type scheduleType = {|
  clicked: boolean,
|};

export type ConfigurationMethodsType = {
  GET: boolean,
  POST: boolean,
  PUT: boolean,
  DELETE: boolean,
  PATCH: boolean,
  UNKNOWN: boolean,
  HEAD: boolean,
  OPTIONS: boolean,
  TRACE: boolean,
  X_HORIZON_UNDEFINED_HTTP_METHOD: boolean,
  ...
};

export type DetectTableDecoratedType = {|
  ...APIOperation,
  base_url: ?string,
  base_path: ?string,
  title: ?string,
|};

export type DetectInjectDecoratedListType = $ReadOnlyArray<DetectTableDecoratedType>;

export type InjectTableDecoratedType = {|
  ...DetectTableDecoratedType,
  randomOffset: number,
|};

export type InjectTableDecoratedListType = $ReadOnlyArray<InjectTableDecoratedType>;

type VulnerableType =
  | {|
      unresolved: true,
      additional_info: string,
    |}
  | {|
      unresolved: false,
    |};

export type ResultsTableOperationDecoratedType = {|
  ...APIOperation,
  asset_type: 'operation',
  apiId: string,
  base_url: string,
  base_path: string,
  vulnerable: VulnerableType,
|};

export type ResultsTableDecoratedType = RestfulAPIDecorated | ResultsTableOperationDecoratedType;

export type ResultsTableDecoratedListType = $ReadOnlyArray<ResultsTableDecoratedType>;
