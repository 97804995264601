// @flow
import FindingTargetStatusEnum from '@dt/enums/FindingTargetStatusEnum';
import { flatMap, flow, filter, map } from 'lodash/fp';
import max from 'date-fns/max';
import differenceInCalendarDays from 'date-fns/difference_in_calendar_days';
import { type SecurityFinding } from './types';

export default function(finding: SecurityFinding): number {
  const dateOfLastOpen = flow(
    flatMap(({ statuses }) => statuses),
    filter(({ status }) =>
      [FindingTargetStatusEnum.OPEN, FindingTargetStatusEnum.NEW].includes(
        status,
      ),
    ),
    map(({ date }) => date),
    dates => max(...dates),
  )(finding.targets);

  return differenceInCalendarDays(new Date(), dateOfLastOpen); // UTC?
}
