// @flow
import config from '@dt/config';
import fetch, { parse, type APIResult as FetchResponse } from '@dt/fetch';

const api = config.horizonApiBaseUrl;
const version = 'v1';

export type CloudResourceType = {|
  enum: string,
  icon_url: string,
  name: string,
  category_enum: string,
  category_name: string,
|};

export type CloudResourceTypesList = $ReadOnlyArray<CloudResourceType>;

export type CloudResourceTypesResponse = {|
  cloud_resource_types: CloudResourceTypesList,
|};

export async function list(): FetchResponse<CloudResourceTypesResponse> {
  return fetch(`${api}/${version}/cloud_resource_types`).then(parse);
}
