// @flow
import has from 'lodash/fp/has';
import BadgeType, { type BadgeTypeEnum } from './BadgeTypeEnum';
import appProtectionBadgeImages from './images';
import type { AppProtectionTask } from '@dt/user-api/mobile_apps';
import { type AppProtectionMetrics } from '@dt/user-api/app_protection_metrics';
import { Raven } from '@dt/global';
import {
  default as MobileAppPlatformEnum,
  type MobileAppPlatformEnum as MobileAppPlatformEnumType,
} from '@dt/enums/MobileAppPlatformEnum';

export type AppWithBadgeInfo = {
  +all_communication_encrypted: boolean,
  +supports_two_factor_authentication: boolean,
  +full_protection_on_untrusted_networks: boolean,
  +protects_data_on_screens: boolean,
  +requires_android_device_security: boolean,
  +requires_android_malware_protection: boolean,
  +server_enforces_https: boolean,
  +removes_data_from_shared_device_locations: boolean,
  +protects_the_keyboard: boolean,
  +leverages_secure_system_sdk: boolean,
  +leverages_secure_browser_settings: boolean,
  +leverages_secure_system_libraries: boolean,
  +leverages_secure_system_settings: boolean,
  +enhances_password_security: boolean,
  +does_not_offload_data_to_third_parties: boolean,
  ...
};

export function getCompletedBadgesForApp(flags: $ReadOnlyArray<BadgeTypeEnum>, app: AppWithBadgeInfo) {
  return flags.reduce((acc, badge_key) => {
    if (app[badge_key]) acc[badge_key] = app[badge_key];
    return acc;
  }, {});
}

export function getIncompleteBadgesForApp(flags: $ReadOnlyArray<BadgeTypeEnum>, app: AppWithBadgeInfo) {
  return flags.reduce((acc, badge_key) => {
    if (app[badge_key] === false) acc[badge_key] = app[badge_key];
    return acc;
  }, {});
}

export function getAppProtectionBadgeKeyByFindingTitle(
  title: string,
  badgesMap: $ReadOnlyArray<{ [string]: string, ... }>,
): BadgeTypeEnum | null {
  const item = badgesMap.find(badgeMap => badgeMap.securityTaskTitle === title);

  // $FlowFixMe
  return item ? item.protectionBadgeKey : null;
}

export function getCompletedAppProtectionBadgeKeyByBadgeTitle(
  title: string,
  app: AppWithBadgeInfo,
): BadgeTypeEnum | null {
  const appProtectionBadgeKeys = securityTaskToProtectionBadgeMap.map(badgeMap => badgeMap.protectionBadgeKey);
  const completedBadges = getCompletedBadgesForApp(appProtectionBadgeKeys, app);

  const completedBadgesMapping = securityTaskToProtectionBadgeMap.filter(
    badgeMap => completedBadges[badgeMap.protectionBadgeKey],
  );

  return getAppProtectionBadgeKeyByFindingTitle(title, completedBadgesMapping);
}

export function getIncompleteAppProtectionBadgeKeyByBadgeTitle(
  title: string,
  app: AppWithBadgeInfo,
): BadgeTypeEnum | null {
  const appProtectionBadgeKeys = securityTaskToProtectionBadgeMap.map(badgeMap => badgeMap.protectionBadgeKey);
  const incompleteBadges = getIncompleteBadgesForApp(appProtectionBadgeKeys, app);

  const incompleteBadgesMapping = securityTaskToProtectionBadgeMap.filter(
    badgeMap => incompleteBadges[badgeMap.protectionBadgeKey],
  );

  return getAppProtectionBadgeKeyByFindingTitle(title, incompleteBadgesMapping);
}

export function hasApplicableTasksForBadge(tasksForBadge: $ReadOnlyArray<AppProtectionTask>): boolean {
  return typeof tasksForBadge.find(task => typeof task.security_finding_id !== 'undefined') !== 'undefined';
}

export const securityTaskToProtectionBadgeMap = [
  {
    protectionBadgeKey: 'full_protection_on_untrusted_networks',
    securityTaskTitle: 'Enable Data Protection Entitlement',
  },
  {
    protectionBadgeKey: 'full_protection_on_untrusted_networks',
    securityTaskTitle: 'Enable SSL Pinning – NSURLSession',
  },
  {
    protectionBadgeKey: 'full_protection_on_untrusted_networks',
    securityTaskTitle: 'Enable SSL Pinning – AFNetworking',
  },
  {
    protectionBadgeKey: 'full_protection_on_untrusted_networks',
    securityTaskTitle: 'Enable SSL Pinning – UIWebView',
  },
  {
    protectionBadgeKey: 'full_protection_on_untrusted_networks',
    securityTaskTitle: 'Enable SSL Pinning – WKWebView',
  },
  {
    protectionBadgeKey: 'full_protection_on_untrusted_networks',
    securityTaskTitle: 'Enable SSL Pinning – SSLSocket',
  },
  {
    protectionBadgeKey: 'full_protection_on_untrusted_networks',
    securityTaskTitle: 'Enable SSL Pinning - HttpsUrlConnection',
  },
  {
    protectionBadgeKey: 'full_protection_on_untrusted_networks',
    securityTaskTitle: 'Enable SSL Pinning - OkHttp',
  },
  {
    protectionBadgeKey: 'full_protection_on_untrusted_networks',
    securityTaskTitle: 'Enable SSL Pinning - Ion',
  },
  {
    protectionBadgeKey: 'removes_data_from_shared_device_locations',
    securityTaskTitle: 'Set Expiration on iOS Pasteboard Data',
  },
  {
    protectionBadgeKey: 'protects_the_keyboard',
    securityTaskTitle: 'Disable Third-Party Keyboards',
  },
  {
    protectionBadgeKey: 'all_communication_encrypted',
    securityTaskTitle: 'App Store Blocker: Dangerous ATS Exemptions',
  },
  {
    protectionBadgeKey: 'all_communication_encrypted',
    securityTaskTitle: 'Enable Android NSC to Prevent Cleartext Traffic',
  },
  {
    protectionBadgeKey: 'supports_two_factor_authentication',
    securityTaskTitle: 'Enable Two-Factor Authentication Input Field',
  },
  {
    protectionBadgeKey: 'leverages_secure_system_sdk',
    securityTaskTitle: 'App Store Blocker: App Built with Deprecated iOS SDK',
  },
  {
    protectionBadgeKey: 'leverages_secure_system_sdk',
    securityTaskTitle: 'Enable SafeBrowsing on WebViews',
  },
  {
    protectionBadgeKey: 'leverages_secure_browser_settings',
    securityTaskTitle: 'Switch from Deprecated UIWebView API to WKWebView',
  },
  {
    protectionBadgeKey: 'leverages_secure_browser_settings',
    securityTaskTitle: 'Enable SafeBrowsing on WebViews',
  },
  {
    protectionBadgeKey: 'leverages_secure_system_libraries',
    securityTaskTitle: 'Switch from Unsafe Serialization API to NSSecureCoding',
  },
  {
    protectionBadgeKey: 'leverages_secure_system_settings',
    securityTaskTitle: 'Enable Verification on Android Security Provider',
  },
  {
    protectionBadgeKey: 'enhances_password_security',
    securityTaskTitle: 'Enable System-Managed Login Input Fields',
  },
  {
    protectionBadgeKey: 'does_not_offload_data_to_third_parties',
    securityTaskTitle: 'App Private Data Exposed Through Backups',
  },
  {
    protectionBadgeKey: 'protects_data_on_screens',
    securityTaskTitle: 'Protect App Screens from 3rd Party Apps',
  },
  {
    protectionBadgeKey: 'requires_android_malware_protection',
    securityTaskTitle: 'Enable Android Verify Apps',
  },
  {
    protectionBadgeKey: 'requires_android_device_security',
    securityTaskTitle: 'Enable SafetyNet Attestation API',
  },
  {
    protectionBadgeKey: 'server_enforces_https',
    securityTaskTitle: 'Server Lacks OCSP Stapling',
  },
  {
    protectionBadgeKey: 'server_enforces_https',
    securityTaskTitle: 'Server Lacks HTTP Strict Transport Security Header',
  },
];

export const getBadgeTypeFromSecurityTaskTitle = (taskTitle: string): BadgeTypeEnum | null => {
  const badgeType = securityTaskToProtectionBadgeMap.find(badgeMap => {
    return badgeMap.securityTaskTitle === taskTitle;
  });
  return badgeType ? BadgeType[badgeType.protectionBadgeKey] : null;
};

export const getBadgeDescriptionFromBadgeType = (type: BadgeTypeEnum | null): string => {
  switch (type) {
    case BadgeType.all_communication_encrypted:
      return '100% Encrypted Communication';

    case BadgeType.supports_two_factor_authentication:
      return 'Supports IOS built-in 2-Factor Authentication';

    case BadgeType.full_protection_on_untrusted_networks:
      return 'Full Protection on Hostile/Untrusted Networks';

    case BadgeType.protects_data_on_screens:
      return 'Protects Data on Screens';

    case BadgeType.requires_android_device_security:
      return 'Requires Android Device Security';

    case BadgeType.requires_android_malware_protection:
      return 'Requires Android Malware Protection';

    case BadgeType.server_enforces_https:
      return 'Enforces Secure Domains';

    case BadgeType.removes_data_from_shared_device_locations:
      return 'Removes Data from Shared Device Locations';

    case BadgeType.protects_the_keyboard:
      return 'Protects The Keyboard';

    case BadgeType.leverages_secure_system_sdk:
      return 'Leverages updated/most secure system software';

    case BadgeType.leverages_secure_browser_settings:
      return 'Leverages updated/most secure Web Browser settings';

    case BadgeType.leverages_secure_system_libraries:
      return 'Leverages updated/most secure system libraries';

    case BadgeType.leverages_secure_system_settings:
      return 'Leverages updated/most secure system settings';

    case BadgeType.enhances_password_security:
      return 'Enhances the security of the Username/Password';

    case BadgeType.does_not_offload_data_to_third_parties:
      return 'Does not offload data to 3rd party locations';

    default:
      Raven.captureException(new Error('Badge not currently handled'), {
        extra: {
          badge_type_tried: type,
        },
      });
      return '';
  }
};

export const getBadgeImageFromBadgeType = (type: BadgeTypeEnum | null) => {
  switch (type) {
    case BadgeType.all_communication_encrypted:
      return appProtectionBadgeImages.all_communication_encrypted;

    case BadgeType.supports_two_factor_authentication:
      return appProtectionBadgeImages.supports_two_factor_authentication;

    case BadgeType.full_protection_on_untrusted_networks:
      return appProtectionBadgeImages.full_protection_on_untrusted_networks;

    case BadgeType.protects_data_on_screens:
      return appProtectionBadgeImages.protects_data_on_screens;

    case BadgeType.requires_android_device_security:
      return appProtectionBadgeImages.requires_android_device_security;

    case BadgeType.requires_android_malware_protection:
      return appProtectionBadgeImages.requires_android_malware_protection;

    case BadgeType.server_enforces_https:
      return appProtectionBadgeImages.server_enforces_https;

    case BadgeType.removes_data_from_shared_device_locations:
      return appProtectionBadgeImages.removes_data_from_shared_device_locations;

    case BadgeType.protects_the_keyboard:
      return appProtectionBadgeImages.protects_the_keyboard;

    case BadgeType.leverages_secure_system_sdk:
      return appProtectionBadgeImages.leverages_secure_system_sdk;

    case BadgeType.leverages_secure_browser_settings:
      return appProtectionBadgeImages.leverages_secure_browser_settings;

    case BadgeType.leverages_secure_system_libraries:
      return appProtectionBadgeImages.leverages_secure_system_libraries;

    case BadgeType.leverages_secure_system_settings:
      return appProtectionBadgeImages.leverages_secure_system_settings;

    case BadgeType.enhances_password_security:
      return appProtectionBadgeImages.enhances_password_security;

    case BadgeType.does_not_offload_data_to_third_parties:
      return appProtectionBadgeImages.does_not_offload_data_to_third_parties;

    default:
      return appProtectionBadgeImages.fallback;
  }
};

export const badgeToIssueTypeMap = [
  {
    taskIssueTypeId: 'ff1dedb9-8f4b-55be-a946-42944f1941c5',
    protectionBadge: BadgeType.full_protection_on_untrusted_networks,
  },
  {
    taskIssueTypeId: '303492e3-bd3c-5007-b39b-2a041cd14cbe',
    protectionBadge: BadgeType.full_protection_on_untrusted_networks,
  },
  {
    taskIssueTypeId: 'f52f2afe-e7f1-51c5-8afe-30c9e372bb4c',
    protectionBadge: BadgeType.full_protection_on_untrusted_networks,
  },
  {
    taskIssueTypeId: '87508aa4-0afc-513d-86fa-5e975b581511',
    protectionBadge: BadgeType.full_protection_on_untrusted_networks,
  },
  {
    taskIssueTypeId: '52704271-43fe-5bb0-b70b-9c0ff852cd7d',
    protectionBadge: BadgeType.full_protection_on_untrusted_networks,
  },
  {
    taskIssueTypeId: '13c2faae-1714-5d0e-b39e-a9f5c9b857c4',
    protectionBadge: BadgeType.full_protection_on_untrusted_networks,
  },
  {
    taskIssueTypeId: '9acecc0f-c1b2-5ea2-b697-82e3b75c686c',
    protectionBadge: BadgeType.full_protection_on_untrusted_networks,
  },
  {
    taskIssueTypeId: '0231f9a7-916a-5928-9f9a-f7f3cecfeb2d',
    protectionBadge: BadgeType.full_protection_on_untrusted_networks,
  },
  {
    taskIssueTypeId: 'b3847058-3a13-5978-8250-e81abf6cbc5f',
    protectionBadge: BadgeType.full_protection_on_untrusted_networks,
  },
  {
    taskIssueTypeId: '51b5bf86-1958-542d-a825-acc36a332e12',
    protectionBadge: BadgeType.removes_data_from_shared_device_locations,
  },
  {
    taskIssueTypeId: 'b22cdce3-89af-5b8e-b091-70831c92a1f5',
    protectionBadge: BadgeType.protects_the_keyboard,
  },
  {
    taskIssueTypeId: '518bdd4c-0248-5854-beaf-beafac805179',
    protectionBadge: BadgeType.all_communication_encrypted,
  },
  {
    taskIssueTypeId: 'bd60dd06-f493-58e7-95af-588dd35c4cae',
    protectionBadge: BadgeType.all_communication_encrypted,
  },
  {
    taskIssueTypeId: 'dfebf7a4-96a7-5173-af52-7387a14e9c09',
    protectionBadge: BadgeType.supports_two_factor_authentication,
  },
  {
    taskIssueTypeId: 'd2aaee79-73fe-5bf6-9ef2-a5ac9a6f294f',
    protectionBadge: BadgeType.leverages_secure_system_sdk,
  },
  {
    taskIssueTypeId: 'c16c425f-4df4-5a5d-b442-a231eb1af745',
    protectionBadge: BadgeType.leverages_secure_system_sdk,
  },
  {
    taskIssueTypeId: '5023703e-a80c-53c2-97d2-50a9ab11b890',
    protectionBadge: BadgeType.leverages_secure_browser_settings,
  },
  {
    taskIssueTypeId: '3f01a452-fc36-5685-ba49-ffeae167884d',
    protectionBadge: BadgeType.leverages_secure_browser_settings,
  },
  {
    taskIssueTypeId: '16ba3a4d-9a70-579b-904f-3963687a917c',
    protectionBadge: BadgeType.leverages_secure_system_libraries,
  },
  {
    taskIssueTypeId: '60ae412c-2375-5a2c-8a8d-bf2e8e59f502',
    protectionBadge: BadgeType.leverages_secure_system_libraries,
  },
  {
    taskIssueTypeId: '5ccb3f15-10dc-58a6-bb95-a79fb3bd0508',
    protectionBadge: BadgeType.enhances_password_security,
  },
  {
    taskIssueTypeId: '359b17e9-db71-51db-92cb-9b258117cc62',
    protectionBadge: BadgeType.does_not_offload_data_to_third_parties,
  },
  {
    taskIssueTypeId: 'b151ba5d-e6bc-51d3-b409-38ba42c584d2',
    protectionBadge: BadgeType.protects_data_on_screens,
  },
  {
    taskIssueTypeId: 'c7ea3f43-5109-5e33-b962-de7075879da5',
    protectionBadge: BadgeType.requires_android_malware_protection,
  },
  {
    taskIssueTypeId: 'c9dea6ad-18b7-5574-aa02-687721730964',
    protectionBadge: BadgeType.requires_android_device_security,
  },
  {
    taskIssueTypeId: '7114c293-93e2-5f24-842f-4752429322c3',
    protectionBadge: BadgeType.server_enforces_https,
  },
  {
    taskIssueTypeId: 'a0939c09-951c-5058-b3c5-58ecaecb55ea',
    protectionBadge: BadgeType.server_enforces_https,
  },
];

export function taskStatusToIndicatorMap(taskStatus: string): string {
  switch (taskStatus) {
    case 'COMPLETED':
      return 'good';
    case 'NOT_COMPLETED':
      return 'bad';
    case 'BEYOND_SUBSCRIPTION':
      return 'orange';
    case 'NOT_COMPLETED_HIDDEN':
      return 'neutral';
    case 'NOT_COMPLETED_VISIBLE':
      return 'bad';
    case 'UNKNOWN':
      return 'neutral';
    default:
      return 'neutral';
  }
}

export type HasBadges = $ReadOnlyArray<[BadgeTypeEnum, boolean]>;

export const getAllApplicableAppProtectionBadges = (
  app: ?{
    +platform: MobileAppPlatformEnumType,
    +app_protection_metrics: AppProtectionMetrics,
    ...
  },
): HasBadges => {
  if (!app) return [];

  const protectionBadgeTypes = [
    BadgeType.all_communication_encrypted,
    BadgeType.full_protection_on_untrusted_networks,
    BadgeType.server_enforces_https,
    BadgeType.leverages_secure_system_sdk,
    BadgeType.leverages_secure_browser_settings,
    ...(app.platform === MobileAppPlatformEnum.IOS
      ? [
          BadgeType.supports_two_factor_authentication,
          BadgeType.removes_data_from_shared_device_locations,
          BadgeType.protects_the_keyboard,
          BadgeType.leverages_secure_system_libraries,
          BadgeType.enhances_password_security,
        ]
      : []),
    ...(app.platform === MobileAppPlatformEnum.ANDROID
      ? [
          BadgeType.requires_android_device_security,
          BadgeType.requires_android_malware_protection,
          BadgeType.protects_data_on_screens,
          BadgeType.leverages_secure_system_settings,
          BadgeType.does_not_offload_data_to_third_parties,
        ]
      : []),
  ];

  return protectionBadgeTypes.reduce(
    (acc, badge: BadgeTypeEnum) => [
      ...acc,
      [badge, has(badge)(app.app_protection_metrics) && app.app_protection_metrics[badge]],
    ],
    ([]: HasBadges),
  );
};

export const getHoverDescriptionFromBadgeType = (type: BadgeTypeEnum | null): string => {
  switch (type) {
    case BadgeType.all_communication_encrypted:
      return 'Using encrypted communication (SSL/TLS) is standard practice for most web and mobile  applications. On a mobile app, publishers can enforce the use of encrypted communication at all times by removing any possibility of any clear-text traffic (e.g. HTTP) from accidentally or maliciously being used (e.g. downgrade attacks).';

    case BadgeType.supports_two_factor_authentication:
      return 'Two-factor authentication requires a user to leverage a username/password as well as an SMS code to authenticate to the application. Apple has created a security feature that will display the SMS code within the app itself, instead of forcing the user to open the iMessage app, copy the SMS code from iMessage, return to the main app, and then finally paste the information into the SMS field. Using Apple’s security feature, the user will simply tap the code within the app itself, allowing the user to directly authenticate.';

    case BadgeType.full_protection_on_untrusted_networks:
      return 'Nationstates, Governments, Certificate Authorities, or even your local attacker at the corner coffee shop may perform a SSL man-in-the-middle attack, allowing an attacker to view encrypted communication to & from the mobile app. A security technique called “SSL Pinning” prevents any attacker, including nationstates, governments, and rogue CAs, from tapping into the communication channel and viewing the data. A user of an app with SSL Pinning can safely use it in a hostile network, domestic or internationally. This is especially important for apps with high risk data flowing through it at all times.';

    case BadgeType.protects_data_on_screens:
      return 'Mobile application publishers can enable a security feature that will prevent other apps installed on the device from taking a screenshot of any screen of the app, especially screens with sensitive information such as login screens, profile screens, and payment screens.';

    case BadgeType.requires_android_device_security:
      return 'Mobile application publishers can enable security feature where the app can detect if the underlying Android device has been maliciously tampered with. If an Android device has been tampered with, there is a good chance malware is on the device, which may lead to Account Takeovers.';

    case BadgeType.requires_android_malware_protection:
      return 'Mobile application publishers can enable a security feature that will detect if the default Android malware scanner has been disabled. There is no legit reason for an Android device should have malware scanner disabled, as there is a good chance malware has been installed.';

    case BadgeType.server_enforces_https:
      return 'Mobile applications connect to server side endpoints, such as RestAPIs, to pull data from the server/cloud and display it onto app. While the communication is usually encrypted (SSL/TLS), publishers can add an extra layer of protection to the server side endpoint for added protection. A few of these protections include HSTS, which will ensure no clear-text communication is used and OSCP Stapling, which will check the revocation status of X.509 digital certificates.';

    case BadgeType.removes_data_from_shared_device_locations:
      return 'Despite what Symantec and McAfee want you to believe, mobile apps, and its private storage, is isolated from one to another. This means two apps on the same device cannot access or see each other’s data due to the application isolation model of both iOS & Android. However, there are a few caveats to this isolation, including the iOS pasteboard. When a user copies text on an iPhone, the data is accessible to all the other apps on the device. This data can be anything, such as a phone number, password, email address, etc. To prevent any app from scraping the pasteboard at any given time, Apple has a security feature that allows an app to set an expiration date on any data copied by the user while using their app. For example, if a banking user has copied their password from a password manager app onto the banking app, the password manager app can set an expiration level on the copied password data to avoid other apps from grabbing it as well.';

    case BadgeType.protects_the_keyboard:
      return `iPhone Apps can allow the user to install 3rd party non-Apple keyboards instead of the default Apple keyboard. While 3rd party keyboards are not leveraged when the user types into a "Secure" field (such as password fields), they can potentially log all of the user's keystrokes for regular fields. If the App requires the user to enter sensitive data (such as credit card numbers) in a non "Secure" field, allowing a custom keyboard to be used may increase the risk of such data being leaked.`;

    case BadgeType.leverages_secure_system_sdk:
      return 'iOS & Android apps should be using the latest and most updated Software Development Kits (SDKs), as the latest SDKs usually have a better security track record.';

    case BadgeType.leverages_secure_browser_settings:
      return 'Mobile apps often have a few screens that are displayed in a WebViews, which are views that display data as web content, but lack some of the features of fully-developed browsers. When WebViews are used, it is susceptible to traditional Webapp attacks and thus, should be secured. Both Google and Apple offer libraries to implement secure WebViews.';

    case BadgeType.leverages_secure_system_libraries:
      return 'On every iOS update, Apple provides developers with more secure version of their libraries, including NSCoding. A more secure version called NSSecureCoding should be used to prevent attacks on older libraries, such as substitution attacks.';

    case BadgeType.leverages_secure_system_settings:
      return 'Both Apple and Google release new versions of their software on a yearly basis. The newer versions of iOS and Android are usually more secure, allowing the app to leverage new security protections. App publishers should always leverage the latest software; however, the app can still support older version as well.';

    case BadgeType.enhances_password_security:
      return 'Apple has a security feature that essentially creates a password manager within the app, removing the need for users to remember difficult passwords.  If the feature is enabled, the app will automatically load the user’s password from the iCloud Keychain, negating the need for the user to every tap in a password ever again.';

    case BadgeType.does_not_offload_data_to_third_parties:
      return 'Android users are allowed to backup their data to another device, such as a personal laptop or desktop. When data is backed-up, an app’s private storage is also backed-up. If corporate data is stored within an app, corporate data would now reside on a non-company asset. To prevent accidental data leakage, app publishers can disable backups of the private storage when a user conducts a full backup of the device.';

    default:
      Raven.captureException(new Error('Badge not currently handled'), {
        extra: {
          badge_type_tried: type,
        },
      });
      return '';
  }
};

export const getHoverFeatureListFromBadgeType = (type: BadgeTypeEnum | null): string[] => {
  switch (type) {
    case BadgeType.all_communication_encrypted:
      return ['Dangerous ATS Exemptions', 'Enable Android NSC to Prevent Cleartext Traffic'];

    case BadgeType.supports_two_factor_authentication:
      return ['iOS Built-In 2-Factor Authentication'];

    case BadgeType.full_protection_on_untrusted_networks:
      return ['Enable SSL Pinning'];

    case BadgeType.protects_data_on_screens:
      return ['Protect App Screens from 3rd Party Apps'];

    case BadgeType.requires_android_device_security:
      return ['SafetyNet Attestation API'];

    case BadgeType.requires_android_malware_protection:
      return ['Enable Android Verify Apps'];

    case BadgeType.server_enforces_https:
      return ['Server Lacks HTTP Strict Transport Security Header', 'Server Lacks OCSP Stapling'];

    case BadgeType.removes_data_from_shared_device_locations:
      return ['Set Expiration on iOS Pasteboard Data'];

    case BadgeType.protects_the_keyboard:
      return ['Disable Third-Party Keyboards'];

    case BadgeType.leverages_secure_system_sdk:
      return ['App Store Blocker: App Built with Deprecated iOS SDK'];

    case BadgeType.leverages_secure_browser_settings:
      return ['Switch from Deprecated UIWebView API to WKWebView'];

    case BadgeType.leverages_secure_system_libraries:
      return ['Switch from Unsafe Serialization API to NSSecureCoding'];

    case BadgeType.leverages_secure_system_settings:
      return ['Enable Verification on Android Security Provider'];

    case BadgeType.enhances_password_security:
      return ['Enable System-Managed Login Input Fields'];

    case BadgeType.does_not_offload_data_to_third_parties:
      return ['App Private Data Exposed Through Backups'];

    default:
      Raven.captureException(new Error('Badge not currently handled'), {
        extra: {
          badge_type_tried: type,
        },
      });
      return [''];
  }
};
