//@flow
export const MANAGER_GET_REQUEST_NAME = 'manager.get';

export const UPLOAD_API_STRING_KEY = 'UPLOAD_API_KEY';
export const API_KEYS_ERROR_STRING_KEY = 'API_KEYS_ERROR';

export const JIRA_CONFIGS_LOADED_KEY = 'JIRA_CONFIGS_LOADED';

// types (used by pagination, withProgressIndicator, etc.)
export const API_KEYS_TYPE = 'apiKeys';
