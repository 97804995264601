// @flow

const releaseType = {
  APP_STORE: 'APP_STORE',
  PRE_PROD: 'PRE_PROD',
  APP_STORE_THIRD_PARTY: 'APP_STORE_THIRD_PARTY',
  ENTERPRISE: 'ENTERPRISE',
};

export default releaseType;
export type ReleaseType = $Keys<typeof releaseType>;
