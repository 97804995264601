// @flow
import config from '@dt/config';
import fetch, { parse, type APIResult as FetchResponse } from '@dt/fetch';

import { type UsersList, type Authenticator } from './types';

const api = config.horizonApiBaseUrl;
const version = 'v1';

export type AwsAuthenticator = {|
  ...Authenticator,
  +role_arn: string,
  +external_id: string,
|};

export type AwsAuthenticatorsList = $ReadOnlyArray<AwsAuthenticator>;

export type AwsAuthenticatorsListResponse = {|
  aws_authenticators: AwsAuthenticatorsList,
  users: UsersList,
|};

export type AwsAuthenticatorsCreateRequestParams = {
  role_arn: string,
  external_id: string,
  ...
};

export async function list(): FetchResponse<AwsAuthenticatorsListResponse> {
  return fetch(`${api}/${version}/aws_authenticators`).then(parse);
}

export type AwsAuthenticatorCreateResponse = AwsAuthenticator;

export async function create(
  params: AwsAuthenticatorsCreateRequestParams,
): FetchResponse<AwsAuthenticatorCreateResponse> {
  return fetch(`${api}/${version}/aws_authenticators`, {
    method: 'POST',
    body: JSON.stringify(params),
  }).then(parse);
}
