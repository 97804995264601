// @flow
import { createReducerCreator, updateInList } from 'rezz';
import { certificateReceived } from './actions';
import type { CertificateList, Certificate } from '@dt/horizon-api';

export type CertificateState = CertificateList;

export default createReducerCreator<CertificateState, Certificate, _>({
  initialState: [],
  equalityCheck: (a, b) => a.id === b.id,
})(updateInList([certificateReceived.toString()]));
