//@flow
import { createAction } from 'redux-actions';
import { type APIOperationsList } from '@dt/horizon-api';

export const apiOperationsReceived = createAction<
  'api_operations/received',
  [APIOperationsList],
  {| api_operations: APIOperationsList |},
>('api_operations/received', api_operations => ({
  api_operations,
}));
