//@flow
import { createAction } from 'redux-actions';
import type { Application } from '@dt/user-api/mobile_apps';

export const updateAppLinkingStarted = createAction<
  'UPDATE_APP_LINKING_STARTED',
  [Application, boolean, ?string],
  {
    app: Application,
    isEnterpriseInternal: boolean,
    linkedAppStoreAppId: ?string,
    ...
  },
>('UPDATE_APP_LINKING_STARTED', (app, isEnterpriseInternal, linkedAppStoreAppId) => ({
  app,
  isEnterpriseInternal,
  linkedAppStoreAppId,
}));

export const updateAppLinkingFailure = createAction<
  'UPDATE_APP_LINKING_FAILURE',
  [string],
  {
    message: string,
    ...
  },
>('UPDATE_APP_LINKING_FAILURE', message => ({
  message,
}));

export const updateAppLinkingSuccess = createAction<
  'UPDATE_APP_LINKING_SUCCESS',
  [Application],
  {
    updatedApp: Application,
    ...
  },
>('UPDATE_APP_LINKING_SUCCESS', updatedApp => ({
  updatedApp,
}));
