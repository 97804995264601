// @flow
import { createAction } from 'redux-actions';
import { type NetworkServicesList } from '@dt/horizon-api';

export const networkServicesReceived = createAction<
  'network_services/received',
  [NetworkServicesList],
  { network_services: NetworkServicesList },
>('network_services/received', network_services => ({
  network_services,
}));

export const reachedEndOfNetworkServicesList = createAction<'network_services/list/reached_end', [], void>(
  'network_services/list/reached_end',
  () => undefined,
);

export const reachedEndOfNetworkServicesListForAssetSearchId = createAction<
  'network_services/list/search/reached_end',
  [string],
  string,
>('network_services/list/search/reached_end', search_id => search_id);

export const networkServicesReceivedIdsForAssetSearch = createAction<
  'network_services/search/ids/received',
  [string, Array<string>],
  {|
    searchId: string,
    ids: Array<string>,
  |},
>('network_services/search/ids/received', (searchId, ids) => ({
  searchId,
  ids,
}));
