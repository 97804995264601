// @flow
import { type Saga } from 'redux-saga';
import { putResolve, call } from 'redux-saga/effects';
import { network_services, assets_searches } from '@dt/horizon-api';
import { callPromise } from '@dt/redux-saga-wrapped-effects';
import { paginationBeginRequest, paginationEndRequest } from '@dt/pagination';

import { withCache, handleNormalizedResponse } from './../resource_fetch/sagas';
import resourceType from './resourceType';

export const getNetworkServicesList = withCache<{
  search_id?: string,
  ...
}>('network_services_list', function*(params): Saga<void> {
  yield putResolve(paginationBeginRequest(resourceType, params));

  const { search_id, ...urlParams } = params;
  const response = search_id
    ? yield* callPromise(assets_searches['network_services'].list, search_id, urlParams)
    : yield* callPromise(network_services.list, params);
  if (response._type === 'error') {
    throw new Error(response.title);
  }

  yield call(handleNormalizedResponse, response.body);

  if (response.body.pagination_information) {
    yield putResolve(paginationEndRequest(resourceType, params, response.body.pagination_information));
  }
});
