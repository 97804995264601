//@flow
const compliancePolicy: {|
  GDPR: 'GDPR',
  PCI: 'PCI',
  HIPAA: 'HIPAA',
  FTC: 'FTC',
  OWASP: 'OWASP',
  CALOPPA: 'CALOPPA',
  COPPA: 'COPPA',
  VPPA: 'VPPA',
  CCPA: 'CCPA',
  MITRE: 'MITRE',
  OWASP_MSTG: 'OWASP_MSTG',
  OWASP_ASVS: 'OWASP_ASVS',
|} = {
  GDPR: 'GDPR',
  PCI: 'PCI',
  HIPAA: 'HIPAA',
  FTC: 'FTC',
  OWASP: 'OWASP',
  CALOPPA: 'CALOPPA',
  COPPA: 'COPPA',
  VPPA: 'VPPA',
  CCPA: 'CCPA',
  MITRE: 'MITRE',
  OWASP_MSTG: 'OWASP_MSTG',
  OWASP_ASVS: 'OWASP_ASVS',
};
export default compliancePolicy;

export type CompliancePolicyEnum = $Keys<typeof compliancePolicy>;
