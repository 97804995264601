//@flow
import createReducerCreator from 'rezz/createReducerCreator';
import updateInList from 'rezz/updateInList';
import type { ShadowscanSecurityFinding } from '@dt/user-api/shadow_security_findings';

import { shadowscanFindingsReceived } from './../shadow-scan/actions';

export type ShadowscanState = $ReadOnlyArray<ShadowscanSecurityFinding>;

export default createReducerCreator<
  ShadowscanState,
  ShadowscanSecurityFinding,
  _,
>({
  initialState: [],
  equalityCheck: (a, b) => a.id === b.id,
})(updateInList(shadowscanFindingsReceived.toString()));
