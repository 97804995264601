// @flow
import { type Saga } from 'redux-saga';
import { put, call } from 'redux-saga/effects';
import { web_applications, assets_searches } from '@dt/horizon-api';
import { callPromise } from '@dt/redux-saga-wrapped-effects';

import resourceType from './resourceType';

import { paginationBeginRequest, paginationEndRequest } from '@dt/pagination';
import { withCache, handleNormalizedResponse } from './../resource_fetch/sagas';

export const getWebApplicationsList = withCache<{
  search_id?: string,
  ...
}>('web_applications', function* getWebApplicationsList(params): Saga<void> {
  yield put(paginationBeginRequest(resourceType, params));

  const { search_id, ...urlParams } = params;
  const response = search_id
    ? yield* callPromise(assets_searches['web_applications'].list, search_id, urlParams)
    : yield* callPromise(web_applications.list, params);

  if (response._type === 'error') {
    throw new Error(response.title);
  }

  yield call(handleNormalizedResponse, response.body);

  if (response.body.pagination_information) {
    yield put(paginationEndRequest(resourceType, params, response.body.pagination_information));
  }
});

export const getWebApplicationDetails = withCache<string>(
  id => `web_application_${id}`,
  function* getWebApplicationId(webAppId): Saga<void> {
    const response = yield* callPromise(web_applications.details, webAppId);
    if (response._type === 'error') {
      throw new Error(response.title);
    }
    yield call(handleNormalizedResponse, response.body);
  },
);
