//@flow
import { PureComponent, type Node } from 'react';
import { createPortal } from 'react-dom';

type Props = { children: Node, ... };

export default class Portal extends PureComponent<Props> {
  node: ?HTMLElement;
  render() {
    return createPortal(this.props.children, this.getContainer());
  }

  getContainer = (): HTMLElement => {
    if (!this.node) {
      this.node = document.createElement('div');

      if (!document.body) {
        throw new Error('expected a document body to exist');
      }
      document.body.appendChild(this.node);
    }

    if (!this.node) {
      throw new Error('Node should exist by now, we tried to create it');
    }

    return this.node;
  };
}
