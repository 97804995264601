//@flow
import config from '@dt/config';
import fetch, { parse } from '@dt/fetch';
import { byProperty, result } from './util';

export type CreateAppResponse = {| upload_url?: string |};

const create = async (): Promise<CreateAppResponse> => {
  return fetch(`${config.sevenhellApiBaseUrl}/v2/app_uploads`, {
    method: 'POST',
  })
    .then(parse)
    .then(result)
    .then(byProperty('upload_url'));
};

export default {
  create,
};
