// @flow
import { createAction } from 'redux-actions';
import { type CommentsList, type CommentPostParams } from '@dt/horizon-api';

export const commentsReceived = createAction<'comments/received', [CommentsList], CommentsList>(
  'comments/received',
  comments => comments,
);

export const commentInsert = createAction<
  'comment/insert',
  [string, CommentPostParams],
  {
    policyViolationId: string,
    params: CommentPostParams,
    ...
  },
>('comment/insert', (policyViolationId: string, params: CommentPostParams) => ({
  policyViolationId,
  params,
}));

export const commentInsertError = createAction<'comment/insert/error', [string], string>(
  'comment/insert/error',
  (error: string) => error,
);

export const commentInsertSuccess = createAction<'comment/insert/success', [], null>(
  'comment/insert/success',
  () => null,
);

export const commentDelete = createAction<
  'comment/delete',
  [string, string],
  {
    policyViolationId: string,
    commentId: string,
    ...
  },
>('comment/delete', (commentId: string, policyViolationId: string) => ({
  policyViolationId,
  commentId,
}));

export const commentDeleteError = createAction<
  'comment/delete/error',
  [string, string],
  {
    commentId: string,
    error: string,
    ...
  },
>('comment/delete/error', (commentId: string, error: string) => ({
  commentId,
  error,
}));

export const commentDeleteSuccess = createAction<'comment/delete/success', [string], { commentId: string, ... }>(
  'comment/delete/success',
  (commentId: string) => ({
    commentId,
  }),
);
