// @flow
import React, { memo } from 'react';
import { palette } from '@dt/theme';

type Props = {
  foregroundColor?: string,
  backgroundColor?: string,
};

function MobileSecureIcon({
  foregroundColor = palette.brand30,
  backgroundColor = palette.white,
}: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="1.4"
      clipRule="evenodd"
      viewBox="0 0 160 160"
      width="100%"
      // This is important for IE 11
      style={{ maxHeight: 'inherit ' }}
    >
      <g fillRule="nonzero">
        <path
          d="M160 127.19C160.001 131.499 159.154 135.766 157.505 139.747C155.857 143.729 153.44 147.346 150.393 150.393C147.346 153.44 143.729 155.857 139.747 157.505C135.766 159.154 131.499 160.001 127.19 160H32.81C24.1082 160 15.7629 156.543 9.60983 150.39C3.45676 144.237 0 135.892 0 127.19L0 32.8C0.00265164 24.1 3.46058 15.7572 9.61336 9.60629C15.7661 3.45539 24.11 1.12044e-06 32.81 1.52453e-06H127.19C131.498 -0.00131195 135.764 0.846116 139.745 2.49388C143.726 4.14165 147.343 6.55747 150.39 9.60336C153.436 12.6493 155.853 16.2655 157.502 20.2457C159.151 24.2258 160 28.4918 160 32.8V127.19Z"
          fill={backgroundColor}
        />
        <path
          d="M98.57 31.5H61.43C58.0078 31.5 54.7256 32.8588 52.3048 35.2777C49.884 37.6966 48.5227 40.9778 48.52 44.4V115.6C48.5227 119.022 49.884 122.303 52.3048 124.722C54.7256 127.141 58.0078 128.5 61.43 128.5H98.57C101.992 128.5 105.274 127.141 107.695 124.722C110.116 122.303 111.477 119.022 111.48 115.6V44.4C111.477 40.9778 110.116 37.6966 107.695 35.2777C105.274 32.8588 101.992 31.5 98.57 31.5ZM107.48 115.6C107.475 117.961 106.534 120.223 104.864 121.891C103.194 123.559 100.931 124.497 98.57 124.5H61.43C59.0695 124.497 56.8062 123.559 55.1361 121.891C53.4661 120.223 52.5253 117.961 52.52 115.6V44.4C52.5253 42.0395 53.4661 39.7773 55.1361 38.1091C56.8062 36.4408 59.0695 35.5026 61.43 35.5H65.15C65.054 35.8793 65.0036 36.2687 65 36.66C65 37.9861 65.5268 39.2579 66.4645 40.1955C67.4022 41.1332 68.6739 41.66 70 41.66H90C91.3261 41.66 92.5979 41.1332 93.5356 40.1955C94.4732 39.2579 95 37.9861 95 36.66C94.9964 36.2687 94.9461 35.8793 94.85 35.5H98.57C100.931 35.5026 103.194 36.4408 104.864 38.1091C106.534 39.7773 107.475 42.0395 107.48 44.4V115.6Z"
          fill={foregroundColor}
        />
      </g>
    </svg>
  );
}

export default memo<Props>(MobileSecureIcon);
