//@flow
import config from 'config';
import { type SecurityFinding } from '@dt/findings/types';
import { type Application } from '@dt/user-api/mobile_apps';

type Data = {
  ...Application,
  security_finding_list: $ReadOnlyArray<SecurityFinding>,
  email: ?string,
  customer_name: ?string,
};

export async function downloadZip(data: Data) {
  return new Promise((resolve, reject) => {
    try {
      // adopted from a stackoverflow answer: https://stackoverflow.com/a/180999
      const form = global.document.createElement('form');
      form.setAttribute('method', 'POST');
      form.setAttribute('action', config.reportingUrl);
      form.setAttribute('target', '_blank');

      const input = document.createElement('input');
      input.setAttribute('name', 'data');
      input.setAttribute('value', JSON.stringify(data));
      form.appendChild(input);
      global.document.body.appendChild(form);

      form.submit();

      resolve();
    } catch (err) {
      reject(err);
    }
  });
}
