//@flow
import { object, string, boolean, array, type YupObject } from 'yup';
import qs from 'query-string';
import config from '@dt/config';
import fetch, { parse } from '@dt/fetch';
import { result } from './util';
import type { PaginatedResponse } from './_common';

export type APIKey = {
  id: string,
  key: string,
  name: string,
  date_created: string,
  date_updated: string,
  results_api?: {
    access: boolean,
    can_access_all_apps: boolean,
    can_access_app_ids?: $ReadOnlyArray<string>,
    can_close_issues: boolean,
    can_comment_on_issues: boolean,
    can_update_issue_external_id: boolean,
    can_set_issue_priority: boolean,
    ...
  },
  management_api?: { access: boolean, ... },
  brand_protect_public_api?: { access: boolean, ... },
  api_inspect_public_api?: { access: boolean, ... },
  ...
};

const resultsAPISchema = object().shape({
  access: boolean()
    .label('Results API Access')
    .required(),
  can_access_all_apps: boolean()
    .label('Can Access All Apps')
    .required(),
  can_access_app_ids: array()
    .of(string())
    .ensure(),
  can_close_issues: boolean()
    .label('Can Close Issues')
    .required(),
  can_comment_on_issues: boolean()
    .label('Can Comment on Issues')
    .required(),
  can_update_issue_external_id: boolean()
    .label('Can Update External Issue Ids')
    .required(),
  can_set_issue_priority: boolean()
    .label('Allow Changing Issues Priority')
    .required(),
});

const managementAPISchema = object().shape({
  access: boolean()
    .label('Management API Access')
    .required(),
});

const brandProtectAPISchema = object().shape({
  access: boolean()
    .label('Brand Protect Public API Access')
    .required(),
});

const apiInspectAPISchema = object().shape({
  access: boolean()
    .label('API Inspect Public API Access')
    .required(),
});

export const APIKeySchema: YupObject<APIKey> = object().shape({
  name: string()
    .label('API Key Name')
    .required(),
  results_api: resultsAPISchema.nullable().default(() => ({
    access: false,
    can_access_all_apps: false,
    can_access_app_ids: [],
    can_close_issues: false,
    can_comment_on_issues: false,
    can_update_issue_external_id: false,
    can_set_issue_priority: false,
  })),
  management_api: managementAPISchema.nullable().default(() => ({
    access: false,
  })),
  brand_protect_public_api: brandProtectAPISchema.nullable().default(() => ({
    access: false,
  })),
  api_inspect_public_api: apiInspectAPISchema.nullable().default(() => ({
    access: false,
  })),
});

export type APIKeysListResponse = PaginatedResponse<{
  api_keys: $ReadOnlyArray<APIKey>,
  upload_api_key: string,
  ...
}>;

type ResultsAPIPatchParams = {
  access: boolean,
  can_access_all_apps: boolean,
  can_access_app_ids: Array<string>,
  can_close_issues: boolean,
  can_comment_on_issues: boolean,
  can_update_issue_external_id: boolean,
  can_set_issue_priority: boolean,
  ...
};

export type PatchAPIKeyParams = {
  name?: string,
  results_api?: ResultsAPIPatchParams,
  management_api?: { access: boolean, ... },
  brand_protect_public_api?: { access: boolean, ... },
  api_inspect_public_api?: { access: boolean, ... },
  ...
};

export type CreateAPIKeyParams = {
  name: string,
  results_api?: ResultsAPIPatchParams,
  management_api?: { access: boolean, ... },
  brand_protect_public_api?: { access: boolean, ... },
  api_inspect_public_api?: { access: boolean, ... },
  ...
};

export const api_keys = {
  async list(params: { +cursor?: ?string, ... }): Promise<APIKeysListResponse> {
    let fullResponse: APIKeysListResponse;

    return fetch(
      `${config.sevenhellApiBaseUrl}/v2/api_keys${
        params && qs.stringify(params) ? `?${qs.stringify(params)}` : ''
      }`,
    )
      .then(parse)
      .then(result)
      .then(response => {
        /* we're only validating the inner APIKey entities within the response, so we need to keep a ref to the full
         response, for the final Promise resolve
      */
        fullResponse = response;
        return array()
          .of(APIKeySchema)
          .validate(response.api_keys);
      })
      .then(() => Promise.resolve(fullResponse));
  },
  async patch(id: string, params: PatchAPIKeyParams): Promise<APIKey> {
    return fetch(`${config.sevenhellApiBaseUrl}/v2/api_keys/${id}`, {
      method: 'PATCH',
      body: JSON.stringify(params),
    })
      .then(parse)
      .then(result)
      .then(response => APIKeySchema.validate(response));
  },
  async delete(id: string) {
    return fetch(`${config.sevenhellApiBaseUrl}/v2/api_keys/${id}`, {
      method: 'DELETE',
    }).then(parse);
  },
  async create(params: CreateAPIKeyParams): Promise<APIKey> {
    return fetch(`${config.sevenhellApiBaseUrl}/v2/api_keys`, {
      method: 'POST',
      body: JSON.stringify(params),
    })
      .then(parse)
      .then(result)
      .then(response => APIKeySchema.validate(response));
  },
};
