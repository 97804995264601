// @flow
import { useLazyQuery } from '@apollo/client';
import mobile_applications from '@dt/graphql-support/horizon/mobile_applications';
import type {
  MobileApplicationDetailsGetV2Query,
  MobileApplicationDetailsGetV2QueryVariables,
} from '@dt/graphql-support/types';
import { useEffect } from 'react';

export default function useGetHorizonAssetIdFromAppId(appId: ?string) {
  const [mobileAppDetailsQuery, { error, loading, data }] = useLazyQuery<
    MobileApplicationDetailsGetV2Query,
    MobileApplicationDetailsGetV2QueryVariables,
  >(mobile_applications.v2_get);

  useEffect(() => {
    if (!loading && appId) {
      mobileAppDetailsQuery({
        variables: {
          id: appId,
        },
      });
    }
  }, [mobileAppDetailsQuery, loading, appId]);

  if (!appId) {
    return null;
  }

  return {
    id: data?.mobile_app_details_v2.id,
    loading,
    error,
  };
}
