//@flow
import { type Saga } from 'redux-saga';
import { call } from 'redux-saga/effects';
import { clearSessionAndRedirectToExpired } from '@dt/session';

/**
 * It may look as though this is unnecessary. Why not simply directly yield `call`? Well, that works, but due to the
 * nature of generators, and Flow, it makes them difficult to type. Though a saga typically only yields `Effects`, the
 * 'next' values provided back to the saga can be anything, making typing a saga a complex proposition.
 *
 * Since it's a common pattern to yield a call effect to a Promise (to perform a REST request), this generator simply
 * typifies that relationship in Flow -- that the type T in Promise<T> will be the type of the "next" value given to the
 * saga when the Promise resolves.
 * @param fn
 * @param args
 * @return {*}
 */
export default function* callPromise<A, Args: $ReadOnlyArray<A>, T>(
  fn: (...args: Args) => Promise<T>,
  ...args: Args
): Saga<T> {
  try {
    return yield call(Array.isArray(fn) ? [fn[0], fn[1]] : fn, ...args);
  } catch (e) {
    // This is a breach of package design -- this package has nothing to do
    // with auth/session but oh well, this was the easiest way to manage this
    // at the time
    yield call(handleAuthError, e); // eslint-disable-line redux-saga/no-unhandled-errors
    throw e;
  }
}

/**
 * Looks for 401 responses and logs the user out if found.
 * @param response
 */
function* handleAuthError<T>(response: APIResponse<T>): Saga<void> {
  if (response && response.status === 401) {
    // We want errors to propogate
    /* eslint-disable redux-saga/no-unhandled-errors */
    /* eslint-enable redux-saga/no-unhandled-errors */
    yield call(clearSessionAndRedirectToExpired);
  }
  throw response;
}

export type APIResponseBody<T> = T & {
  etag: string,
  kind: string,
  error?: { message: string, ... },
  ...
};

export type APIResponse<T> = {
  // The parsed result returned by GAPI
  result?: APIResponseBody<T>,
  // The raw response body
  body: string,
  // HTTP response headers
  headers: { [key: string]: string, ... },
  // HTTP status code
  status: number,
  // HTTP status text
  statusText?: string,
  ...
};
