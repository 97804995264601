//@flow
import React from 'react';
import { Match, Link } from '@reach/router';
import MenuItem from './Item';
import type { Node } from 'react';

type Props = {
  path?: string,
  exact?: boolean,
  strict?: boolean,
  replace?: boolean,
  // For MenuItem
  children?: Node,
  active?: boolean,
  label: Node,
  heading?: boolean,
  badge?: ?string,
  onClick?: () => void,
  open?: boolean,
  hasSubMenu?: ?boolean,
  isSubMenu?: ?boolean,
  isChildApp?: ?boolean,
  tooltip?: boolean,
};

export const MenuLinkItemReachRouter = (props: Props) => {
  const {
    path,
    exact,
    strict,
    tooltip,
    replace,
    hasSubMenu,
    isSubMenu,
    isChildApp,
    ...appMenuItemProps
  } = props;

  return (
    <Match path={path || ''}>
      {({ match }) => {
        if (path) {
          return (
            <Link to={path} replace={replace} style={{ display: 'block' }}>
              <MenuItem
                tooltip={tooltip}
                hasSubMenu={hasSubMenu}
                isSubMenu={isSubMenu}
                isChildApp={isChildApp}
                active={!!match}
                {...appMenuItemProps}
              />
            </Link>
          );
        }

        return <MenuItem {...appMenuItemProps} />;
      }}
    </Match>
  );
};
