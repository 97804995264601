// @flow
import React, { memo } from 'react';
import { palette } from '@dt/theme';

type Props = {
  foregroundColor?: string,
  backgroundColor?: string,
};

function CloudSecureIcon({
  foregroundColor = palette.brand30,
  backgroundColor = palette.white,
}: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="1.4"
      clipRule="evenodd"
      viewBox="0 0 160 160"
      width="100%"
      // This is important for IE 11
      style={{ maxHeight: 'inherit ' }}
    >
      <g fillRule="nonzero">
        <path
          d="M160,127.19A32.8,32.8,0,0,1,127.19,160H32.81A32.81,32.81,0,0,1,0,127.19V32.8A32.81,32.81,0,0,1,32.81,0h94.38A32.8,32.8,0,0,1,160,32.8Z"
          fill={backgroundColor}
        />

        <g id="Cloud">
          <path
            fill={foregroundColor}
            d="M105,78.28c-.26,0-.52,0-.78,0A28.53,28.53,0,0,0,81.35,52.83a29,29,0,0,0-5.55-.54,28.53,28.53,0,0,0-28.41,26c-.26,0-.51,0-.77,0a18.61,18.61,0,0,0,0,37.22H105a18.61,18.61,0,0,0,0-37.22Z"
          />
          <path
            fill={foregroundColor}
            d="M119,62.59l-.54,0A19.84,19.84,0,0,0,86.73,48.54a32.55,32.55,0,0,1,23,24.91,22.67,22.67,0,0,1,17.43,12.19,12.94,12.94,0,0,0-8.09-23Z"
          />
        </g>
        {/* <path
          d="M98.57 31.5H61.43C58.0078 31.5 54.7256 32.8588 52.3048 35.2777C49.884 37.6966 48.5227 40.9778 48.52 44.4V115.6C48.5227 119.022 49.884 122.303 52.3048 124.722C54.7256 127.141 58.0078 128.5 61.43 128.5H98.57C101.992 128.5 105.274 127.141 107.695 124.722C110.116 122.303 111.477 119.022 111.48 115.6V44.4C111.477 40.9778 110.116 37.6966 107.695 35.2777C105.274 32.8588 101.992 31.5 98.57 31.5ZM107.48 115.6C107.475 117.961 106.534 120.223 104.864 121.891C103.194 123.559 100.931 124.497 98.57 124.5H61.43C59.0695 124.497 56.8062 123.559 55.1361 121.891C53.4661 120.223 52.5253 117.961 52.52 115.6V44.4C52.5253 42.0395 53.4661 39.7773 55.1361 38.1091C56.8062 36.4408 59.0695 35.5026 61.43 35.5H65.15C65.054 35.8793 65.0036 36.2687 65 36.66C65 37.9861 65.5268 39.2579 66.4645 40.1955C67.4022 41.1332 68.6739 41.66 70 41.66H90C91.3261 41.66 92.5979 41.1332 93.5356 40.1955C94.4732 39.2579 95 37.9861 95 36.66C94.9964 36.2687 94.9461 35.8793 94.85 35.5H98.57C100.931 35.5026 103.194 36.4408 104.864 38.1091C106.534 39.7773 107.475 42.0395 107.48 44.4V115.6Z"
          fill={foregroundColor}
        /> */}
      </g>
    </svg>
  );
}

export default memo<Props>(CloudSecureIcon);
