//@flow
import React, { PureComponent } from 'react';
import times from 'lodash/times';

function getStyles() {
  const styles = {
    filler: {
      backgroundColor: '#eee',
      height: 20,
      margin: 10,
    },
    heading: {},
    description: {
      marginLeft: 20,
    },
  };

  styles.heading = {
    ...styles.filler,
  };

  styles.description = {
    ...styles.filler,
    marginLeft: 20,
  };

  return styles;
}

type Props = {
  headings: number,
  descriptionLines: number,
  headingStyle: Object,
  descriptionStyle: Object,
  ...
};

export default class LoadingFiller extends PureComponent<Props> {
  static defaultProps = {
    headings: 2,
    descriptionLines: 5,

    headingStyle: {},
    descriptionStyle: {},
  };

  render() {
    const {
      headings,
      descriptionLines,
      headingStyle,
      descriptionStyle,
    } = this.props;
    const styles = getStyles();

    return (
      <div aria-label="Loading">
        {times(headings).map(i => (
          <div key={i}>
            <div style={{ ...styles.heading, ...headingStyle }} />
            {times(descriptionLines).map(j => (
              <div
                key={j}
                style={{ ...styles.description, ...descriptionStyle }}
              />
            ))}
          </div>
        ))}
      </div>
    );
  }
}
