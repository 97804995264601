//@flow
import { type Saga } from 'redux-saga';
import { call as originalCall } from 'redux-saga/effects';

export default function* callSaga<T, Args: $ReadOnlyArray<*>>(
  saga: (...args: Args) => Saga<T>,
  ...args: Args
): Saga<T> {
  // We want errors to propogate
  return yield originalCall(saga, ...args); //eslint-disable-line redux-saga/no-unhandled-errors
}
