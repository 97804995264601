//@flow
const role = {
  DEVELOPER: 'DEVELOPER',
  DEVELOPER_ALL_APPS: 'DEVELOPER_ALL_APPS',
  SECURITY: 'SECURITY',
  SECURITY_ALL_APPS: 'SECURITY_ALL_APPS',
  MANAGER: 'MANAGER',
};

export default role;

export type UserRoleEnum = $Keys<typeof role>;
