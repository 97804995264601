//@flow
import createReducerCreator from 'rezz/createReducerCreator';
import assign from 'rezz/assign';
import type { ActionType } from 'redux-actions';
import { openscanConfigReceived } from '../actions';
import type { OpenScanAlertsFilterConfig } from '@dt/user-api/openscan';

export type OpenscanConfigState = OpenScanAlertsFilterConfig | null;

export default createReducerCreator<OpenscanConfigState, void, ActionType<typeof openscanConfigReceived>>({
  initialState: (null: OpenscanConfigState),
})(assign(openscanConfigReceived.toString()));
