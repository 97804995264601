//@flow
import gql from 'graphql-tag';

const list = gql`
  query NotificationEventsList($cursor: String) {
    notification_events_list(cursor: $cursor) {
      pagination_information {
        next_cursor
      }
      events {
        id
        title
        event_type
        icon_url
        detail
        detail_icon_url
        date_created
        portal_url
        subtitle
      }
    }
  }
`;

export default {
  list,
};
