// @flow
import { createAction } from 'redux-actions';
import {
  type PolicyRule,
  type PolicyRuleList,
  type PolicyRuleCreateParams,
  type PolicyRulePatchParams,
} from '@dt/horizon-api';

export const policyRulesReceived = createAction<'policy_rules/received', [PolicyRuleList], PolicyRuleList>(
  'policy_rules/received',
  taskTypes => taskTypes,
);

export const policyRuleEdit = createAction<
  'policy_rules/edit',
  [string, string, PolicyRulePatchParams],
  {
    policy_id: string,
    policy_rule_id: string,
    params: PolicyRulePatchParams,
  },
>('policy_rules/edit', (policy_id, policy_rule_id, params) => ({
  policy_id,
  policy_rule_id,
  params,
}));

export const policyRulesConfigMounted = createAction<'mounted/policy_rules/config', [], { key: 'policyRules', ... }>(
  'mounted/policy_rules/config',
  () => ({ key: 'policyRules' }),
);

export const policyRuleDeleteFlowStart = createAction<
  'policy_rules/flow/start',
  [PolicyRule],
  { policy_rule: PolicyRule, ... },
>('policy_rules/flow/start', policy_rule => ({
  policy_rule,
}));

export const policyRuleDeleteFlowConfirm = createAction<
  'policy_rules/flow/confirm',
  [string, string],
  {
    policy_rule_id: string,
    explanation: string,
    ...
  },
>('policy_rules/flow/confirm', (policy_rule_id, explanation) => ({
  policy_rule_id,
  explanation,
}));

export const policyRuleDeleteFlowStop = createAction<
  'policy_rules/flow/stop',
  [string],
  { policy_rule_id: string, ... },
>('policy_rules/flow/stop', policy_rule_id => ({
  policy_rule_id,
}));

export const policyRuleDeleteFlowStep = createAction<
  'policy_rules/flow/step',
  [string, number],
  {
    policy_rule_id: string,
    step: number,
    ...
  },
>('policy_rules/flow/step', (policy_rule_id, step) => ({
  policy_rule_id,
  step,
}));

export const policyRuleDeleteFlowComplete = createAction<
  'policy_rules/flow/complete',
  [string],
  { policy_rule_id: string, ... },
>('policy_rules/flow/complete', policy_rule_id => ({
  policy_rule_id,
}));

export const policyRuleViewViolationsClicked = createAction<
  'policy_rules/view_violations/open/clicked',
  [string],
  { policy_rule_id: string },
>('policy_rules/view_violations/open/clicked', policy_rule_id => ({
  policy_rule_id,
}));

export const policyRuleViewViolationsCloseClicked = createAction<
  'policy_rules/view_violations/close/clicked',
  [],
  void,
>('policy_rules/view_violations/close/clicked', () => {});

export const policyRuleViewViolationsDrawerMounted = createAction<
  'policy_rules/view_violations/drawer/mounted',
  [string],
  { key: 'view_policy_rule_issues', policy_rule_id: string },
>('policy_rules/view_violations/drawer/mounted', policy_rule_id => ({
  key: 'view_policy_rule_issues',
  policy_rule_id,
}));

export const policyRuleCreate = createAction<
  'policy_rules/create',
  [string, PolicyRuleCreateParams],
  {
    policy_id: string,
    params: PolicyRuleCreateParams,
  },
>('policy_rules/create', (policy_id, params) => ({
  policy_id,
  params,
}));

export const policyRuleCreateError = createAction<
  'policy_rules/create/error',
  [string, string, string],
  {
    policy_id: string,
    policy_rule_type_id: string,
    error: string,
  },
>('policy_rules/create/error', (policy_id, policy_rule_type_id, error) => ({
  policy_id,
  policy_rule_type_id,
  error,
}));

export const policyRuleCreateSuccess = createAction<
  'policy_rules/create/success',
  [string, string],
  {
    policy_id: string,
    policy_rule_type_id: string,
  },
>('policy_rules/create/success', (policy_id, policy_rule_type_id) => ({
  policy_id,
  policy_rule_type_id,
}));
