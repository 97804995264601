import Cookies from 'js-cookie';
function useCookie(key) {
    var getCookie = function () {
        return Cookies.get(key);
    };
    var setCookie = function (v, opts) {
        Cookies.set(key, v, opts);
    };
    return [getCookie(), setCookie];
}
export default useCookie;
