// @flow
import { createReducerCreator } from 'rezz';

import { setStatusesForKeyResourceFetch, setCacheStatus } from './actions';
import { handleActions, type ActionType } from 'redux-actions';
import { type KeyStatuses, type KeyCache, type StateStatusesType, StatusesTypeEnum } from './types';

const modifyStatatuses = <T: $ReadOnlyMap<string, StateStatusesType>>(
  stateStatuses: T,
  { key, ...payload }: KeyStatuses,
): $ReadOnlyMap<string, StateStatusesType> => {
  const statuses = new Map([...stateStatuses]);

  const currentStatus = statuses.get(key);

  const hasInitialLoaded = currentStatus ? currentStatus.initialLoaded : false;

  statuses.set(key, {
    isLoading: payload.status === StatusesTypeEnum.LOADING || false,
    error: payload.status === StatusesTypeEnum.ERROR ? payload.error : null,
    initialLoaded: payload.status === StatusesTypeEnum.DONE || hasInitialLoaded,
  });

  return statuses;
};

const modifyCacheStatus = (
  cache: $ReadOnlyMap<string, CacheType>,
  { key, ...payload }: KeyCache,
): $ReadOnlyMap<string, CacheType> => {
  const cacheMap = new Map([...cache]);

  cacheMap.set(key, {
    startedAt: payload.start ? new Date() : null,
    endedAt: payload.done ? new Date() : null,
  });

  if (payload.error) {
    cacheMap.delete(key);
  }

  return cacheMap;
};

type CacheType = {
  startedAt: null | Date,
  endedAt: null | Date,
  ...
};

export type ResourceFetchState = {
  statuses: $ReadOnlyMap<string, StateStatusesType>,
  cache: $ReadOnlyMap<string, CacheType>,
  ...
};

const initialState = {
  statuses: new Map([]),
  cache: new Map([]),
};

export default createReducerCreator<ResourceFetchState, _, _>({
  initialState: initialState,
})(
  handleActions(
    {
      [setStatusesForKeyResourceFetch.toString()]: (
        state,
        action: ActionType<typeof setStatusesForKeyResourceFetch>,
      ) => ({
        ...state,
        statuses: modifyStatatuses(state.statuses, action.payload),
      }),
      [setCacheStatus.toString()]: (state, action: ActionType<typeof setCacheStatus>) => ({
        ...state,
        cache: modifyCacheStatus(state.cache, action.payload),
      }),
    },
    initialState,
  ),
);
