//@flow
import createReducerCreator from 'rezz/createReducerCreator';
import updateInList from 'rezz/updateInList';
import type { OpenscanMobileApp } from '@dt/user-api/openscan';
import {
  updateApp,
  openscanMobileAppsRecieved,
  contactPublisherRequestReceived,
  contactPublisherRequestLoading,
  contactPublisherRequestError,
} from '../actions';

export type OpenscanAppsState = $ReadOnlyArray<OpenscanMobileApp>;

export default createReducerCreator<OpenscanAppsState, OpenscanMobileApp, void>({
  initialState: ([]: OpenscanAppsState),
  equalityCheck: (a, b) => a.id === b.id,
})(
  updateInList([
    updateApp.toString(),
    openscanMobileAppsRecieved.toString(),
    contactPublisherRequestReceived.toString(),
    contactPublisherRequestLoading.toString(),
    contactPublisherRequestError.toString(),
  ]),
);
