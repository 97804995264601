// @flow

import { createAction } from 'redux-actions';

export const detectInjectMounted = createAction<'detect_inject/mounted', [], { key: 'detect_inject' }>(
  'detect_inject/mounted',
  () => ({ key: 'detect_inject' }),
);

export const detectTableMounted = createAction<'detect_inject/detect_table/mounted', [], { key: 'detect_table' }>(
  'detect_inject/detect_table/mounted',
  () => ({ key: 'detect_table' }),
);

export const detectInjectResetClicked = createAction<'detect_inject/reset'>('detect_inject/reset');

export const scheduleComponentClicked = createAction<'detect_inject/schedule/clicked', [boolean], { clicked: boolean }>(
  'detect_inject/schedule/clicked',
  clicked => ({
    clicked,
  }),
);

export const detectInjectStatusRecived = createAction<
  'detect_inject/status/recived',
  [string, string, ?string],
  { status: string, date_created: string, date_completed: ?string },
>('detect_inject/status/recived', (status, date_created, date_completed) => ({
  status,
  date_created,
  date_completed,
}));

export const detectInjectStartScanClicked = createAction<'detect_inject/inject/start'>('detect_inject/inject/start');

export const detectInjectStartDetectClicked = createAction<'detect_inject/detect/start'>('detect_inject/detect/start');

export const detectInjectAtLeastOneOperationSelected = createAction<'detect_inject/inject/toggle', [boolean], boolean>(
  'detect_inject/inject/toggle',
  enable => enable,
);

export const detectInjectExcludedOperectionIdsReceived = createAction<
  'detect_inject/operation/exclude/list',
  [$ReadOnlyArray<string>],
  $ReadOnlyArray<string>,
>('detect_inject/operation/exclude/list', excludedOperationIds => excludedOperationIds);

export const detectInjectToggleOperectionClicked = createAction<'detect_inject/operation/toggle', [string], string>(
  'detect_inject/operation/toggle',
  operation_id => operation_id,
);

export const detectInjectIncludeAllOperectionsClicked = createAction<'detect_inject/operation/include/all'>(
  'detect_inject/operation/include/all',
);

export const detectInjectExcludeAllOperectionsClicked = createAction<'detect_inject/operation/exclude/all'>(
  'detect_inject/operation/exclude/all',
);

export const detectInjectConfigToggleMethodClicked = createAction<
  'detect_inject/config/method/toggle',
  [string],
  string,
>('detect_inject/config/method/toggle', method => method);

export const detectInjectViewResultsClicked = createAction<'detect_inject/results/open'>('detect_inject/results/open');

export const detectInjectViewSampleResultsClicked = createAction<'detect_inject/results/sample/open'>(
  'detect_inject/results/sample/open',
);

export const detectInjectCloseResults = createAction<'detect_inject/results/close'>('detect_inject/results/close');
