// @flow
import { type Saga } from 'redux-saga';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { select } from '@dt/redux-saga-wrapped-effects';
import { getCloudResourceDetails, getCloudResourcesList } from './resource_fetch.sagas';
import {
  takePatternAndFetch,
  watchForLoadMoreAndFetchNextPage,
  paginateWhile,
  paginateToEndLazy,
} from '../resource_fetch/sagas';
import {
  cloudResourcesDetailsAuthenticatorsMounted,
  cloudResourcesDetailsMounted,
  reachedEndOfCloudResourcesList,
  reachedEndOfCloudResourcesListForAssetSearchId,
  cloudResourcesReceived,
  cloudResourcesReceivedIdsForAssetSearch,
} from './actions';
import { type ActionType } from 'redux-actions';
import resourceType from './resourceType';
import { isUserViewingVisualization } from '../dashboard/saga-isViewingVisualization';
import { assetGroupsMembershipReceived } from '../asset_groups/memberships/actions';

import {
  getAwsAuthenticators,
  getGcpAuthenticators,
  getAzureAuthenticators,
  getAxwayAuthenticators,
  getApigeeAuthenticators,
  getMulesoftAuthenticators,
} from '../configurations/resource_fetch.sagas';
import { inventory_search } from '../inventory/search/selectors';

export default function* watchForResourceFetching(): Saga<void> {
  yield all([
    call(takePatternAndFetch, cloudResourcesDetailsMounted.toString(), function*(
      action: ActionType<typeof cloudResourcesDetailsMounted>,
    ): Saga<void> {
      let calls = [];
      calls.push(call(getCloudResourceDetails, action.payload.cloudResourceId));
      yield all(calls);
    }),
    call(takePatternAndFetch, cloudResourcesDetailsAuthenticatorsMounted.toString(), function*(): Saga<void> {
      let calls = [];
      calls.push(call(getAwsAuthenticators, {}));
      calls.push(call(getGcpAuthenticators, {}));
      calls.push(call(getAzureAuthenticators, {}));
      calls.push(call(getAxwayAuthenticators, {}));
      calls.push(call(getApigeeAuthenticators, {}));
      calls.push(call(getMulesoftAuthenticators, {}));
      yield all(calls);
    }),
    call(watchForLoadMoreAndFetchNextPage, resourceType, function*({ cursor }): Saga<void> {
      yield call(getCloudResourcesList, { cursor });
    }),
    takeEvery(cloudResourcesReceived.toString(), cloudResourcesReceivedSaga),
  ]);
}

export function* getAllCloudResourcesWhileOnVisualization(): Saga<void> {
  yield call(paginateWhile, getCloudResourcesList, resourceType, {}, isUserViewingVisualization);
}

export function* getAllCloudResourcesLazily(params: { search_id?: string, cursor?: string }): Saga<void> {
  if (params.search_id) {
    yield call(
      paginateToEndLazy,
      getCloudResourcesList,
      resourceType,
      { ...params },
      action => reachedEndOfCloudResourcesListForAssetSearchId.toString() && params.search_id === action.payload,
    );
  } else {
    yield call(
      paginateToEndLazy,
      getCloudResourcesList,
      resourceType,
      { ...params },
      reachedEndOfCloudResourcesList.toString(),
    );
  }
}

function* cloudResourcesReceivedSaga(action: ActionType<typeof cloudResourcesReceived>): Saga<void> {
  const { cloud_resources } = action.payload;
  const { lastQueryId, query } = yield* select(inventory_search, {});

  const collector = cloud_resources.reduce(
    (collector, item) => {
      if (item.asset_group_memberships) {
        collector.assetGroupMembers = collector.assetGroupMembers.concat(item.asset_group_memberships);
      }
      collector.idsForCurrentSearch.push(item.id);
      return collector;
    },
    {
      assetGroupMembers: [],
      idsForCurrentSearch: [],
    },
  );

  if (collector.assetGroupMembers) {
    yield put(assetGroupsMembershipReceived(collector.assetGroupMembers));
  }

  if (lastQueryId && (!query.asset_types.length || query.asset_types.includes('CLOUD_RESOURCE'))) {
    yield put(cloudResourcesReceivedIdsForAssetSearch(lastQueryId, collector.idsForCurrentSearch));
  }
}
