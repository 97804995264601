// @flow
import { handleActions, type ActionType } from 'redux-actions';
import { type PolicyViolationsMap } from './../util/policyViolations';
import { policyViolationsReceived } from '../actions';
import { type OpenscanMobileApp, type OpenScanAlertsFilterConfig } from '@dt/user-api/openscan';
import { reduce, includes } from 'lodash/fp';
import { PolicyViolationsLookup, PolicyViolationsEnum } from './../util/policyViolations';

export type PolicyViolationsState = {|
  ...PolicyViolationsMap<$ReadOnlyArray<string>>,
  any: $ReadOnlyArray<string>,
|};

export const emptyPolicyViolationsState = (): PolicyViolationsState => ({
  any: [],
  threshold_critical_security_issues_count: [],
  threshold_google_play_blocker_count: [],
  threshold_apple_app_store_blocker_count: [],
  threshold_sdk_with_issues_count: [],
  threshold_low_severity_issue_count: [],
  threshold_medium_severity_issues_count: [],
  threshold_high_severity_issues_count: [],
  minimum_app_protection_score: [],
  lacks_does_not_offload_data_to_third_parties: [],
  lacks_enhances_password_security: [],
  lacks_leverages_secure_browser_settings: [],
  lacks_leverages_secure_system_libraries: [],
  lacks_leverages_secure_system_sdk: [],
  lacks_leverages_secure_system_settings: [],
  lacks_protects_the_keyboard: [],
  lacks_removes_data_from_shared_device_locations: [],
  lacks_all_communication_encrypted: [],
  lacks_supports_two_factor_authentication: [],
  lacks_full_protection_on_untrusted_networks: [],
  lacks_protects_data_on_screens: [],
  lacks_requires_android_device_security: [],
  lacks_requires_android_malware_protection: [],
  lacks_server_enforces_https: [],
  threshold_sdk_count: [],
  data_at_rest_exposure: [],
  supports_apple_watch: [],
  supports_imessage: [],
  supports_face_id: [],
  has_android_permissions: [],
  has_ios_permissions: [],
  communicates_with_countries_outside_us: [],
  minimum_ios_sdk_version: [],
  minimum_android_sdk_version: [],
});

const calculatePolicyViolations = (
  apps: $ReadOnlyArray<OpenscanMobileApp>,
  policyViolationsConfig: ?OpenScanAlertsFilterConfig,
  existingPolicyViolations: PolicyViolationsState,
): PolicyViolationsState => {
  if (!policyViolationsConfig) {
    return emptyPolicyViolationsState();
  }

  return reduce((state, app) => {
    Object.keys(PolicyViolationsEnum).forEach(key => {
      if (!key) return;

      // Check if this app is in violation of a policy.
      if (PolicyViolationsLookup[key](app, policyViolationsConfig) && !includes(app.id)(state[key])) {
        // Add app to list of specific app violation.
        state[key] = [...state[key], app.id];

        // Add app to list of any app violations.
        if (!includes(app.id)(state['any'])) {
          state['any'] = [...state['any'], app.id];
        }
      }
    });

    return state;
  }, existingPolicyViolations)(apps);
};

type Actions = ActionType<typeof policyViolationsReceived>;

// PolicyViolation key lookup to violating mobile ids.
export default handleActions<PolicyViolationsState, Actions>(
  {
    [policyViolationsReceived.toString()]: (state, action: ActionType<typeof policyViolationsReceived>) => {
      const { config, apps } = action.payload;
      return calculatePolicyViolations(apps || [], config, emptyPolicyViolationsState());
    },
  },
  emptyPolicyViolationsState(),
);
