//@flow
import React, { PureComponent, type Node } from 'react';
import { palette, spacing } from '@dt/theme';

const getStyles = ({ style = {} }) => {
  let styles = {
    wrapper: {
      height: spacing.headerHeight,
      minHeight: spacing.headerHeight,
      display: 'flex',
      alignItems: 'center',
      borderBottom: `1px solid ${palette.accent}`,
      backgroundColor: palette.brightBg,
      zIndex: 5,
      justifyContent: 'space-between',
      ...style.wrapper,
    },
  };

  return {
    ...styles,
    primaryNavigationWrapper: {
      display: 'flex',
      alignItems: 'stretch',
      height: styles.wrapper.height,
      flex: 1,
      ...style.primaryNavigationWrapper,
    },
    secondaryNavigationWrapper: {
      right: 0,
      display: 'flex',
      alignItems: 'center',
      ...style.secondaryNavigationWrapper,
    },
  };
};

type Props = {
  children: Node,
  primaryNavigationItems?: Node,
  secondaryNavigationItems?: Node,
  style?: { [string]: mixed, ... },
  ...
};

/*
 * Generic header to standardize navigation items.
 *
 * @deprecated - Don't use this compoennt for a header - its too generic - instead write JSX to support your use case.
 */
export default class Header extends PureComponent<Props> {
  render() {
    const {
      primaryNavigationItems,
      secondaryNavigationItems,
      children,
    } = this.props;

    const styles = getStyles(this.props);

    return (
      <div style={styles.wrapper}>
        {children}
        {primaryNavigationItems && (
          <div style={styles.primaryNavigationWrapper}>
            {primaryNavigationItems}
          </div>
        )}
        <div style={styles.secondaryNavigationWrapper}>
          {secondaryNavigationItems}
        </div>
      </div>
    );
  }
}
