// @flow
import type { ActionType } from 'redux-actions';
import TaskStatus, { type TaskStatusEnum } from '@dt/enums/TaskStatusEnum';
import type { AppProtectionTask, AppProtectionTasksMetadata } from '@dt/user-api/mobile_apps';
import { isOpenStatus, getTargetStatus } from '@dt/findings/targets/status';
import groupBy from 'lodash/fp/groupBy';
import mapValues from 'lodash/fp/mapValues';
import { appProtectionTasksReceived, appProtectionTasksMetadataReceived } from '../actions';

export type AppProtectionTaskCategoriesState = {
  [string]: ?AppProtectionTaskCategories,
  ...,
};

export type TaskCategoryPointsAndTasks = {|
  points: number,
  count: number,
  percent: number,

  // tasks are null when the backend provides metadata only but no tasks. At
  // the time of writing, this happens when the app has no subscription.
  // Consumers of this data should take this to mean we know the points
  // and count of each category but not the details. Treat null and zero-length
  // task lists differently (if neccessary!)
  tasks: $ReadOnlyArray<AppProtectionTask> | null,
|};

export type AppProtectionTaskCategories = {|
  NOT_COMPLETED_HIDDEN: TaskCategoryPointsAndTasks,
  NOT_COMPLETED_VISIBLE: TaskCategoryPointsAndTasks,
  UNKNOWN: TaskCategoryPointsAndTasks,
  COMPLETED: TaskCategoryPointsAndTasks,
  BEYOND_SUBSCRIPTION: TaskCategoryPointsAndTasks,

  // This category is not rendered directly like the above. It's primary use is
  // to get the total number of points this app can attain. Note the
  // TaskCategoriesToRender and TaskCategoriesData types determine which of
  // these categories get rendered, and they do not include APPLICABLE
  APPLICABLE: TaskCategoryPointsAndTasks,

  // This category is also not rendered, it's used to keep track of ALL tasks
  ALL: TaskCategoryPointsAndTasks,
|};

function splitTasksByOpenAndClosed(
  tasks: $ReadOnlyArray<AppProtectionTask>,
): [$ReadOnlyArray<AppProtectionTask>, $ReadOnlyArray<AppProtectionTask>] {
  const openTasks = tasks.filter(
    task => task.targets && task.targets.some(target => isOpenStatus(getTargetStatus(target))),
  );

  const closedTasks = tasks.filter(task => !openTasks.includes(task));

  return [openTasks, closedTasks];
}

// @todo: po: write tests to ensure this function never goes above 100, or below 0, and rounds properly
function getPercent(value, total): number {
  return Math.min(100, total === 0 ? 0 : Math.round((value / total) * 100));
}

const tasksToTotalPoints = tasks =>
  tasks.map(({ app_protection_score }) => app_protection_score).reduce((total, points) => total + Number(points), 0);

const tasksToPercentageOfAll = (theseTasks, allTasks) =>
  getPercent(tasksToTotalPoints(theseTasks), tasksToTotalPoints(allTasks));

const byTaskStatus = (status: TaskStatusEnum) => ({ task_status }) => task_status === status;

function calculateCategoriesFromTasks(tasks: $ReadOnlyArray<AppProtectionTask>): AppProtectionTaskCategories {
  const applicableTasks = tasks.filter(task => !byTaskStatus(TaskStatus.UNKNOWN)(task));
  const unknownTasks = tasks
    .filter(byTaskStatus(TaskStatus.UNKNOWN))
    .filter(task => !task.is_beyond_subscription_level);
  const completedTasks = tasks.filter(byTaskStatus(TaskStatus.COMPLETED));
  const notCompletedTasks = tasks.filter(byTaskStatus(TaskStatus.NOT_COMPLETED));
  const beyondSubscriptionTasks = tasks.filter(task => task.is_beyond_subscription_level);

  const [notHiddenTasks, hiddenTasks] = splitTasksByOpenAndClosed(notCompletedTasks);

  return {
    ALL: {
      points: tasksToTotalPoints(tasks),
      count: tasks.length,
      percent: 100,
      tasks,
    },
    NOT_COMPLETED_HIDDEN: {
      points: tasksToTotalPoints(hiddenTasks),
      count: hiddenTasks.length,
      percent: tasksToPercentageOfAll(hiddenTasks, applicableTasks),
      tasks: hiddenTasks,
    },
    APPLICABLE: {
      points: tasksToTotalPoints(applicableTasks),
      count: applicableTasks.length,
      percent: 100,
      tasks: applicableTasks,
    },
    UNKNOWN: {
      points: tasksToTotalPoints(unknownTasks),
      count: unknownTasks.length,
      percent: tasksToPercentageOfAll(unknownTasks, tasks),
      tasks: unknownTasks,
    },
    COMPLETED: {
      points: tasksToTotalPoints(completedTasks),
      count: completedTasks.length,
      percent: tasksToPercentageOfAll(completedTasks, applicableTasks),
      tasks: completedTasks,
    },
    NOT_COMPLETED_VISIBLE: {
      points: tasksToTotalPoints(notHiddenTasks),
      count: notHiddenTasks.length,
      percent: tasksToPercentageOfAll(notHiddenTasks, applicableTasks),
      tasks: notHiddenTasks,
    },
    BEYOND_SUBSCRIPTION: {
      points: tasksToTotalPoints(beyondSubscriptionTasks),
      count: beyondSubscriptionTasks.length,
      percent: tasksToPercentageOfAll(beyondSubscriptionTasks, applicableTasks),
      tasks: beyondSubscriptionTasks,
    },
  };
}

function combineValuesOfFields(...fields: $ReadOnlyArray<number>): number {
  return fields.reduce((total, value) => total + value, 0);
}

function calculateCategoriesFromMetadata(metadata: AppProtectionTasksMetadata): AppProtectionTaskCategories {
  const {
    completed_points,
    completed_tasks_count,
    not_completed_points,
    not_completed_tasks_count,
    unknown_points,
    unknown_tasks_count,
    requires_enterprise_scan_points,
    requires_enterprise_scan_tasks_count,
  } = metadata;

  const completed_points_number = parseInt(completed_points, 10);
  const completed_tasks_count_number = parseInt(completed_tasks_count, 10);
  const not_completed_points_number = parseInt(not_completed_points, 10);
  const not_completed_tasks_count_number = parseInt(not_completed_tasks_count, 10);
  const unknown_points_number = parseInt(unknown_points, 10);
  const unknown_tasks_count_number = parseInt(unknown_tasks_count, 10);
  const requires_enterprise_scan_points_number = parseInt(requires_enterprise_scan_points, 10);
  const requires_enterprise_scan_tasks_count_number = parseInt(requires_enterprise_scan_tasks_count, 10);

  const all_points = combineValuesOfFields(
    completed_points_number,
    not_completed_points_number,
    unknown_points_number,
    requires_enterprise_scan_points_number,
  );

  const applicable_points = combineValuesOfFields(
    completed_points_number,
    not_completed_points_number,
    requires_enterprise_scan_points_number,
  );

  return {
    ALL: {
      points: all_points,
      count: combineValuesOfFields(
        completed_tasks_count_number,
        not_completed_tasks_count_number,
        unknown_tasks_count_number,
        requires_enterprise_scan_tasks_count_number,
      ),
      percent: 100,
      tasks: null,
    },
    NOT_COMPLETED_HIDDEN: {
      points: 0, // If your app is unsubscribed, it's possible to have hidden
      count: 0, // tasks but we wouldn't know about it, so just assume 0
      percent: 0,
      tasks: null,
    },
    APPLICABLE: {
      points: applicable_points,
      count: combineValuesOfFields(
        completed_tasks_count_number,
        not_completed_tasks_count_number,
        requires_enterprise_scan_tasks_count_number,
      ),
      percent: 100,
      tasks: null,
    },
    UNKNOWN: {
      points: unknown_points_number,
      count: unknown_tasks_count_number,
      percent: getPercent(unknown_points_number, all_points),
      tasks: null,
    },
    COMPLETED: {
      points: completed_points_number,
      count: completed_tasks_count_number,
      percent: getPercent(completed_points_number, applicable_points),
      tasks: null,
    },
    NOT_COMPLETED_VISIBLE: {
      points: not_completed_points_number,
      count: not_completed_tasks_count_number,
      percent: getPercent(not_completed_points_number, applicable_points),
      tasks: null,
    },
    BEYOND_SUBSCRIPTION: {
      points: requires_enterprise_scan_points_number,
      count: requires_enterprise_scan_tasks_count_number,
      percent: getPercent(requires_enterprise_scan_points_number, applicable_points),
      tasks: null,
    },
  };
}

export default function(
  state: AppProtectionTaskCategoriesState = {},
  action: ActionType<typeof appProtectionTasksReceived> | ActionType<typeof appProtectionTasksMetadataReceived>,
) {
  if (appProtectionTasksReceived.toString() === action.type) {
    const tasksByApp = groupBy(task => task.mobile_app_id)(action.payload);

    return {
      ...state,
      ...mapValues(
        (tasks, appId) =>
          tasks && calculateCategoriesFromTasks(tasks.concat(state[appId] ? state[appId].ALL.tasks : [])),
      )(tasksByApp),
    };
  } else if (appProtectionTasksMetadataReceived.toString() === action.type) {
    if (!state[action.payload.mobile_app_id]) {
      return {
        ...state,
        [action.payload.mobile_app_id]: calculateCategoriesFromMetadata(action.payload),
      };
    }
  }

  return state;
}
