//@flow
import immer from 'immer';
import type { ActionType } from 'redux-actions';
import type { APIOperation } from '@dt/horizon-api';
import { apiOperationsReceived } from './actions';

type Actions = ActionType<typeof apiOperationsReceived>;

export type ApiOperationsState = {|
  +id: { [string]: void | APIOperation, ... },
  +for_policy_violation_id: { [string]: void | string, ... },
|};

const initialState = {
  id: {},
  for_policy_violation_id: {},
};

export default immer<ApiOperationsState, Actions>((draft, action: Actions) => {
  if (action.type === apiOperationsReceived.toString()) {
    const { api_operations } = action.payload;

    for (const operation of api_operations) {
      draft.id[operation.id] = operation;
      for (const policy_violation_id of operation.policy_violation_ids) {
        draft.for_policy_violation_id[policy_violation_id] = operation.id;
      }
    }
  }
}, initialState);
