//@flow
import type { Application } from '@dt/user-api/mobile_apps';
import TemplateScanTypeEnum from '@dt/enums/TemplateScanTypeEnum';
import { type AppScanBlockedReasonEnum as AppScanBlockedReasonEnumType } from '@dt/enums/AppScanBlockedReasonEnum';
import { fromNow } from '@dt/date';
import { default as MobileAppReleaseTypeEnum } from '@dt/enums/MobileAppReleaseTypeEnum';

const SUBSCRIPTION_LEVEL_TO_RELATIVE_PRICE = {
  NO_SUBSCRIPTION: 5,
  P1_ALERTS: 10,
  STATIC: 20,
  APP_LOGIC: 50,
  DYNAMIC: 40,
};

const SUBSCRIPTION_LEVEL: $ReadOnlyArray<string> = Object.keys(SUBSCRIPTION_LEVEL_TO_RELATIVE_PRICE);

export function hasSubscription(app: { +subscription: string, ... }): boolean {
  return app ? app.subscription !== TemplateScanTypeEnum.NO_SUBSCRIPTION : false;
}

export function isScanBlocked(blockedScanReason: ?AppScanBlockedReasonEnumType): boolean {
  return blockedScanReason === 'CORRUPTED_APP_PACKAGE' || blockedScanReason === 'NO_CREDENTIALS';
}

export function getHumanReadableScanStatus(app: Application): ?string {
  if (!app || !app.most_recent_scan) {
    return null;
  }

  return getHumanReadableScanStatusFromScan(app.most_recent_scan);
}

export function getHumanReadableScanStatusFromScan(
  scan: ?{
    +start_date?: ?string,
    +end_date?: ?string,
    ...
  },
  blockedScanReason: ?AppScanBlockedReasonEnumType,
): string {
  let status = getScanStatus(scan, blockedScanReason);
  return status === scanStatusEnum.SCANNED && scan && scan.end_date
    ? scanStatusToText[status] + ' ' + fromNow(scan.end_date)
    : scanStatusToText[status];
}

export const scanStatusEnum = {
  NEVER_SCANNED: ('never-scanned': 'never-scanned'),
  BLOCKED: ('blocked': 'blocked'),
  ONGOING: ('ongoing': 'ongoing'),
  SCANNED: ('scanned': 'scanned'),
};

const scanStatusToText = {
  [scanStatusEnum.NEVER_SCANNED]: 'Never Scanned',
  [scanStatusEnum.BLOCKED]: 'Scan Blocked',
  [scanStatusEnum.ONGOING]: 'Scan Ongoing',
  [scanStatusEnum.SCANNED]: 'Scanned',
};

type ScanStatus = $Values<typeof scanStatusEnum>;

export function getScanStatus(
  scan: ?{
    +start_date?: ?string,
    +end_date?: ?string,
    ...
  },
  blockedScanReason: ?AppScanBlockedReasonEnumType,
): ScanStatus {
  if (!scan) return scanStatusEnum.NEVER_SCANNED;
  let { start_date, end_date } = scan;
  if (!start_date) return scanStatusEnum.NEVER_SCANNED;
  if (!end_date && isScanBlocked(blockedScanReason)) return scanStatusEnum.BLOCKED;
  if (!end_date) return scanStatusEnum.ONGOING;
  return scanStatusEnum.SCANNED;
}

export function getListOfHigherSubscriptions(subscription: string): ?$ReadOnlyArray<string> {
  const currentSubscriptionPower = SUBSCRIPTION_LEVEL_TO_RELATIVE_PRICE[subscription];
  if (!currentSubscriptionPower) {
    return null;
  }

  return SUBSCRIPTION_LEVEL.reduce(
    (final, sub) => (SUBSCRIPTION_LEVEL_TO_RELATIVE_PRICE[sub] > currentSubscriptionPower ? final.concat(sub) : final),
    [],
  ).sort((a, b) => SUBSCRIPTION_LEVEL_TO_RELATIVE_PRICE[a] - SUBSCRIPTION_LEVEL_TO_RELATIVE_PRICE[b]);
}

export function isAppStoreApp(app: { +release_type: ?string, ... }): boolean {
  return app && app.release_type === MobileAppReleaseTypeEnum.APP_STORE;
}

export function isAppStoreAppThirdParty(app: { +release_type: ?string, ... }): boolean {
  return app && app.release_type === MobileAppReleaseTypeEnum.APP_STORE_THIRD_PARTY;
}

export function isEnterpriseApp(app: { +release_type: ?string, ... }): boolean {
  return app && app.release_type === MobileAppReleaseTypeEnum.ENTERPRISE;
}

export function isPreProdApp(app: { +release_type: ?string, ... }): boolean {
  return app && app.release_type === MobileAppReleaseTypeEnum.PRE_PROD;
}

export function isProdApp(app: { +release_type: ?string, is_enterprise_internal: boolean, ... }): boolean {
  return isEnterpriseApp(app) || (isAppStoreApp(app) && !app.is_enterprise_internal);
}
