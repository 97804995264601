//@flow
import type { FieldDefinitionNode, NonNullTypeNode } from 'graphql';

export default function extractNamedTypeFromField(
  field: FieldDefinitionNode | NonNullTypeNode,
): string | { error: string } {
  const name = field.kind === 'FieldDefinition' ? field.name.value : 'Unknown';
  if (field.type.kind === 'NamedType') {
    return field.type.name.value;
  }
  if (field.type.kind === 'ListType') {
    if (field.type.type.kind === 'ListType') {
      return { error: `Nested lists are not supported. (on ${name})` };
    }
    if (field.type.type.kind === 'NamedType') {
      return field.type.type.name.value;
    }
    return extractNamedTypeFromField(field.type.type);
  }
  if (field.type.kind === 'NonNullType') {
    return extractNamedTypeFromField(field.type);
  }
  return {
    error: `This type of node is not supported. (type ${field.type} on ${name})`,
  };
}
