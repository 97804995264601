// @flow
import React, { memo, type Node } from 'react';
import { Link } from '@reach/router';
import { type Properties } from 'csstype';
import ExtLink from './ExtLink';

type Props = {
  children: Node,
  activeStyle: Properties<>,
  exact?: boolean,
  redirect?: boolean,
  to: string,
  target?: '_self' | '_blank' | '_parent' | '_top',
  text?: string,
  ...
};

// This component is tailored to be used within apps that use
// `@reach/router`. We probably need something similar for apps
// that use `react-router` for when we need to add new left side
// navigation that `web` portal uses.
function NavLink({
  children,
  activeStyle,
  to,
  text,
  exact = false,
  redirect = false,
  target = '_blank',
  ...rest
}: Props) {
  // TODO: Temporary hack to prevent highlighting duplicate menu entries
  const disableHighlight = to === '/cloud/policies/default' && text === 'CSPM';

  return redirect ? (
    <ExtLink {...rest} text={text} to={to} target={redirect ? target : '_self'}>
      {children}
    </ExtLink>
  ) : (
    <Link
      {...rest}
      to={to}
      text={text}
      getProps={({ isCurrent, isPartiallyCurrent }) => {
        let style = {};
        if (exact) {
          if (isCurrent && !disableHighlight) style = activeStyle;
        } else {
          if (isPartiallyCurrent && !disableHighlight) style = activeStyle;
        }
        return {
          style: { ...style },
        };
      }}
    >
      {children}
    </Link>
  );
}

export default memo<Props>(NavLink);
