//@flow
const badgeType = {
  all_communication_encrypted: 'all_communication_encrypted',
  supports_two_factor_authentication: 'supports_two_factor_authentication',
  full_protection_on_untrusted_networks:
    'full_protection_on_untrusted_networks',
  protects_data_on_screens: 'protects_data_on_screens',
  requires_android_device_security: 'requires_android_device_security',
  requires_android_malware_protection: 'requires_android_malware_protection',
  server_enforces_https: 'server_enforces_https',
  removes_data_from_shared_device_locations:
    'removes_data_from_shared_device_locations',
  protects_the_keyboard: 'protects_the_keyboard',
  leverages_secure_system_sdk: 'leverages_secure_system_sdk',
  leverages_secure_browser_settings: 'leverages_secure_browser_settings',
  leverages_secure_system_libraries: 'leverages_secure_system_libraries',
  leverages_secure_system_settings: 'leverages_secure_system_settings',
  enhances_password_security: 'enhances_password_security',
  does_not_offload_data_to_third_parties:
    'does_not_offload_data_to_third_parties',
};

export default badgeType;

export type BadgeTypeEnum = $Keys<typeof badgeType>;
