// @flow

import type {
  AlertsIntegrationsCreateMutation,
  AlertsIntegrationsCreateMutationVariables,
  AlertsIntegrationsDeleteMutation,
  AlertsIntegrationsDeleteMutationVariables,
  AlertsIntegrationsListQuery,
  AlertsIntegrationsListQueryVariables,
} from '@dt/graphql-support/types';

import alerts_integrations from '@dt/graphql-support/public/integrations/alerts_integrations';
import { useMutation } from '@apollo/client';
import type { SnackbarStatus } from './AwsSecurityHubConfiguration';

type Params = {
  setSnackbarStatus: (type: SnackbarStatus) => void,
  queryVariables: AlertsIntegrationsListQueryVariables,
};

const useAwsSecurityHub = ({ setSnackbarStatus, queryVariables }: Params) => {
  const deleteMutation = useMutation<AlertsIntegrationsDeleteMutation, AlertsIntegrationsDeleteMutationVariables>(
    alerts_integrations.delete,
    {
      update: (cache, result) => {
        if (result?.errors) {
          return;
        }
        setSnackbarStatus('success');
        const q = cache.readQuery<AlertsIntegrationsListQuery, AlertsIntegrationsListQueryVariables>({
          query: alerts_integrations.list,
          variables: queryVariables,
        });
        cache.writeQuery({
          query: alerts_integrations.list,
          variables: queryVariables,
          data: {
            alerts_integrations_list: {
              ...q?.alerts_integrations_list,
              alerts_integrations: [],
            },
          },
        });
      },
    },
  );

  const createMutation = useMutation<AlertsIntegrationsCreateMutation, AlertsIntegrationsCreateMutationVariables>(
    alerts_integrations.create,
    {
      update: (cache, result) => {
        if (result?.errors) {
          return;
        }
        setSnackbarStatus('success');
        const q = cache.readQuery<AlertsIntegrationsListQuery, AlertsIntegrationsListQueryVariables>({
          query: alerts_integrations.list,
          variables: queryVariables,
        });

        const newItem = result.data.alerts_integrations_create;
        cache.writeQuery({
          query: alerts_integrations.list,
          variables: queryVariables,
          data: {
            alerts_integrations_list: {
              ...q?.alerts_integrations_list,
              alerts_integrations: [newItem],
            },
          },
        });
      },
    },
  );

  return {
    deleteMutation,
    createMutation,
  };
};

export default useAwsSecurityHub;
