// @flow
import config from '@dt/config';
import fetch, { parse, type APIResult as FetchResponse } from '@dt/fetch';

const api = config.horizonApiBaseUrl;
const version = 'v1';

export type CertificateTransparencyDomains = {|
  domain_names: $ReadOnlyArray<string>,
|};

export async function get(): FetchResponse<CertificateTransparencyDomains> {
  return fetch(`${api}/${version}/certificate_transparency_domains`).then(
    parse,
  );
}
