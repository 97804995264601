//@flow
export type PaginationInformation = {|
  +total_count?: ?number,
  +next_cursor?: ?string,
|};
export default async function* pages<
  T: { +pagination_information: PaginationInformation, ... },
>(
  requestFn: ({ +cursor?: ?string, ... }) => Promise<T>,
): AsyncGenerator<T, void, void> {
  let cursor;
  do {
    const response = await requestFn({ cursor });

    // Backend should always provide pagination information on pagable requests.
    if (!response.pagination_information) {
      throw new Error(
        "'pagination_information' was not found on the response.",
      );
    }

    cursor = response.pagination_information.next_cursor;

    yield response;
  } while (cursor);
}
