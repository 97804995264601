//@flow
import type { Application } from '@dt/user-api/mobile_apps';
import type { JiraIntegrationConfig } from '@dt/user-api/jira_config';
import type { ActionType } from 'redux-actions';
import { jiraIntegrationReceivedListConfigurations } from '../actions';

export type JiraIntegrationState = {|
  +configurations: ConfigurationList,
  +isThereAGlobalConfiguration: boolean,
|};

export type Configuration = {|
  app: Application,
  fields: ?JiraIntegrationConfig,
  isGlobal: boolean,
|};

export type ConfigurationList = Array<Configuration>;

const initialState = {
  configurations: [],
  isThereAGlobalConfiguration: false,
};

export default (state: JiraIntegrationState = initialState, action: ActionType<*>) => {
  if (action.type === jiraIntegrationReceivedListConfigurations.toString()) {
    return {
      configurations: action.payload.configurations,
      isThereAGlobalConfiguration: action.payload.isThereAGlobalConfiguration,
    };
  }

  return state;
};
