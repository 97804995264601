// @flow
import immer from 'immer';
import { type ActionType } from 'redux-actions';
import {
  createActiveSubscriptionAction,
  createPastDueSubscriptionAction,
  createDemoSubscriptionAction,
  dismissBillingReminderAlertAction,
  openUploadPoComponentAction,
  closeUploadPoComponentAction,
  uploadPoFileAction,
  loadPurchaseQuoteAction,
  purchaseQuoteAppListFetchSucceed,
  purchaseQuoteApiStatsFetchSucceed,
  purchaseQuoteSelectSubscription,
  purchaseQuoteSelectDiscount,
  purchaseQuoteAppSecureSelectApp,
  purchaseQuoteAppSecureDeselectAllApps,
  purchaseQuoteAppSecureSelectAllApps,
  purchaseQuoteApiSecureSelectPlan,
} from './actions';
import {
  getSubscriptionEndDateInDays,
  recentlyDismissed,
  isBillingAdminUser as isCurrentUserBilling,
} from '../utls';
import { reminderContents } from '../contents';
import { type BillingInfoStateType } from '../types';

export const initialState: BillingInfoStateType = {
  name: '',
  status: 'unsubscribed',
  period: {
    endDate: undefined,
    startDate: undefined,
    daysToExpire: 0,
  },
  reminder: {
    shouldShow: true,
    closed: false,
    isOpen: false,
    title: '',
    description: '',
  },
  purchaseQuote: {
    shouldShow: true,
    subscriptions: {
      list: [
        {
          id: 1,
          desc: '1 year subscription',
          discountPresentage: 0,
        },
        {
          id: 2,
          desc: '2 year subscription',
          discountPresentage: 2.5,
        },
        {
          id: 3,
          desc: '3 year subscription',
          discountPresentage: 4,
        },
      ],
      selected_id: 1,
    },
    discounts: [
      {
        id: 1,
        desc: 'CI/CD Integration Completed (e.g. Jenkins) during Trial.',
        discountPresentage: 2,
        example: null,
        selected: false,
      },
      {
        id: 2,
        desc: 'Bug Tracking Integration Completed (e.g. Jira) during Trial',
        discountPresentage: 2,
        example: null,
        selected: false,
      },
      {
        id: 3,
        desc: `Agree to Case Study`,
        example: 'https://www.datatheorem.com/customers/netflix/',
        discountPresentage: 4,
        selected: false,
      },
      {
        id: 4,
        desc: 'Countersigned Agreement within 30 days',
        discountPresentage: 5,
        example: null,
        selected: false,
      },
      {
        id: 5,
        desc: 'Obtain App Protection Score > 51%',
        discountPresentage: 2,
        example: null,
        selected: false,
      },
    ],
    api_secure: {
      stats: {
        restful_apis: 0,
        api_ops: 0,
        web_applications: 0,
        dbs: 0,
        serverless: 0,
      },
      plans: [
        {
          id: 1,
          name: 'Baseline',
          price: 4200,
          priceMoneyFormat: '$4,200',
          shadowApiDetection: true,
          restfulApiOperations: 50,
          webApps: 10,
          storageDb: 100,
          serverlessApps: 10,
        },
        {
          id: 2,
          name: 'Enterprise',
          price: 6250,
          priceMoneyFormat: '$6,250',
          shadowApiDetection: true,
          restfulApiOperations: 100,
          webApps: 20,
          storageDb: 125,
          serverlessApps: 15,
        },
        {
          id: 3,
          name: 'Prime',
          price: 8400,
          priceMoneyFormat: '$8,400',
          shadowApiDetection: true,
          restfulApiOperations: 150,
          webApps: 25,
          storageDb: 150,
          serverlessApps: 30,
        },
      ],
      recommended_plan_id: 0,
      selected_plan_id: null,
    },
    app_list: [],
  },
  quoteUrl: {
    shouldShow: false,
  },
  payNow: {
    shouldShow: false,
  },
  poUpload: {
    shouldShow: false,
  },
  notifyBilling: {
    shouldShow: false,
  },
  shouldShowBillingReminder: false,
  isCurrentUserBillingAdmin: false,
  shouldAlertBillingUser: false,
};
type Actions =
  | ActionType<typeof createActiveSubscriptionAction>
  | ActionType<typeof createPastDueSubscriptionAction>
  | ActionType<typeof createDemoSubscriptionAction>
  | ActionType<typeof dismissBillingReminderAlertAction>
  | ActionType<typeof openUploadPoComponentAction>
  | ActionType<typeof closeUploadPoComponentAction>
  | ActionType<typeof uploadPoFileAction>
  | ActionType<typeof loadPurchaseQuoteAction>
  | ActionType<typeof purchaseQuoteAppListFetchSucceed>
  | ActionType<typeof purchaseQuoteApiStatsFetchSucceed>
  | ActionType<typeof purchaseQuoteSelectSubscription>
  | ActionType<typeof purchaseQuoteSelectDiscount>
  | ActionType<typeof purchaseQuoteAppSecureSelectApp>
  | ActionType<typeof purchaseQuoteAppSecureSelectAllApps>
  | ActionType<typeof purchaseQuoteAppSecureDeselectAllApps>
  | ActionType<typeof purchaseQuoteApiSecureSelectPlan>;

// $FlowFixMe
export default immer<BillingInfoStateType>(
  (draft: BillingInfoStateType, action: Actions) => {
    if (action.type === createActiveSubscriptionAction.toString()) {
      const { accountInfo, currentUser } = action.payload;
      const {
        name,
        subscription_period,
        renewal_info,
        toggles,
        billing_contacts,
      } = accountInfo;
      const { login_email } = currentUser;
      const { pay_by_credit_card_url, po_quote_url } = renewal_info || {};

      draft.name = name ? name : '';
      draft.status = 'active';

      const expirationDate = subscription_period
        ? subscription_period.end_date
        : '';
      const shouldShowRenewalReminder =
        toggles && toggles.renewal_reminders ? true : false;
      const daysToExpire = getSubscriptionEndDateInDays(expirationDate);
      draft.shouldShowBillingReminder = (() => {
        if (shouldShowRenewalReminder && expirationDate) {
          if ((daysToExpire || daysToExpire === 0) && daysToExpire <= 90) {
            if (!recentlyDismissed(currentUser)) {
              return true;
            }
          }
        }
        return false;
      })();
      draft.isCurrentUserBillingAdmin = isCurrentUserBilling(
        login_email,
        billing_contacts,
      );
      draft.shouldAlertBillingUser =
        draft.isCurrentUserBillingAdmin && daysToExpire <= 90;
      draft.period = {
        startDate: subscription_period?.start_date,
        endDate: subscription_period?.end_date,
        daysToExpire,
      };

      if (
        draft.isCurrentUserBillingAdmin &&
        daysToExpire <= 90 &&
        pay_by_credit_card_url
      ) {
        draft.payNow = {
          shouldShow: true,
          credit_card_url: pay_by_credit_card_url,
        };
      }

      if (
        draft.isCurrentUserBillingAdmin &&
        daysToExpire <= 90 &&
        po_quote_url
      ) {
        draft.quoteUrl = {
          shouldShow: true,
          quote_url: po_quote_url,
        };
      }

      if (draft.isCurrentUserBillingAdmin && daysToExpire <= 90) {
        draft.poUpload = {
          shouldShow: true,
          isOpen: false,
        };
      }

      draft.reminder = (() =>
        draft.shouldShowBillingReminder
          ? {
              shouldShow: true,
              closed:
                draft.shouldShowBillingReminder &&
                draft.reminder &&
                draft.reminder.closed
                  ? true
                  : false,
              isOpen: draft.shouldShowBillingReminder,
              title: (() => {
                if (daysToExpire >= 60)
                  return reminderContents.title['60_days'];
                if (daysToExpire >= 30)
                  return reminderContents.title['30_days'];
                if (daysToExpire >= 15)
                  return reminderContents.title['15_days'];
                if (daysToExpire === 1) {
                  return reminderContents.title['beforeExp']
                    .replace('{number}', daysToExpire.toString())
                    .replace('days', 'day');
                }
                if (daysToExpire === 0) return reminderContents.title['today'];
                return reminderContents.title['beforeExp'].replace(
                  '{number}',
                  daysToExpire.toString(),
                );
              })(),
              description:
                draft.payNow.shouldShow || draft.quoteUrl.shouldShow
                  ? reminderContents.desc['beforeExp']['plural']
                  : reminderContents.desc['beforeExp']['singular'],
            }
          : {
              shouldShow: false,
            })();

      draft.purchaseQuote = {
        shouldShow: false,
      };

      if (draft.shouldShowBillingReminder && !draft.isCurrentUserBillingAdmin) {
        draft.notifyBilling = {
          shouldShow: true,
        };
      }
    }

    if (action.type === createPastDueSubscriptionAction.toString()) {
      const { accountInfo, currentUser } = action.payload;
      const {
        name,
        subscription_period,
        renewal_info,
        toggles,
        billing_contacts,
      } = accountInfo;
      const { login_email } = currentUser;
      const { pay_by_credit_card_url, po_quote_url } = renewal_info || {};

      draft.name = name ? name : '';
      draft.status = 'past-due';

      const expirationDate = subscription_period
        ? subscription_period.end_date
        : '';
      const shouldShowRenewalReminder =
        toggles && toggles.renewal_reminders ? true : false;
      const daysToExpire = getSubscriptionEndDateInDays(expirationDate);
      draft.shouldShowBillingReminder = shouldShowRenewalReminder
        ? true
        : false;
      draft.isCurrentUserBillingAdmin = isCurrentUserBilling(
        login_email,
        billing_contacts,
      );
      draft.shouldAlertBillingUser =
        draft.isCurrentUserBillingAdmin && daysToExpire <= 90;
      draft.period = {
        startDate: subscription_period?.start_date,
        endDate: subscription_period?.end_date,
        daysToExpire,
      };

      if (
        draft.isCurrentUserBillingAdmin &&
        daysToExpire <= 90 &&
        pay_by_credit_card_url
      ) {
        draft.payNow = {
          shouldShow: true,
          credit_card_url: pay_by_credit_card_url,
        };
      }

      if (
        draft.isCurrentUserBillingAdmin &&
        daysToExpire <= 90 &&
        po_quote_url
      ) {
        draft.quoteUrl = {
          shouldShow: true,
          quote_url: po_quote_url,
        };
      }

      if (draft.isCurrentUserBillingAdmin && daysToExpire <= 90) {
        draft.poUpload = {
          shouldShow: true,
          isOpen: false,
        };
      }

      draft.reminder = (() =>
        draft.shouldShowBillingReminder
          ? {
              shouldShow: true,
              closed:
                draft.shouldShowBillingReminder &&
                draft.reminder &&
                draft.reminder.closed
                  ? true
                  : false,
              isOpen: draft.shouldShowBillingReminder,
              title: (() => {
                return reminderContents.title['beforeExp']
                  .replace('days.', '')
                  .replace('{number}', `${Math.abs(daysToExpire)} day(s) ago.`)
                  .replace('expires in', 'expired');
              })(),
              description:
                draft.payNow.shouldShow || draft.quoteUrl.shouldShow
                  ? reminderContents.desc['beforeExp']['plural']
                  : reminderContents.desc['beforeExp']['singular'],
            }
          : {
              shouldShow: false,
            })();

      draft.purchaseQuote = {
        shouldShow: false,
      };

      if (draft.shouldShowBillingReminder && !draft.isCurrentUserBillingAdmin) {
        draft.notifyBilling = {
          shouldShow: true,
        };
      }
    }

    if (action.type === createDemoSubscriptionAction.toString()) {
      const { accountInfo, currentUser } = action.payload || {};
      const {
        name,
        last_login_date,
        renewal_info,
        toggles,
        billing_contacts,
      } = accountInfo;
      const { login_email } = currentUser || {};
      const { po_quote_url } = renewal_info || {};

      draft.name = name ? name : '';
      draft.status = 'demo';

      const expirationDate = last_login_date ? last_login_date : '';
      const shouldShowRenewalReminder =
        toggles && toggles.renewal_reminders ? true : false;
      const daysToExpire = getSubscriptionEndDateInDays(expirationDate);
      draft.shouldShowBillingReminder = (() => {
        if (shouldShowRenewalReminder && expirationDate) {
          if ((daysToExpire || daysToExpire === 0) && daysToExpire <= 90) {
            if (!recentlyDismissed(currentUser)) {
              return true;
            }
          }
        }
        return false;
      })();
      draft.isCurrentUserBillingAdmin = isCurrentUserBilling(
        login_email,
        billing_contacts,
      );
      draft.shouldAlertBillingUser =
        draft.isCurrentUserBillingAdmin && daysToExpire <= 90;
      draft.period = {
        startDate: undefined,
        endDate: expirationDate,
        daysToExpire,
      };

      if (
        draft.isCurrentUserBillingAdmin &&
        daysToExpire <= 90 &&
        po_quote_url
      ) {
        draft.quoteUrl = {
          shouldShow: true,
          quote_url: po_quote_url,
        };
      }

      if (draft.isCurrentUserBillingAdmin && daysToExpire <= 90) {
        draft.poUpload = {
          shouldShow: true,
          isOpen: false,
        };
      }

      draft.reminder = (() =>
        draft.shouldShowBillingReminder
          ? {
              shouldShow: true,
              closed:
                draft.shouldShowBillingReminder &&
                draft.reminder &&
                draft.reminder.closed
                  ? true
                  : false,
              isOpen: draft.shouldShowBillingReminder,
              title: (() => {
                if (daysToExpire >= 60)
                  return reminderContents.title['60_days'].replace(
                    'subscription',
                    'Trial',
                  );
                if (daysToExpire >= 30)
                  return reminderContents.title['30_days'].replace(
                    'subscription',
                    'Trial',
                  );
                if (daysToExpire >= 15)
                  return reminderContents.title['15_days'].replace(
                    'subscription',
                    'Trial',
                  );
                if (daysToExpire === 1) {
                  return reminderContents.title['beforeExp']
                    .replace('{number}', daysToExpire.toString())
                    .replace('days', 'day')
                    .replace('subscription', 'Trial');
                }
                if (daysToExpire === 0) return reminderContents.title['today'];
                if (daysToExpire < 0)
                  return reminderContents.title['beforeExp']
                    .replace('days.', '')
                    .replace(
                      '{number}',
                      `${Math.abs(daysToExpire)} day(s) ago.`,
                    )
                    .replace('expires in', 'expired');
                return reminderContents.title['beforeExp']
                  .replace('{number}', daysToExpire.toString())
                  .replace('subscription', 'Trial');
              })(),
              description: '',
            }
          : {
              shouldShow: false,
            })();

      if (!draft.isCurrentUserBillingAdmin) {
        draft.purchaseQuote = {
          shouldShow: false,
        };
      }

      if (draft.shouldShowBillingReminder && !draft.isCurrentUserBillingAdmin) {
        draft.notifyBilling = {
          shouldShow: true,
        };
      }
    }

    if (action.type === dismissBillingReminderAlertAction.toString()) {
      draft.reminder = {
        shouldShow: false,
      };
    }

    if (action.type === loadPurchaseQuoteAction.toString()) {
      draft.reminder = {
        shouldShow: false,
      };
    }

    if (action.type === openUploadPoComponentAction.toString()) {
      if (draft.poUpload.shouldShow) {
        draft.poUpload.isOpen = true;
      }
    }

    if (action.type === closeUploadPoComponentAction.toString()) {
      if (draft.poUpload.shouldShow) {
        draft.poUpload.isOpen = false;
      }
    }

    if (action.type === purchaseQuoteAppListFetchSucceed.toString()) {
      const { appList } = action.payload;
      const newAppList = appList.filter(
        item =>
          draft.purchaseQuote.shouldShow &&
          !draft.purchaseQuote.app_list.filter(
            draftItem => draftItem.id === item.id,
          ).length,
      );
      if (draft.purchaseQuote.shouldShow) {
        draft.purchaseQuote.app_list = [
          ...draft.purchaseQuote.app_list,
          ...newAppList,
        ];
      }
    }

    if (action.type === purchaseQuoteApiStatsFetchSucceed.toString()) {
      const {
        restful_apis,
        api_ops,
        web_applications,
        dbs,
        serverless,
      } = action.payload.stats;
      if (draft.purchaseQuote.shouldShow) {
        draft.purchaseQuote.api_secure.stats = {
          restful_apis,
          api_ops,
          web_applications,
          dbs,
          serverless,
        };
        for (
          let index = 0;
          index < draft.purchaseQuote.api_secure.plans.length;
          index++
        ) {
          const plan = draft.purchaseQuote.api_secure.plans[index];
          if (plan.restfulApiOperations >= api_ops && api_ops > 0) {
            draft.purchaseQuote.api_secure.recommended_plan_id = plan.id;
            break;
          }
        }
      }
    }

    if (action.type === purchaseQuoteSelectSubscription.toString()) {
      const { subscriptionId } = action.payload;
      if (draft.purchaseQuote.shouldShow) {
        draft.purchaseQuote.subscriptions.selected_id = subscriptionId;
      }
    }

    if (action.type === purchaseQuoteSelectDiscount.toString()) {
      const { discountId, selected } = action.payload;
      if (draft.purchaseQuote.shouldShow) {
        draft.purchaseQuote = {
          ...draft.purchaseQuote,
          discounts: draft.purchaseQuote.discounts.map(item => {
            if (item.id === discountId) {
              return {
                ...item,
                selected,
              };
            }
            return item;
          }),
        };
      }
    }

    if (action.type === purchaseQuoteAppSecureSelectApp.toString()) {
      const { appId, checked } = action.payload;
      if (draft.purchaseQuote.shouldShow) {
        draft.purchaseQuote = {
          ...draft.purchaseQuote,
          app_list: draft.purchaseQuote.app_list.map(item => {
            if (item.id === appId) {
              return {
                ...item,
                checked: checked ? true : false,
              };
            }
            return item;
          }),
        };
      }
    }

    if (action.type === purchaseQuoteAppSecureSelectAllApps.toString()) {
      if (draft.purchaseQuote.shouldShow) {
        draft.purchaseQuote = {
          ...draft.purchaseQuote,
          app_list: draft.purchaseQuote.app_list.map(item => {
            return {
              ...item,
              checked: true,
            };
          }),
        };
      }
    }

    if (action.type === purchaseQuoteAppSecureDeselectAllApps.toString()) {
      if (draft.purchaseQuote.shouldShow) {
        draft.purchaseQuote = {
          ...draft.purchaseQuote,
          app_list: draft.purchaseQuote.app_list.map(item => {
            return {
              ...item,
              checked: false,
            };
          }),
        };
      }
    }

    if (action.type === purchaseQuoteApiSecureSelectPlan.toString()) {
      const { plainId } = action.payload;
      if (draft.purchaseQuote.shouldShow) {
        draft.purchaseQuote.api_secure.selected_plan_id = plainId;
      }
    }
  },
  initialState,
);
