// @flow
import immer from 'immer';
import { type ActionType } from 'redux-actions';
import formatDate from 'date-fns/format';
import {
  detectInjectMounted,
  detectInjectStatusRecived,
  detectInjectResetClicked,
  scheduleComponentClicked,
  detectInjectStartDetectClicked,
  detectInjectStartScanClicked,
  detectInjectAtLeastOneOperationSelected,
  detectInjectConfigToggleMethodClicked,
  detectInjectExcludedOperectionIdsReceived,
  detectInjectToggleOperectionClicked,
  detectInjectIncludeAllOperectionsClicked,
  detectInjectExcludeAllOperectionsClicked,
  detectInjectViewResultsClicked,
  detectInjectViewSampleResultsClicked,
  detectInjectCloseResults,
} from './actions';
import { DETECT_INJECT_POLICY_RULE_TYPE_ID } from './types';
import type { ConfigurationMethodsType, resultsType, detectType, injectType, scheduleType } from './types';

export type DetectInjectState = {
  targetRuleTypeUUID: string,
  configuration: {|
    method: ConfigurationMethodsType,
  |},

  includedOperations: {
    all: boolean,
    list: {
      [string]: boolean | void,
      ...,
    },
    ...
  },
  excludedOperations: {
    list: {
      [string]: boolean | void,
      ...,
    },
    ...
  },
  results: resultsType,
  detect: detectType,
  inject: injectType,
  schedule: scheduleType,
  scanning: boolean,
};

type Actions =
  | ActionType<typeof detectInjectMounted>
  | ActionType<typeof detectInjectResetClicked>
  | ActionType<typeof scheduleComponentClicked>
  | ActionType<typeof detectInjectStatusRecived>
  | ActionType<typeof detectInjectConfigToggleMethodClicked>
  | ActionType<typeof detectInjectStartDetectClicked>
  | ActionType<typeof detectInjectStartScanClicked>
  | ActionType<typeof detectInjectAtLeastOneOperationSelected>
  | ActionType<typeof detectInjectExcludedOperectionIdsReceived>
  | ActionType<typeof detectInjectToggleOperectionClicked>
  | ActionType<typeof detectInjectIncludeAllOperectionsClicked>
  | ActionType<typeof detectInjectExcludeAllOperectionsClicked>
  | ActionType<typeof detectInjectViewResultsClicked>
  | ActionType<typeof detectInjectViewSampleResultsClicked>
  | ActionType<typeof detectInjectCloseResults>;

const initialState = {
  targetRuleTypeUUID: DETECT_INJECT_POLICY_RULE_TYPE_ID,
  configuration: {
    method: {
      GET: true,
      POST: true,
      PUT: true,
      DELETE: true,
      PATCH: true,
      UNKNOWN: true,
      HEAD: false,
      OPTIONS: false,
      TRACE: false,
      X_HORIZON_UNDEFINED_HTTP_METHOD: false,
    },
  },
  includedOperations: {
    all: false,
    list: {},
  },
  excludedOperations: {
    list: {},
  },

  results: {
    enabled: false,
    sampleClicked: false,
  },

  detect: { enabled: false },

  inject: { enabled: false },

  schedule: { clicked: false },

  scanning: false,
};

export default immer<DetectInjectState, Actions>((draft, action: Actions) => {
  if (action.type === detectInjectMounted.toString()) {
    draft.detect = { enabled: true, started: false };
    draft.inject = { enabled: false };
    draft.schedule = { clicked: false };

    if (draft.results.enabled) {
      draft.results.clicked = false;
    }

    draft.configuration.method['GET'] = true;
    draft.configuration.method['POST'] = true;
    draft.configuration.method['PUT'] = true;
    draft.configuration.method['DELETE'] = true;
    draft.configuration.method['PATCH'] = true;
    draft.configuration.method['UNKNOWN'] = true;

    draft.includedOperations = {
      all: false,
      list: {},
    };
  }

  if (action.type === detectInjectResetClicked.toString()) {
    draft.detect = { enabled: true, started: false };
    draft.inject = { enabled: false };
    draft.schedule = { clicked: false };

    if (draft.results.enabled) {
      draft.results.clicked = false;
    }
    draft.results.sampleClicked = false;

    draft.configuration.method['GET'] = true;
    draft.configuration.method['POST'] = true;
    draft.configuration.method['PUT'] = true;
    draft.configuration.method['DELETE'] = true;
    draft.configuration.method['PATCH'] = true;
    draft.configuration.method['UNKNOWN'] = true;

    draft.includedOperations = {
      all: false,
      list: {},
    };
  }

  if (action.type === detectInjectStatusRecived.toString()) {
    const { status, date_created, date_completed } = action.payload;
    if (status === 'ONGOING') {
      draft.detect = { enabled: false };
      draft.results = { enabled: false, sampleClicked: false };
      draft.inject = { enabled: false };
      draft.scanning = true;
    } else if (status === 'NOT_STARTED') {
      draft.detect = { enabled: true, started: false };
      if (draft.results.enabled) {
        draft.results.clicked = false;
      }
      draft.inject = { enabled: false };
    } else if (status === 'FINISHED' && isToday(date_created)) {
      draft.detect = { enabled: false };
      draft.results = {
        enabled: true,
        clicked: false,
        date_completed,
        sampleClicked: false,
      };
      draft.inject = { enabled: false };
    } else if (status === 'FINISHED') {
      draft.detect = { enabled: true, started: false };
      draft.results = {
        enabled: true,
        clicked: false,
        date_completed,
        sampleClicked: false,
      };
      draft.inject = { enabled: false };
    } else {
      draft.detect = { enabled: true, started: false };
      draft.results = { enabled: false, sampleClicked: false };
      draft.inject = { enabled: true, started: false };
    }
  }

  if (action.type === scheduleComponentClicked.toString()) {
    const { clicked } = action.payload;
    draft.detect = { enabled: true, started: false };
    draft.inject = { enabled: false };
    draft.schedule = { clicked: clicked };

    if (draft.results.enabled) {
      draft.results.clicked = false;
    }

    draft.configuration.method['GET'] = true;
    draft.configuration.method['POST'] = true;
    draft.configuration.method['PUT'] = true;
    draft.configuration.method['DELETE'] = true;
    draft.configuration.method['PATCH'] = true;
    draft.configuration.method['UNKNOWN'] = true;

    draft.includedOperations = {
      all: false,
      list: {},
    };
  }

  if (action.type === detectInjectStartDetectClicked.toString()) {
    draft.detect = { enabled: true, started: true };
    draft.inject = { enabled: false };
    draft.schedule = { clicked: false };
    if (draft.results.enabled) {
      draft.results.clicked = false;
    }
  }

  if (action.type === detectInjectStartScanClicked.toString()) {
    draft.detect = { enabled: false };
    draft.inject = { enabled: false };
    draft.schedule = { clicked: false };
    if (draft.results.enabled) {
      draft.results.clicked = false;
    }
    draft.scanning = true;
  }

  if (action.type === detectInjectAtLeastOneOperationSelected.toString()) {
    const enabled = action.payload;
    draft.inject = enabled ? { enabled: true, started: false } : { enabled: false };
  }

  if (action.type === detectInjectViewResultsClicked.toString()) {
    draft.schedule = { clicked: false };
    if (draft.detect.enabled) {
      draft.detect.started = false;
    }
    if (draft.results.enabled) {
      draft.results.clicked = true;
    }
    draft.results.sampleClicked = false;
  }

  if (action.type === detectInjectViewSampleResultsClicked.toString()) {
    draft.schedule = { clicked: false };
    if (draft.detect.enabled) {
      draft.detect.started = false;
    }
    if (draft.results.enabled) {
      draft.results.clicked = false;
    }
    draft.results.sampleClicked = true;
  }

  if (action.type === detectInjectConfigToggleMethodClicked.toString()) {
    const methodType = action.payload;
    draft.configuration.method[methodType] = !draft.configuration.method[methodType];
    draft.includedOperations.all = false;
  }

  if (action.type === detectInjectExcludedOperectionIdsReceived.toString()) {
    const operationIds = action.payload;
    if (operationIds.length) {
      operationIds.map(id => {
        draft.excludedOperations.list[id] = true;
      });
    } else {
      draft.excludedOperations.list = {};
    }
  }

  if (action.type === detectInjectToggleOperectionClicked.toString()) {
    const operationId = action.payload;
    draft.includedOperations.list[operationId] = !draft.includedOperations.list[operationId];
  }

  if (action.type === detectInjectIncludeAllOperectionsClicked.toString()) {
    draft.includedOperations = {
      all: true,
      list: {},
    };
    draft.excludedOperations = {
      list: {},
    };
  }

  if (action.type === detectInjectExcludeAllOperectionsClicked.toString()) {
    draft.includedOperations = {
      all: false,
      list: {},
    };
    draft.excludedOperations = {
      list: {},
    };
  }
}, initialState);

function isToday(completed_date) {
  return completed_date && formatDate(completed_date, 'YYYY-DD-MMM') === formatDate(new Date(), 'YYYY-DD-MMM')
    ? true
    : false;
}
