// @flow
import immer from 'immer';
import { type ActionType } from 'redux-actions';
import { graphqlApisReceived } from './actions';
import type { GraphqlApi } from '@dt/horizon-api';

const initialState = {
  id: {},
  for_policy_violation_id: {},
};

export type GraphqlApiState = {|
  id: { [string]: GraphqlApi | void, ... },
  for_policy_violation_id: { [string]: string | void, ... },
|};

type Actions = ActionType<typeof graphqlApisReceived>;

export default immer<GraphqlApiState, Actions>((draft, action: Actions) => {
  if (action.type === graphqlApisReceived.toString()) {
    for (const graphql_api of action.payload.graphql_apis) {
      draft.id[graphql_api.id] = graphql_api;
      for (const policy_violation_id of graphql_api.policy_violation_ids) {
        draft.for_policy_violation_id[policy_violation_id] = graphql_api.id;
      }
    }
  }
}, initialState);
