// @flow
import { createAction } from 'redux-actions';

import { type CloudResourcesList } from '@dt/horizon-api';

export const cloudResourcesReceived = createAction<
  'cloud_resources/received',
  [CloudResourcesList],
  { cloud_resources: CloudResourcesList },
>('cloud_resources/received', cloud_resources => ({
  cloud_resources,
}));

export const cloudResourcesDetailsMounted = createAction<
  'mounted/cloud_resource_details',
  [string],
  {
    key: 'cloud_resource_details',
    cloudResourceId: string,
    ...
  },
>('mounted/cloud_resource_details', cloudResourceId => ({
  cloudResourceId,
  key: 'cloud_resource_details',
}));

export const cloudResourcesDetailsAuthenticatorsMounted = createAction<
  'mounted/cloud_resource_details/authenticators',
  [],
  {
    key: 'cloud_resource_details_authenticators',
  },
>('mounted/cloud_resource_details/authenticators', () => ({
  key: 'cloud_resource_details_authenticators',
}));

export const reachedEndOfCloudResourcesList = createAction<'cloud_resources/list/reached_end', [], void>(
  'cloud_resources/list/reached_end',
  () => undefined,
);

export const reachedEndOfCloudResourcesListForAssetSearchId = createAction<
  'cloud_resources/list/search/reached_end',
  [string],
  string,
>('cloud_resources/list/search/reached_end', search_id => search_id);

export const cloudResourcesReceivedIdsForAssetSearch = createAction<
  'cloud_resources/search/ids/received',
  [string, Array<string>],
  {|
    searchId: string,
    ids: Array<string>,
  |},
>('cloud_resources/search/ids/received', (searchId, ids) => ({
  searchId,
  ids,
}));
