// @flow
import { put, takeEvery } from 'redux-saga/effects';
import { select } from '@dt/redux-saga-wrapped-effects';
import { type Saga } from 'redux-saga';
import { Actions as NotificationsActions } from '@dt/notifications';
import { openscanMobileAppsRecieved, policyViolationsReceived, openscanConfigReceived } from './../actions';
import { config as watchlistConfig, watchlistApps } from './../selectors/openscan';
import { type OpenscanMobileApp, type OpenScanAlertsFilterConfig } from '@dt/user-api/openscan';

export function* watchForOpenscanMobileAppsReceived(): Saga<void> {
  yield takeEvery(openscanMobileAppsRecieved.toString(), function*(action: {
    payload: $ReadOnlyArray<OpenscanMobileApp>,
  }): Saga<void> {
    const apps = action.payload;

    try {
      const config = yield* select(watchlistConfig, {});
      yield put(policyViolationsReceived(config, apps));
    } catch (err) {
      yield put(
        NotificationsActions.requestNotifyUser({
          text: `Unable to update policy violations.`,
        }),
      );
    }
  });
}

export function* watchForOpenscanConfigReceived(): Saga<void> {
  yield takeEvery(openscanConfigReceived.toString(), function*(action: {
    payload: OpenScanAlertsFilterConfig,
  }): Saga<void> {
    const config = action.payload;

    try {
      const apps = yield* select(watchlistApps, {});
      yield put(policyViolationsReceived(config, apps));
    } catch (err) {
      yield put(
        NotificationsActions.requestNotifyUser({
          text: `Unable to update policy violations.`,
        }),
      );
    }
  });
}
