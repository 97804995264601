//@flow
const priority: {|
  P0: 'P0',
  P1: 'P1',
  P2: 'P2',
  P3: 'P3',
  P4: 'P4',
  NO_PRIORITY: 'NO_PRIORITY',
|} = {
  P0: 'P0',
  P1: 'P1',
  P2: 'P2',
  P3: 'P3',
  P4: 'P4',
  NO_PRIORITY: 'NO_PRIORITY',
};

export default priority;

export type FindingPriorityEnum = $Keys<typeof priority>;
