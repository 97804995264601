// @flow
import { type Saga } from 'redux-saga';
import { type ActionType } from 'redux-actions';
import { all, takeEvery, put, call } from 'redux-saga/effects';
import { select } from '@dt/redux-saga-wrapped-effects';
import { inventoryExportClicked, inventoryExportRequestRow, inventoryExportRestartRequestRow } from './actions';
import { inventory_export } from './selectors';
import restful_apis from '../../restful_apis/resourceType';
import network_services from '../../network_services/resourceType';
import cloud_resources from '../../cloud_resources/resourceType';
import web_applications from '../../web_applications/resourceType';
import {
  reachedEndOfNetworkServicesList,
  reachedEndOfNetworkServicesListForAssetSearchId,
} from '../../network_services/actions';
import { reachedEndOfRestfulApisList, reachedEndOfRestfulApisListForAssetSearchId } from '../../restful_apis/actions';
import {
  reachedEndOfCloudResourcesList,
  reachedEndOfCloudResourcesListForAssetSearchId,
} from '../../cloud_resources/actions';
import {
  reachedEndOfWebApplicationsList,
  reachedEndOfWebApplicationsListForAssetSearchId,
} from '../../web_applications/actions';
import { getCurrentSearchId } from '../search/selectors';
import { getNetworkServicesLazily } from '../../network_services/sagas';
import { getAllRestfulAPIsLazily } from '../../restful_apis/sagas';
import { getAllCloudResourcesLazily } from '../../cloud_resources/sagas';
import { getAllWebApplicationsLazily } from '../../web_applications/sagas';

export default function* watchForResourceFetching(): Saga<void> {
  yield all([
    takeEvery(inventoryExportClicked.toString(), inventoryExportStartSaga),
    takeEvery(inventoryExportRequestRow.toString(), inventoryExportRequestRowSaga),
    takeEvery(inventoryExportRestartRequestRow.toString(), inventExportRestartRequestSaga),
  ]);
}

function* inventoryExportStartSaga(): Saga<void> {
  const search_id = yield* select(state => getCurrentSearchId(state));

  if (search_id) {
    yield put(reachedEndOfCloudResourcesListForAssetSearchId(search_id));
    yield put(reachedEndOfNetworkServicesListForAssetSearchId(search_id));
    yield put(reachedEndOfRestfulApisListForAssetSearchId(search_id));
    yield put(reachedEndOfWebApplicationsListForAssetSearchId(search_id));
  } else {
    yield put(reachedEndOfCloudResourcesList());
    yield put(reachedEndOfNetworkServicesList());
    yield put(reachedEndOfRestfulApisList());
    yield put(reachedEndOfWebApplicationsList());
  }
}

function* inventoryExportRequestRowSaga(action: ActionType<typeof inventoryExportRequestRow>): Saga<void> {
  const { resource_type } = action.payload;
  const searchId = yield* select(state => getCurrentSearchId(state));
  const { inProgress: exportInProgress } = yield* select(state => inventory_export(state));

  if (exportInProgress) {
    switch (resource_type) {
      case restful_apis:
        {
          if (searchId) {
            yield put(reachedEndOfRestfulApisListForAssetSearchId(searchId));
          } else {
            yield put(reachedEndOfRestfulApisList());
          }
        }
        break;
      case network_services:
        {
          if (searchId) {
            yield put(reachedEndOfNetworkServicesListForAssetSearchId(searchId));
          } else {
            yield put(reachedEndOfNetworkServicesList());
          }
        }
        break;
      case cloud_resources:
        {
          if (searchId) {
            yield put(reachedEndOfCloudResourcesListForAssetSearchId(searchId));
          } else {
            yield put(reachedEndOfCloudResourcesList());
          }
        }
        break;
      case web_applications:
        {
          if (searchId) {
            yield put(reachedEndOfWebApplicationsListForAssetSearchId(searchId));
          } else {
            yield put(reachedEndOfWebApplicationsList());
          }
        }
        break;
      default:
        break;
    }
  }
}

function* inventExportRestartRequestSaga(action: ActionType<typeof inventoryExportRestartRequestRow>): Saga<void> {
  const { resource_type, cursor } = action.payload;
  const { inProgress: exportInProgress } = yield* select(state => inventory_export(state));
  const search_id = yield* select(state => getCurrentSearchId(state));

  const params = {};
  if (search_id) {
    params.search_id = search_id;
  }
  if (cursor) {
    params.cursor = cursor;
  }

  if (exportInProgress) {
    switch (resource_type) {
      case restful_apis:
        yield call(getAllRestfulAPIsLazily, params);
        break;
      case network_services:
        yield call(getNetworkServicesLazily, params);
        break;
      case cloud_resources:
        yield call(getAllCloudResourcesLazily, params);
        break;
      case web_applications:
        yield call(getAllWebApplicationsLazily, params);
        break;
      default:
        break;
    }
  }
}
