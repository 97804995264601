//@flow
import {
  type DefinitionNode,
  type FieldDefinitionNode,
  type ObjectTypeDefinitionNode,
  type InterfaceTypeDefinitionNode,
} from 'graphql';

import extractNamedTypeFromField from './extractNamedTypeFromField';
import type { LinkSchemaRestOptions } from './types';
import {
  makeDefaultResolver,
  makeResolverForRestFK,
  makeResolverForRestEndpoint,
  makeResolverForDate,
  makeResolverForJson,
} from './resolvers';

export default function buildResolver(
  definitions: $ReadOnlyArray<DefinitionNode>,
  options: LinkSchemaRestOptions,
) {
  validateRestEndpoints(definitions);
  return definitions.reduce((current, node) => {
    // Unions & Interfaces are currently not supported.
    // Adding support would require one of two options.
    //
    //   1) Determine which type is used at runtime by examining the JSON response from
    //      the server, ultimately guessing which union type to use for the response.
    //
    //        i.e.
    //            type UnionType = { propX: string } | { propY: number }
    //
    //            { "propX": "my_string" }
    //            { "propY": 4 }
    //
    //      Determining the type based on "propX" and "propY" to determine which type is required.
    //
    //   2) Have the server send back a standardized `__type` property that could be used to pick
    //      which type of a union type the data belongs to.
    //
    //        i.e.
    //            type UnionType = { propX: string } | { propY: number }
    //
    //            { "__type": "A", "name": "A", "propX": "my_string" }
    //            { "__type": "B", "name": "B", "propY": 4 }
    //
    //      This would allow us to determine which union type is required without introspection
    //      of specific data properties.
    if (node.kind !== 'ObjectTypeDefinition') {
      return current;
    }

    const resolvers = (current[node.name.value] = {});

    if (!node.fields) {
      return current;
    }

    for (const field of node.fields) {
      const { directives } = field;
      const endpointDirective = directives
        ? directives.find(directive => directive.name.value === 'restEndpoint')
        : null;
      const fkDirective = directives
        ? directives.find(directive => directive.name.value === 'restFK')
        : null;
      const type = extractNamedTypeFromField(field);
      if (fkDirective) {
        resolvers[field.name.value] = makeResolverForRestFK(
          field,
          fkDirective,
          options,
        );
      } else if (endpointDirective) {
        resolvers[field.name.value] = makeResolverForRestEndpoint(
          field,
          endpointDirective,
          options,
          getResponseNodeForEndpointField(field, definitions),
        );
      } else if (type === 'Date') {
        resolvers[field.name.value] = makeResolverForDate(field);
      } else if (type === 'JSON') {
        resolvers[field.name.value] = makeResolverForJson(field);
      } else {
        resolvers[field.name.value] = makeDefaultResolver(field);
      }
    }
    return current;
  }, {});
}

function getResponseNodeForEndpointField(
  field: FieldDefinitionNode,
  definitions: $ReadOnlyArray<DefinitionNode>,
): ObjectTypeDefinitionNode | InterfaceTypeDefinitionNode {
  /*
  TODO: @Sam
    This function will throw error for any non-object or non-interface types. This means
    that if you have a query ot mutation with scalar return type this will fail. For
    example:
      type Mutation {
        mobile_app_rescan_schedule_patch(id: ID!): Boolean
          @restEndpoint(path: ":sevenhell/v2/mobile_app_rescan_schedules/:id" method: "PUT")
      }

    This function along with `makeResolverForRestEndpoint` function need to be updated
    to take into account any scalar response as well.

    Good luck!
  */
  const type = extractNamedTypeFromField(field);
  const node = definitions.find(
    node =>
      (node.kind === 'ObjectTypeDefinition' ||
        node.kind === 'InterfaceTypeDefinition') &&
      node.name.value === type,
  );
  if (
    !node ||
    !(
      node.kind === 'ObjectTypeDefinition' ||
      node.kind === 'InterfaceTypeDefinition'
    )
  ) {
    throw new Error(
      `Invalid schema: A type was referenced in an endpoint value that didn't exist in the schema.`,
    );
  }

  return node;
}

let mutationMethods = new Set(['POST', 'PUT', 'DELETE', 'PATCH']);

function validateRestEndpoints(defs: $ReadOnlyArray<DefinitionNode>) {
  for (let def of defs) {
    if (def.kind === 'ObjectTypeDefinition') {
      if (def.name.value === 'Query') {
        for (let f of def.fields || []) {
          for (let dir of f.directives || []) {
            if (dir.name.value === 'restEndpoint') {
              for (let arg of dir.arguments || []) {
                if (arg.name.value === 'method') {
                  throw new Error(
                    '`method` is redundant when using @restEndpoint within Query definition',
                  );
                }
              }
            }
          }
        }
      } else if (def.name.value === 'Mutation') {
        for (let f of def.fields || []) {
          for (let dir of f.directives || []) {
            if (dir.name.value === 'restEndpoint') {
              if (dir.arguments) {
                let method = dir.arguments.find(a => a.name.value === 'method');
                if (!method) {
                  throw new Error(
                    '`method` is required when using @restEndpoint directive in a mutation',
                  );
                }
                if (
                  method.value.kind === 'StringValue' &&
                  !mutationMethods.has(method.value.value)
                ) {
                  throw new Error(
                    `Only ${[...mutationMethods].join(
                      ', ',
                    )} are allowed when using` +
                      `@restEndpoint directive in a mutation. Please check ${f.name.value}`,
                  );
                }
              }
            }
          }
        }
      }
    }
  }
}
