// @flow

export const StatusesTypeEnum: {
  LOADING: 'LOADING',
  DONE: 'DONE',
  ERROR: 'ERROR',
  ...
} = {
  LOADING: 'LOADING',
  DONE: 'DONE',
  ERROR: 'ERROR',
};

export type StateStatusesType = {|
  +initialLoaded: boolean,
  +isLoading: boolean,
  +error: ?string,
|};

export type StatusesType =
  | {| status: typeof StatusesTypeEnum.LOADING |}
  | {| status: typeof StatusesTypeEnum.DONE |}
  | {| status: typeof StatusesTypeEnum.ERROR, +error: string |};

export type KeyStatuses = {|
  +key: string,
  ...StatusesType,
|};

export type CacheManagerType = {|
  start?: boolean,
  done?: boolean,
  error?: boolean,
|};

export type KeyCache = {|
  ...CacheManagerType,
  key: string,
|};

export type GenericParamsType = { +[string]: string, ... };
