// @flow
import { createAction } from 'redux-actions';
import type { SpecialScanRequestList, PiiReportsInApiResponsesList } from '@dt/horizon-api';

export const specialScanRequestReceived = createAction<
  'special_scan_request/received',
  [SpecialScanRequestList],
  SpecialScanRequestList,
>('special_scan_request/received', list => list);

export const piiReportsInApiResponsesReceived = createAction<
  'special_scan_request/pii_reports_in_api_responses/received',
  [PiiReportsInApiResponsesList],
  { pii_reports_in_api_responses: PiiReportsInApiResponsesList },
>('special_scan_request/pii_reports_in_api_responses/received', pii_reports_in_api_responses => ({
  pii_reports_in_api_responses,
}));
