// @flow
import config from '@dt/config';
import fetch, { parse, type APIResult as FetchResponse } from '@dt/fetch';
import type {
  UsersList,
  PaginatedResponse,
  PiiReportsInApiResponsesList,
  PiiTypeCustomizationConfig,
} from './types';

const api = config.horizonApiBaseUrl;
const version = 'v1';

export const SpecialScanRequestTypeEnum = {
  HACK_AND_EXTRACT: 'HACK_AND_EXTRACT',
  LEAKY_APIS: 'LEAKY_APIS',
  DETECT_AND_INJECT: 'DETECT_AND_INJECT',
  SPA_DETECT_AND_INJECT: 'SPA_DETECT_AND_INJECT',
  XSS_PROTECTION: 'XSS_PROTECTION',
};

export type SpecialScanRequestType = $Keys<typeof SpecialScanRequestTypeEnum>;

export const SpecialScanRequestStatusEnum = {
  NOT_STARTED: 'NOT_STARTED',
  ONGOING: 'ONGOING',
  FINISHED: 'FINISHED',
};

export type SpecialScanRequestStatus = $Keys<
  typeof SpecialScanRequestStatusEnum,
>;

export type SpecialScanRequest = {
  id: string,
  date_created: string,
  date_completed: ?string,
  created_by_user_id: string,
  current_status: SpecialScanRequestStatus,
  scan_type: SpecialScanRequestType,
  scan_configuration: {
    excluded_api_operation_ids?: $ReadOnlyArray<string>,
    selected_web_application_ids?: $ReadOnlyArray<string>,
    ...
  },
};

export type SpecialScanRequestList = $ReadOnlyArray<SpecialScanRequest>;

export type SpecialScanRequestListResponse = {|
  special_scan_requests: SpecialScanRequestList,
  users: UsersList,
|};

export async function get(
  type: SpecialScanRequestType,
): FetchResponse<SpecialScanRequestListResponse> {
  return fetch(`${api}/${version}/special_scan_requests/${type}`).then(
    response => {
      if (response.status === 404) {
        // This endpoint is special as when this type hasn't been created yet,
        // it returns 404
        return {
          _type: 'response',
          status: response.status,
          ok: true,
          body: {
            special_scan_requests: [],
            users: [],
          },
        };
      }

      return parse(response);
    },
  );
}

export async function list(
  type: SpecialScanRequestType,
): FetchResponse<SpecialScanRequestListResponse> {
  return fetch(
    `${api}/${version}/special_scan_requests?scan_type=${type}`,
  ).then(response => {
    if (response.status === 404) {
      // This endpoint is special as when this type hasn't been created yet,
      // it returns 404
      return {
        _type: 'response',
        status: response.status,
        ok: true,
        body: {
          special_scan_requests: [],
          users: [],
        },
      };
    }

    return parse(response);
  });
}

type LeakyApisScanRequest = {|
  scan_type: typeof SpecialScanRequestTypeEnum.LEAKY_APIS,
|};

type HackExtractScanRequest = {|
  scan_type: typeof SpecialScanRequestTypeEnum.HACK_AND_EXTRACT,
  ...PiiTypeCustomizationConfig,
|};

export type DetectAndInjectScanRequest = {|
  scan_type: typeof SpecialScanRequestTypeEnum.DETECT_AND_INJECT,
  scan_configuration: {|
    excluded_api_operation_ids: $ReadOnlyArray<string>,
  |},
|};

export type SpaDetectAndInjectScanRequest = {|
  scan_type: typeof SpecialScanRequestTypeEnum.SPA_DETECT_AND_INJECT,
  scan_configuration: {|
    selected_all_web_applications: boolean,
    selected_web_application_ids: $ReadOnlyArray<string>,
  |},
|};

type SpecialScanRequestCreate =
  | LeakyApisScanRequest
  | HackExtractScanRequest
  | DetectAndInjectScanRequest
  | SpaDetectAndInjectScanRequest;

export async function create(
  params: SpecialScanRequestCreate,
): FetchResponse<SpecialScanRequestListResponse> {
  return fetch(`${api}/${version}/special_scan_requests`, {
    method: 'POST',
    body: JSON.stringify(params),
  }).then(parse);
}

export type PiiReportsInApiResponsesListResponse = {|
  pii_reports_in_api_responses: PiiReportsInApiResponsesList,
|};

export async function list_pii_reports_in_api_responses(): FetchResponse<
  PaginatedResponse<PiiReportsInApiResponsesListResponse>,
> {
  return fetch(
    `${api}/${version}/special_scan_requests/hack_and_extract/pii_reports_in_responses`,
  ).then(parse);
}
