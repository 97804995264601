// @flow
import config from '@dt/config';
import fetch, { parse, type APIResult as FetchResponse } from '@dt/fetch';

import { type UsersList, type Authenticator } from './types';

const api = config.horizonApiBaseUrl;
const version = 'v1';

export type ApigeeAuthenticator = {|
  ...Authenticator,
  +organization: string,
|};

export type ApigeeAuthenticatorsList = $ReadOnlyArray<ApigeeAuthenticator>;

export type ApigeeAuthenticatorsListResponse = {|
  apigee_authenticators: ApigeeAuthenticatorsList,
  users: UsersList,
|};

export type ApigeeAuthenticatorsCreateRequestParams = {
  organization: string,
  ...
};

export async function list(): FetchResponse<ApigeeAuthenticatorsListResponse> {
  return fetch(`${api}/${version}/apigee_authenticators`).then(parse);
}

export type ApigeeAuthenticatorsCreateResponse = ApigeeAuthenticator;

export async function create(
  params: ApigeeAuthenticatorsCreateRequestParams,
): FetchResponse<ApigeeAuthenticatorsCreateResponse> {
  return fetch(`${api}/${version}/apigee_authenticators`, {
    method: 'POST',
    body: JSON.stringify(params),
  }).then(parse);
}
