// @flow
import { createReducerCreator, updateInList } from 'rezz';
import type { EventsList, Event } from '@dt/horizon-api';

import { eventsReceived } from './actions';

export default createReducerCreator<EventsList, Event, _>({
  initialState: [],
  equalityCheck: (a, b) => a.id === b.id,
})(updateInList([eventsReceived.toString()]));
