// @flow
import { createAction } from 'redux-actions';

import type {
  ExternalSearchResult,
  OpenscanMobileApp,
  SearchResult,
  OpenScanAlertsFilterConfig,
} from '@dt/user-api/openscan';

export const setTemporaryToken = createAction<'SET_TEMPORARY_TOKEN', [string], string>(
  'SET_TEMPORARY_TOKEN',
  string => string,
);

export const setTemporaryTokenSuccess = createAction<'SET_TEMPORARY_TOKEN/SUCCESS'>('SET_TEMPORARY_TOKEN/SUCCESS');

export const updateApp = createAction<'APPS_UPDATE', [OpenscanMobileApp], OpenscanMobileApp>('APPS_UPDATE', app => app);

export const requestApp = createAction<'REQUEST_APP', [string], { id: string, ... }>('REQUEST_APP', id => ({
  id,
}));
export const receivedApp = createAction<'RECEIVED_APP', [string], { id: string, ... }>('RECEIVED_APP', id => ({
  id,
}));

export const requestFindingsForApp = createAction<'REQUEST_FINDINGS_FOR_APP', [string], { id: string, ... }>(
  'REQUEST_FINDINGS_FOR_APP',
  id => ({
    id,
  }),
);

export const receivedFindingsForApp = createAction<'RECEIVED_FINDINGS_FOR_APP', [string], { id: string, ... }>(
  'RECEIVED_FINDINGS_FOR_APP',
  id => ({
    id,
  }),
);

// region SearchResults
export const requestSearchResult = createAction<
  'REQUEST_SEARCH_RESULT',
  [string, string],
  {
    search: string,
    id: string,
    ...
  },
>('REQUEST_SEARCH_RESULT', (search, id) => ({
  search,
  id,
}));

export const receivedSearchResult = createAction<
  'RECEIVED_SEARCH_RESULT',
  [string, string],
  {
    search: string,
    id: string,
    ...
  },
>('RECEIVED_SEARCH_RESULT', (search, id) => ({
  search,
  id,
}));

export const requestSearchResults = createAction<'REQUEST_SEARCH_RESULTS', [string], { name: string, ... }>(
  'REQUEST_SEARCH_RESULTS',
  name => ({
    name,
  }),
);

export const receivedSearchResults = createAction<
  'RECEIVED_SEARCH_RESULTS',
  [string, $ReadOnlyArray<SearchResult>],
  {
    search: string,
    search_results: $ReadOnlyArray<SearchResult>,
    ...
  },
>('RECEIVED_SEARCH_RESULTS', (search, search_results) => ({
  search,
  search_results,
}));

export const receivedRequestedSearchResults = createAction<
  'RECEIVED_REQUESTED_SEARCH_RESULTS',
  [string],
  { search: string, ... },
>('RECEIVED_REQUESTED_SEARCH_RESULTS', search => ({
  search,
}));

export const cancelledSearchResults = createAction<'CANCELLED_REQUEST_SEARCH_RESULTS', [], { ... }>(
  'CANCELLED_REQUEST_SEARCH_RESULTS',
  () => ({}),
);
// endregion SearchResults

export const contactPublisherRequest = createAction<
  'CREATE_CONTACT_PUBLISHER_REQUEST',
  [string, $ReadOnlyArray<string>],
  { id: string, additional_recipients: $ReadOnlyArray<string>, ... },
>('CREATE_CONTACT_PUBLISHER_REQUEST', (id: string, additional_recipients: $ReadOnlyArray<string>) => ({
  id,
  additional_recipients,
}));

export const contactPublisherRequestLoading = createAction<
  'CONTACT_PUBLISHER_REQUEST_LOADING',
  [$ReadOnlyArray<OpenscanMobileApp>],
  $ReadOnlyArray<OpenscanMobileApp>,
>('CONTACT_PUBLISHER_REQUEST_LOADING', updatedApps => updatedApps);

export const contactPublisherRequestReceived = createAction<
  'CONTACT_PUBLISHER_REQUEST_RECEIVED',
  [$ReadOnlyArray<OpenscanMobileApp>],
  $ReadOnlyArray<OpenscanMobileApp>,
>('CONTACT_PUBLISHER_REQUEST_RECEIVED', updatedApps => updatedApps);

export const contactPublisherRequestError = createAction<
  'CONTACT_PUBLISHER_REQUEST_ERROR',
  [$ReadOnlyArray<OpenscanMobileApp>],
  $ReadOnlyArray<OpenscanMobileApp>,
>('CONTACT_PUBLISHER_REQUEST_ERROR', updatedApps => updatedApps);

export const removeAppFromOpenscanWatchList = createAction<
  'REMOVE_APP_FROM_OPENSCAN_WATCHLIST',
  [string],
  { id: string, ... },
>('REMOVE_APP_FROM_OPENSCAN_WATCHLIST', (id: string) => ({ id }));

export const removeAppFromOpenscanWatchListGroup = createAction<
  'REMOVE_APP_FROM_OPENSCAN_WATCHLIST_GROUP',
  [string, string],
  {
    id: string,
    group: string,
    ...
  },
>('REMOVE_APP_FROM_OPENSCAN_WATCHLIST_GROUP', (id, group) => ({
  id,
  group,
}));

export const openscanWatchlistLoading = createAction<'STARTED_LOADING_WATCHLIST_APPS'>(
  'STARTED_LOADING_WATCHLIST_APPS',
);

export const openscanMobileAppsRecieved = createAction<
  'OPENSCAN_MOBILE_APPS_RECEIVED',
  [$ReadOnlyArray<OpenscanMobileApp>],
  $ReadOnlyArray<OpenscanMobileApp>,
>('OPENSCAN_MOBILE_APPS_RECEIVED', apps => apps);

export const openscanConfigReceived = createAction<
  'OPENSCAN_CONFIG_RECEIVED',
  [OpenScanAlertsFilterConfig],
  OpenScanAlertsFilterConfig,
>('OPENSCAN_CONFIG_RECEIVED', config => config);

export const openscanRuleUpdated = createAction<
  'OPENSCAN_CONFIG_UPDATED',
  [OpenScanAlertsFilterConfig],
  { config: OpenScanAlertsFilterConfig, ... },
>('OPENSCAN_CONFIG_UPDATED', config => ({ config }));

export const addAppToOpenscanWatchListGroup = createAction<
  'ADD_APP_TO_OPENSCAN_WATCHLIST_GROUP',
  [string, string],
  {
    id: string,
    group: string,
    ...
  },
>('ADD_APP_TO_OPENSCAN_WATCHLIST_GROUP', (id, group) => ({
  id,
  group,
}));

export const openScanSearchNameSelected = createAction<'OPEN_SCAN_APP_SELECTED', [string], { name: string, ... }>(
  'OPEN_SCAN_APP_SELECTED',
  name => ({ name }),
);

export const openscanErrorOccurred = createAction<'OPENSCAN/ERROR_OCCURRED', [string], string>(
  'OPENSCAN/ERROR_OCCURRED',
  error => error,
);

export const openscanReceivedExternalSearchResults = createAction<
  'OPENSCAN/RECIEVED_EXTERNAL_SEARCH_RESULTS',
  [string, $ReadOnlyArray<ExternalSearchResult>],
  {
    query: string,
    results: $ReadOnlyArray<ExternalSearchResult>,
    ...
  },
>('OPENSCAN/RECIEVED_EXTERNAL_SEARCH_RESULTS', (query: string, results: $ReadOnlyArray<ExternalSearchResult>) => ({
  query,
  results,
}));

export const disabledWatchListInquire = createAction<
  'OPENSCAN/DISABLED_WATCHLIST_INQUIRE',
  [OpenscanMobileApp],
  { app: OpenscanMobileApp, ... },
>('OPENSCAN/DISABLED_WATCHLIST_INQUIRE', (app: OpenscanMobileApp) => ({ app }));

export const policyViolationsReceived = createAction<
  'OPENSCAN/RECEIVED_POLICY_VIOLATIONS',
  [?OpenScanAlertsFilterConfig, ?$ReadOnlyArray<OpenscanMobileApp>],
  {
    apps: ?$ReadOnlyArray<OpenscanMobileApp>,
    config: ?OpenScanAlertsFilterConfig,
  },
>(
  'OPENSCAN/RECEIVED_POLICY_VIOLATIONS',
  (config: ?OpenScanAlertsFilterConfig, apps: ?$ReadOnlyArray<OpenscanMobileApp>) => ({ config, apps }),
);
