// @flow
import { type NetworkService } from '@dt/horizon-api';
import immer from 'immer';
import type { ActionType } from 'redux-actions';

import { networkServicesReceived, networkServicesReceivedIdsForAssetSearch } from './actions';

type Actions = ActionType<typeof networkServicesReceived> | ActionType<typeof networkServicesReceivedIdsForAssetSearch>;

export type NetworkServiceState = {|
  id: { [string]: void | NetworkService, ... },
  for_policy_violation_id: { [string]: void | string, ... },
  for_domain_name: { [string]: void | Array<string>, ... },
  for_hosted_on: { [string]: void | Array<string>, ... },
  for_search_id: { [string]: Array<string>, ... },
|};

const initialState = {
  id: {},
  for_policy_violation_id: {},
  for_domain_name: {},
  for_hosted_on: {},
  for_search_id: {},
};

export default immer<NetworkServiceState, Actions>((draft, action: Actions): void | NetworkServiceState => {
  switch (action.type) {
    case networkServicesReceived.toString():
      for (const service of action.payload.network_services) {
        addService(draft, service);
      }
      pruneDuplicates(draft);
      return;
    case networkServicesReceivedIdsForAssetSearch.toString(): {
      const { searchId, ids } = action.payload;
      draft.for_search_id[searchId] = (draft.for_search_id[searchId] || []).concat(ids);
      return;
    }
  }
}, initialState);

// If you add stuff to this function, make sure to remove it in the
// `removeService` function below it.
function addService(draft: NetworkServiceState, service: NetworkService) {
  const { hosted_on, id, domain_name_id, policy_violation_ids } = service;
  draft.id[id] = service;
  const for_domain = draft.for_domain_name[domain_name_id] || [];
  for_domain.push(id);
  for (const violation_id of policy_violation_ids) {
    draft.for_policy_violation_id[violation_id] = id;
  }
  const for_hosted = (draft.for_hosted_on[hosted_on] = draft.for_hosted_on[hosted_on] || []);
  if (!for_hosted.includes(id)) {
    for_hosted.push(id);
  }
}

function removeService(draft: NetworkServiceState, service: NetworkService) {
  const { id, domain_name_id, hosted_on, policy_violation_ids } = service;
  draft.for_domain_name[domain_name_id] = (draft.for_domain_name[domain_name_id] || []).filter(sid => sid !== id);
  for (const violation_id of policy_violation_ids) {
    delete draft.for_policy_violation_id[violation_id];
  }
  draft.for_hosted_on[hosted_on] = (draft.for_domain_name[hosted_on] || []).filter(sid => sid !== id);
  delete draft.id[service.id];
}

// prune network services that are not `is_tls_encrypted` and share a
// domain name with another network service that `is_tls_encrypted`
function pruneDuplicates(draft: NetworkServiceState) {
  for (const domain_name_id in draft.for_domain_name) {
    const serviceIds = draft.for_domain_name[domain_name_id];
    if (!serviceIds || serviceIds.length < 2) continue;

    let secure_version_exists = false,
      services_to_prune = [];
    for (const serviceId of serviceIds) {
      const service = draft.id[serviceId];
      if (service && service.is_tls_encrypted) {
        secure_version_exists = true;
      } else if (service) {
        services_to_prune.push(service);
      }
    }

    if (secure_version_exists && services_to_prune.length) {
      for (const service of services_to_prune) {
        removeService(draft, service);
      }
    }
  }
}
