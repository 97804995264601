//@flow
const integrationBadgeType = {
  CI_CD: 'CI_CD',
  TRUSTKIT: 'TRUSTKIT',
  ISSUE_TRACKER: 'ISSUE_TRACKER',
};

export default integrationBadgeType;

export type IntegrationBadgeTypeEnum = $Keys<typeof integrationBadgeType>;
