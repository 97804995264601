// @flow
import { type Saga } from 'redux-saga';
import { call } from 'redux-saga/effects';
import { paginateToEnd } from '../resource_fetch/sagas';
import { getOpenAPIDefinitions } from './resource_fetch.sagas';
import resourceType from './resourceType';

export function* getAllOpenAPIDefinitions(): Saga<void> {
  yield call(paginateToEnd, getOpenAPIDefinitions, resourceType, {});
}
