// @flow

import {
  hackExtractForbidden,
  hackExtractHideResultsClicked,
  hackExtractMounted,
  hackExtractShowResultsClicked,
  hackExtractStartClicked,
  hackExtractUnmounted,
} from './actions';

import { type ActionType } from 'redux-actions';
import immer from 'immer';

export type HackExtractState = {
  showingResults: boolean,
  isForbidden: boolean,
  isViewingHackAndExtract: boolean,
};

type Actions =
  | ActionType<typeof hackExtractMounted>
  | ActionType<typeof hackExtractUnmounted>
  | ActionType<typeof hackExtractStartClicked>
  | ActionType<typeof hackExtractShowResultsClicked>
  | ActionType<typeof hackExtractHideResultsClicked>
  | ActionType<typeof hackExtractForbidden>;

const initialState = {
  isViewingHackAndExtract: false,
  showingResults: false,
  isForbidden: false,
};

export default immer<HackExtractState, Actions>((draft, action: Actions) => {
  if (action.type === hackExtractShowResultsClicked.toString()) {
    draft.showingResults = true;
  }

  if (action.type === hackExtractStartClicked.toString()) {
    draft.showingResults = false;
    draft.isForbidden = false;
  }

  if (action.type === hackExtractHideResultsClicked.toString()) {
    draft.showingResults = false;
  }

  if (action.type === hackExtractMounted.toString()) {
    draft.isViewingHackAndExtract = true;
  }

  if (action.type === hackExtractUnmounted.toString()) {
    draft.isViewingHackAndExtract = false;
  }

  if (action.type === hackExtractForbidden.toString()) {
    draft.isForbidden = true;
  }
}, initialState);
