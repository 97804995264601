// @flow
import { createSelector } from 'reselect';

import { type State } from '../store_state_type';

const cacheMap = state => state.resource_fetch.cache;
const getKey = (state, props = {}) => props.key;

export const getCacheStatusFromKeySelector = createSelector<
  State,
  { key: string, ... },
  {
    started: null | Date,
    ended: null | Date,
    ...
  },
  _,
  _,
  _,
>(
  getKey,
  cacheMap,

  (key, cacheMap) => {
    let started = null;
    let ended = null;

    const cache = cacheMap.get(key);

    if (cache) {
      started = cache.startedAt;
      ended = cache.endedAt;
    }

    return {
      started,
      ended,
    };
  },
);
