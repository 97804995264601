// @flow
import config from '@dt/config';
import qs from 'query-string';
import fetch, { parse, type APIResult as FetchResponse } from '@dt/fetch';

import { type AwsAuthenticatorsList } from './aws_authenticators';
import { type MulesoftAuthenticatorsList } from './mulesoft_authenticators';
import { type GcpAuthenticatorsList } from './gcp_authenticators';
import { type ApigeeAuthenticatorsList } from './apigee_authenticators';

import {
  type CommentsList,
  type PolicyViolationList,
  type PolicyRuleList,
  type NetworkServicesList,
  type DomainNameList,
  type RestfulAPIsList,
  type CloudResourcesList,
  type EventsList,
  type UsersList,
  type PolicyRuleTypeList,
  type PaginatedResponse,
} from './types';

const api = config.horizonApiBaseUrl;
const version = 'v1';

export type EventsListResponse = {|
  events: EventsList,

  aws_authenticators: AwsAuthenticatorsList,
  gcp_authenticators: GcpAuthenticatorsList,
  mulesoft_authenticators: MulesoftAuthenticatorsList,
  apigee_authenticators: ApigeeAuthenticatorsList,
  users: UsersList,

  network_services: NetworkServicesList,
  domain_names: DomainNameList,
  restful_apis: RestfulAPIsList,
  cloud_resources: CloudResourcesList,

  policy_violations: PolicyViolationList,
  policy_rules: PolicyRuleList,
  policy_rule_types: PolicyRuleTypeList,
  comments: CommentsList,
|};

export type EventsListParams = {|
  +start_date?: string, // ISO format @default: 30 days from today
  +end_date?: string, // ISO format @default: today
  +cursor?: string,
|};

export async function list(
  params?: EventsListParams,
): FetchResponse<PaginatedResponse<EventsListResponse>> {
  return fetch(`${api}/${version}/events?${qs.stringify(params || {})}`).then(
    parse,
  );
}
