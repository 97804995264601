// @flow
import { createAction } from 'redux-actions';
import { type PolicyList } from '@dt/horizon-api';

export const policiesReceived = createAction<'policies/received', [PolicyList], PolicyList>(
  'policies/received',
  params => params,
);

export const policiesMounted = createAction<'policies/mounted', [], { key: 'policies', ... }>(
  'policies/mounted',
  () => ({ key: 'policies' }),
);

export const policiesDetailsMounted = createAction<
  'policies/details/mounted',
  [string],
  {
    key: 'policies',
    policy_id: string,
    ...
  },
>('policies/details/mounted', policy_id => ({ key: 'policies', policy_id }));

export const policiesCreate = createAction<
  'policies/create',
  [{| name: string, description: string |}],
  {| name: string, description: string |},
>('policies/create', params => params);

export const policiesCreateSuccess = createAction<'policies/create/success', [string], string>(
  'policies/create/success',
  params => params,
);

export const policiesCreateError = createAction<'policies/create/error', [string], string>(
  'policies/create/error',
  params => params,
);

export const policiesCreateDone = createAction<'policies/create/done'>('policies/create/done');

export const policiesDeleteButtonClicked = createAction<'policies/delete/clicked', [string], string>(
  'policies/delete/clicked',
  policy_id => policy_id,
);
