//@flow
import type { ActionType } from 'redux-actions';
import {
  paginationLoadedInitialAction,
  paginationBeginRequest,
  paginationEndRequest,
  paginationRequestFailed,
} from './actions';

import { type PaginationState } from './types';

const actions = [
  paginationLoadedInitialAction.toString(),
  paginationBeginRequest.toString(),
  paginationEndRequest.toString(),
  paginationRequestFailed.toString(),
];

export default function reducer(
  state: PaginationState = { requests: {} },
  action:
    | ActionType<typeof paginationLoadedInitialAction>
    | ActionType<typeof paginationBeginRequest>
    | ActionType<typeof paginationEndRequest>
    | ActionType<typeof paginationRequestFailed>,
): PaginationState {
  if (
    actions.includes(action.type) &&
    action.payload &&
    action.payload.type &&
    action.payload.key
  ) {
    const currentRequestInfo = state.requests[action.payload.type];

    const newBody = {};

    let body =
      currentRequestInfo && currentRequestInfo[action.payload.key]
        ? currentRequestInfo[action.payload.key]
        : {};

    if (action.type === paginationLoadedInitialAction.toString()) {
      newBody.loadedInitial = true;
    }

    if (action.type === paginationBeginRequest.toString()) {
      newBody.loading = true;
      newBody.error = false;
    }

    if (action.type === paginationEndRequest.toString()) {
      newBody.loading = false;
      newBody.next_cursor = action.payload.next_cursor;
      newBody.total_count = action.payload.total_count;
      newBody.complete = !action.payload.next_cursor;
      newBody.type = action.payload.type;
      newBody.error = false;
    }

    if (action.type === paginationRequestFailed.toString()) {
      newBody.loading = false;
      newBody.error = true;
    }

    const newState = {
      ...state,
      ...{
        requests: {
          ...state.requests,
          [action.payload.type]: {
            ...currentRequestInfo,
            [action.payload.key]: { ...body, ...newBody },
          },
        },
      },
    };

    return newState;
  }
  return state;
}
