//@flow
import config from '@dt/config';
import fetch, { parse } from '@dt/fetch';
import { byProperty, result } from './util';

export type upload_url = string;

export type CreateFileParams = {
  filename: string,
  purpose: string,
  size: number,
  origin: string,
  ...
};

export const finance = {
  create: async (params: CreateFileParams): Promise<upload_url> => {
    if (
      !params ||
      !params.filename ||
      !params.purpose ||
      !params.size ||
      !params.origin
    ) {
      throw new Error('Invalid params supplied to create upload request');
    }

    return fetch(`${config.sevenhellApiBaseUrl}/v2/uploads/finance`, {
      method: 'POST',
      body: JSON.stringify(params),
    })
      .then(parse)
      .then(result)
      .then(byProperty('upload_url'));
  },
};
