// @flow
import { type PolicyRule } from '@dt/horizon-api';
import type { PolicyRuleTypeState } from '../policy_rule_types/reducer';
import { type PolicyRuleDecorated } from './types';

export const decorate = (policy_rule: PolicyRule, policy_rule_types: PolicyRuleTypeState): ?PolicyRuleDecorated => {
  const policy_rule_type = policy_rule_types.id[policy_rule.policy_rule_type_id];

  if (!policy_rule_type) return null;

  return {
    ...policy_rule,
    policy_rule_type: policy_rule_type,
  };
};
