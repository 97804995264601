// @flow
import { createAction } from 'redux-actions';
import { type PolicyViolationList } from '@dt/horizon-api';

export const policyViolationsReceived = createAction<
  'policy_violations/received',
  [PolicyViolationList],
  PolicyViolationList,
>('policy_violations/received', tasks => tasks);

export const policyViolationDetailsMounted = createAction<
  'mounted/policy_violation/details',
  [string],
  {
    policyViolationId: string,
    key: 'policy_violation_details',
    ...
  },
>('mounted/policy_violation/details', policyViolationId => ({
  policyViolationId,
  key: 'policy_violation_details',
}));

export const policyViolationDashboardMounted = createAction<
  'mounted/policy_violations/dashboard',
  [],
  { key: 'policy_violation_dashboard', ... },
>('mounted/policy_violations/dashboard', () => ({
  key: 'policy_violation_dashboard',
}));
