// @flow
import { type Saga } from 'redux-saga';
import { call, putResolve } from 'redux-saga/effects';
import { special_scan_requests, type SpecialScanRequestType } from '@dt/horizon-api';
import { paginationBeginRequest, paginationEndRequest } from '@dt/pagination';
import { callPromise } from '@dt/redux-saga-wrapped-effects';

import { withCache, handleNormalizedResponse } from './../resource_fetch/sagas';

export const getSpecialScanRequestTypeList = withCache<{
  type: SpecialScanRequestType,
}>(
  params => `special_scan_request_${params.type}`,
  function* getSpecialScanRequest(params): Saga<void> {
    const response = yield* callPromise(special_scan_requests.list, params.type);

    if (response._type === 'error') {
      throw new Error(response.title);
    }

    yield call(handleNormalizedResponse, response.body);
  },
);

export const getAllPiiReportsInApiResponses = withCache<{ ... }>(
  'pii_reports_in_api_responses',
  function* getPiiReportsInResponses(params): Saga<void> {
    yield putResolve(paginationBeginRequest('piiReportsInApiResponses', params));
    const response = yield* callPromise(special_scan_requests.list_pii_reports_in_api_responses, params);
    if (response._type === 'error') {
      throw new Error(response.title);
    }
    yield call(handleNormalizedResponse, response.body);
    if (response.body.pagination_information) {
      yield putResolve(paginationEndRequest('piiReportsInApiResponses', params, response.body.pagination_information));
    }
  },
);
