// @flow
import { createAction } from 'redux-actions';

import {
  type AwsAuthenticatorsList,
  type GcpAuthenticatorsList,
  type CertificateTransparencyDomains,
  type AzureAuthenticatorsList,
  type AxwayAuthenticatorsList,
  type MulesoftAuthenticatorsList,
  type KongAuthenticatorsList,
  type ApigeeAuthenticatorsList,
} from '@dt/horizon-api';

export const authenticatorSectionMounted = createAction<
  'mounted/configuration/authenticator_section',
  [],
  { key: 'authenticator_section' },
>('mounted/configuration/authenticator_section', () => ({
  key: 'authenticator_section',
}));

export const securityRoadmapMounted = createAction<'mounted/security-roadmap/', [], { key: 'security-roadmap' }>(
  'mounted/security-roadmap/',
  () => ({
    key: 'security-roadmap',
  }),
);

export const cloudAccessMounted = createAction<'mounted/configuration/', [], { key: 'configuration', ... }>(
  'mounted/configuration/',
  () => ({
    key: 'configuration',
  }),
);

export const awsAuthenticatorsReceived = createAction<
  'aws_authenticators/list/received',
  [AwsAuthenticatorsList],
  AwsAuthenticatorsList,
>('aws_authenticators/list/received', (authenticators: AwsAuthenticatorsList) => authenticators);

export const gcpAuthenticatorsReceived = createAction<
  'gcp_authenticators/list/received',
  [GcpAuthenticatorsList],
  GcpAuthenticatorsList,
>('gcp_authenticators/list/received', (authenticators: GcpAuthenticatorsList) => authenticators);

export const azureAuthenticatorsReceived = createAction<
  'azure_authenticators/list/received',
  [AzureAuthenticatorsList],
  AzureAuthenticatorsList,
>('azure_authenticators/list/received', (authenticators: AzureAuthenticatorsList) => authenticators);

export const axwayAuthenticatorsReceived = createAction<
  'axway_authenticators/list/received',
  [AxwayAuthenticatorsList],
  AxwayAuthenticatorsList,
>('axway_authenticators/list/received', (authenticators: AxwayAuthenticatorsList) => authenticators);

export const mulesoftAuthenticatorsReceived = createAction<
  'mulesoft_authenticators/list/received',
  [MulesoftAuthenticatorsList],
  MulesoftAuthenticatorsList,
>('mulesoft_authenticators/list/received', (authenticators: MulesoftAuthenticatorsList) => authenticators);

export const apigeeAuthenticatorsReceived = createAction<
  'apigee_authenticators/list/received',
  [ApigeeAuthenticatorsList],
  ApigeeAuthenticatorsList,
>('apigee_authenticators/list/received', (authenticators: ApigeeAuthenticatorsList) => authenticators);

export const certificateTransparencyReceived = createAction<
  'certificate_transparency/get/received',
  [CertificateTransparencyDomains],
  CertificateTransparencyDomains,
>(
  'certificate_transparency/get/received',
  (certificateTransparency: CertificateTransparencyDomains) => certificateTransparency,
);

export const kongAuthenticatorsReceived = createAction<
  'kong_authenticators/list/received',
  [KongAuthenticatorsList],
  KongAuthenticatorsList,
>('kong_authenticators/list/received', (authenticators: KongAuthenticatorsList) => authenticators);

export const onboardingCloudAccessMounted = createAction<'mounted/onboarding/', [], { key: 'onboarding', ... }>(
  'mounted/onboarding/',
  () => ({
    key: 'onboarding',
  }),
);
