//@flow
import type { Params, State, PaginationInfo } from './types';
import getKey from './getKey';

export function createSelectorForPaginationInfo(type: string, params: Params) {
  const key = getKey(params);
  return (state: State): PaginationInfo =>
    (state.pagination.requests[type] &&
      state.pagination.requests[type][key]) || {
      loadedInitial: false,
      loading: false,
    };
}
