// @flow
import { createAction } from 'redux-actions';
import { type OpenAPIDefinitionsList } from '@dt/horizon-api';

export const openAPIDefinitionsListMounted = createAction<
  'openapi-definition/list',
  [],
  { key: 'openapi_definitions', ... },
>('openapi-definition/list', () => ({
  key: 'openapi_definitions',
}));

export const openAPIDefinitionsReceived = createAction<
  'openapi-definition/received',
  [OpenAPIDefinitionsList],
  OpenAPIDefinitionsList,
>('openapi-definition/received', definitions => definitions);

export const openAPIDefinitionUploadReset = createAction<'openapi-definition/reset', [], void>(
  'openapi-definition/reset',
);

export const openAPIDefinitionUpload = createAction<
  'openapi-definition/upload',
  [{| file: File, apiURL: string, CURLCommand: string, comments: string |}],
  {| file: File, apiURL: string, CURLCommand: string, comments: string |},
>('openapi-definition/upload', params => params);

export const openAPIDefinitionUploadSuccess = createAction<'openapi-definition/upload/success', [], void>(
  'openapi-definition/upload/success',
  () => {},
);

export const openAPIDefinitionUploadError = createAction<'openapi-definition/upload/error', [string], string>(
  'openapi-definition/upload/error',
  error => error,
);
