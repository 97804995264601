// @flow
import config from '@dt/config';
import fetch, { parse, type APIResult as FetchResponse } from '@dt/fetch';
import { type UsersList, type Authenticator } from './types';

const api = config.horizonApiBaseUrl;
const version = 'v1';

export type MulesoftAuthenticator = {|
  ...Authenticator,
  organization_id: string,
|};

export type MulesoftAuthenticatorsList = $ReadOnlyArray<MulesoftAuthenticator>;

export type MulesoftAuthenticatorsListResponse = {|
  mulesoft_authenticators: MulesoftAuthenticatorsList,
  users: UsersList,
|};

export type MulesoftAuthenticatorsCreateRequestParams = {
  organization_id: string,
  account_username: string,
  account_password: string,
  ...
};

export type MulesoftAuthenticatorCreateResponse = MulesoftAuthenticator;

export async function list(): FetchResponse<MulesoftAuthenticatorsListResponse> {
  return fetch(`${api}/${version}/mulesoft_authenticators`).then(parse);
}

export async function create(
  params: MulesoftAuthenticatorsCreateRequestParams,
): FetchResponse<MulesoftAuthenticatorCreateResponse> {
  return fetch(`${api}/${version}/mulesoft_authenticators`, {
    method: 'POST',
    body: JSON.stringify(params),
  }).then(parse);
}
