// @flow
import { type Saga } from 'redux-saga';
import { put } from 'redux-saga/effects';
import { inventory_stats } from '@dt/horizon-api';
import { callPromise } from '@dt/redux-saga-wrapped-effects';

import { inventoryStatsReceived } from './actions';

import { withCache } from './../resource_fetch/sagas';

export const getInventoryStats = withCache<{}>('inventory_stats', getInventoryStatsRequest);

export function* getInventoryStatsRequest(): Saga<void> {
  const response = yield* callPromise(inventory_stats.list);
  if (response._type === 'error') {
    throw new Error(response.title);
  }
  if (response.body.inventory_stats) {
    if (response.body.inventory_stats.length < 3) {
      throw new Error('response error');
    }
    // We are making an assumption that the array in the response will be
    // ordered in this order: current, week, month. If this isn't stable we
    // should probably parse the date but I'd like to avoid that because
    // controlling timing bleed will be annoying.
    yield put(
      inventoryStatsReceived({
        current: response.body.inventory_stats[0], // unsafe! Make sure length is checked before this line
        week: response.body.inventory_stats[1], // unsafe! Make sure length is checked before this line
        month: response.body.inventory_stats[2], // unsafe! Make sure length is checked before this line
      }),
    );
  }
}
