// @flow
import '@dt/global';
import { ApolloClient, InMemoryCache, ApolloLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { default as SchemaRestLink, ApolloLinkSchemaRestError } from '@dt/apollo-link-schema-rest';
import Raven from 'raven-js';
import fetch, { parse, DEFAULT_ERROR_MESSAGE } from '@dt/fetch';
import config from '@dt/config';
import schema from '@dt/graphql-support/restSchema.graphql';
import getMockResolvers from '@dt/graphql-support/getMockResolvers';
import type { ResolverMap } from 'graphql-tools';
import { restNormalizer } from './rest_normalizer';

/*
 * Creates an apollo client with application specific configuration.
 *
 * Sets up the following:
 * - Error handling
 * - Resolver links
 * - Caching with an in memory cache
 * - Query & mutation policies
 *
 * @param graphQLMocks - Mocks apollo client resolvers.
 */
export const createApolloClient = (cache: InMemoryCache, graphQLMocks: ?ResolverMap) => {
  return new ApolloClient<*>({
    link: ApolloLink.from([
      onError(({ graphQLErrors, networkError, response, operation }) => {
        if (graphQLErrors) {
          for (const error of graphQLErrors) {
            // Log all errors.
            console.error(
              `[GraphQL error]: Message: ${error.message}, Location: ${error.locations}, Path: ${error.path}`,
            );

            // Handle apollo link schema rest errors - These are not recorded as network errors.
            if (error.originalError && error.originalError instanceof ApolloLinkSchemaRestError) {
              // If any backend errors occur with a 5** status codes report to sentry.
              if (error.originalError.statusCode >= 500 && error.originalError.statusCode < 600) {
                Raven.captureException(error, {
                  extra: {
                    msg: `Backend reported ${error.originalError.statusCode} status code when executing operation '${operation.operationName}'.`,
                  },
                });

                // Format 5** response error messages to a friendlier message.
                error.message =
                  'We were unable to complete your request at this time. \nPlease try again or contact us at support@datatheorem.com';
              }
              // If any backend errors occur without a error message report to sentry.
              else if (error.originalError.message === DEFAULT_ERROR_MESSAGE) {
                Raven.captureException(error, {
                  extra: {
                    msg: `Backend did not provide an error message when executing operation '${operation.operationName}'.`,
                  },
                });

                // Format unknown response error messages to a friendlier message.
                error.message =
                  'We were unable to complete your request at this time. \nPlease try again or contact us at support@datatheorem.com';
              }
            }
          }
        }

        if (networkError) {
          console.error(`[Network error]: ${networkError}`);
        }

        response.errors = graphQLErrors;
      }),
      SchemaRestLink({
        // NOTE: Any entries added here should be customer facing approved i.e. *.securetheorem.com as these domains have
        // already been authorized within some customer/prospect environments. Please request for the proxied version
        // if you are provided with a backend such as the following for development purposes:
        // https://default-aycb7dgkma-uc.a.run.app/portal/mobile_protect/v1/.
        // Additionally, see https://bitbucket.org/datatheorem/api-proxy/src/release/apiproxy/config.yaml
        endpoints: {
          public: `${config.publicAPI}/apis/portal`,
          horizon: config.horizonApiBaseUrl,
          cloudy: config.cloudyBaseUrl,
          sevenhell: config.sevenhellApiBaseUrl,
          googleStorage: `${config.googleStorage}/prod-echo_scan_run_logs/1030002`,
        },
        schema,
        fetch,
        fetchParse: parse,
        restNormalizer,
        mockResolvers: getMockResolvers(graphQLMocks),
      }),
    ]),
    cache,
    connectToDevTools: process.env.NODE_ENV !== 'production',
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-first',
        errorPolicy: 'all',
        // TODO: Should use 'cache-only' for `nextFetchPolicy` but, I haven't figured out all of its side effects yet.
        //       nextFetchPolicy: 'cache-only',
      },
      query: {
        fetchPolicy: 'cache-first',
        errorPolicy: 'all',
        // TODO: Should use 'cache-only' for `nextFetchPolicy` but, I haven't figured out all of its side effects yet.
        //       nextFetchPolicy: 'cache-only',
      },
      mutate: {
        // NOTE: DO NOT change `fetchPolicy` or `errorPolicy` there are some nasty dragons here.
        //       To learn more see: https://datatheorem.atlassian.net/wiki/spaces/IW/pages/1297351093/FrontEnd+GraphQL
        errorPolicy: 'all',
      },
    },
  });
};
