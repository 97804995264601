//@flow
import gql from 'graphql-tag';

const create_for_policy_violation = gql`
  mutation SharedLinksCreateForPolicyViolationMutation(
    $id: ID!
    $body: SharedLinksCreateForPolicyViolationParams!
  ) {
    shared_links_for_policy_violation_create(id: $id, body: $body) {
      shared_resource_type
      shared_resource_id
      access_token
    }
  }
`;

const create_for_policy_violations = gql`
  mutation SharedLinksCreateForPolicyViolations(
    $body: SharedLinksCreateForPolicyViolationsParams!
  ) {
    shared_links_for_policy_violations_create(body: $body) {
      action_type
      status
      id
      move_to_group_result
      completed_tasks_count
      total_tasks_count
      secure_share_policy_violations_result {
        policy_violations_ids
        access_token
      }
      date_created
      date_completed
    }
  }
`;

export default {
  create_for_policy_violation,
  create_for_policy_violations,
};
