//@flow
import React, {
  PureComponent,
  type ComponentType,
  type ElementRef,
  createRef,
} from 'react';

export default function(options: {
  fixedWidth?: boolean,
  fixedHeight?: boolean,
  ...
}) {
  return function<Props: { ... }>(
    BaseComponent: ComponentType<Props>,
  ): Class<
    React$Component<Props> & {
      measure: () => {
        height: number,
        width: number,
        ...
      },
      ...
    },
  > {
    return class withMeasurement extends PureComponent<Props> {
      node: { current: null | ElementRef<'div'>, ... } = createRef();
      render() {
        return (
          <div ref={this.node}>
            <BaseComponent {...this.props} />
          </div>
        );
      }

      /**
       * This code is from https://github.com/bvaughn/react-virtualized/blob/838b5b541116d1be3cf66cfb6b38a76bfe6c7759/source/CellMeasurer/CellMeasurer.js
       *
       * @return {{height: number, width: number}}
       */
      measure = () => {
        const node = this.node.current;

        if (node instanceof HTMLElement) {
          const styleWidth = node.style.width;
          const styleHeight = node.style.height;

          // If we are re-measuring a cell that has already been measured,
          // It will have a hard-coded width/height from the previous measurement.
          // The fact that we are measuring indicates this measurement is probably stale,
          // So explicitly clear it out (eg set to "auto") so we can recalculate.
          // See issue #593 for more info.
          // Even if we are measuring initially- if we're inside of a MultiGrid component,
          // Explicitly clear width/height before measuring to avoid being tainted by another Grid.
          // eg top/left Grid renders before bottom/right Grid
          // Since the CellMeasurerCache is shared between them this taints derived cell size values.
          if (!options.fixedWidth) {
            node.style.width = 'auto';
          }
          if (!options.fixedHeight) {
            node.style.height = 'auto';
          }

          const height = Math.ceil(node.offsetHeight);
          const width = Math.ceil(node.offsetWidth);

          // Reset after measuring to avoid breaking styles; see #660
          if (styleWidth) {
            node.style.width = styleWidth;
          }
          if (styleHeight) {
            node.style.height = styleHeight;
          }

          return { height, width };
        } else {
          return { height: 0, width: 0 };
        }
      };
    };
  };
}
