// @flow
import { type Saga } from 'redux-saga';
import { all, call, takeEvery, put } from 'redux-saga/effects';
import { type ActionType } from 'redux-actions';
import { select } from '@dt/redux-saga-wrapped-effects';
import { getNetworkServicesList } from './resource_fetch.sagas';
import { watchForLoadMoreAndFetchNextPage, paginateWhile, paginateToEndLazy } from '../resource_fetch/sagas';
import resourceType from './resourceType';
import {
  reachedEndOfNetworkServicesList,
  reachedEndOfNetworkServicesListForAssetSearchId,
  networkServicesReceived,
  networkServicesReceivedIdsForAssetSearch,
} from './actions';
import { isUserViewingVisualization } from '../dashboard/saga-isViewingVisualization';
import { assetGroupsMembershipReceived } from '../asset_groups/memberships/actions';
import { inventory_search } from '../inventory/search/selectors';

export default function* watchForResourceFetching(): Saga<void> {
  yield all([
    call(watchForLoadMoreAndFetchNextPage, resourceType, function*({ cursor }): Saga<void> {
      yield call(getNetworkServicesList, { cursor });
    }),
    takeEvery(networkServicesReceived.toString(), networkServicesReceivedSaga),
  ]);
}

export const getAllNetworkServicesWhileOnVisualization = function*(): Saga<void> {
  yield call(paginateWhile, getNetworkServicesList, resourceType, {}, isUserViewingVisualization);
};

export const getNetworkServicesLazily = function*(params: { search_id?: string, cursor?: string }): Saga<void> {
  if (params.search_id) {
    yield call(
      paginateToEndLazy,
      getNetworkServicesList,
      resourceType,
      { ...params },
      action => reachedEndOfNetworkServicesListForAssetSearchId.toString() && params.search_id === action.payload,
    );
  } else {
    yield call(
      paginateToEndLazy,
      getNetworkServicesList,
      resourceType,
      { ...params },
      reachedEndOfNetworkServicesList.toString(),
    );
  }
};

function* networkServicesReceivedSaga(action: ActionType<typeof networkServicesReceived>): Saga<void> {
  const { network_services } = action.payload;

  const { lastQueryId, query } = yield* select(inventory_search, {});

  const collector = network_services.reduce(
    (collector, item) => {
      if (item.asset_group_memberships) {
        collector.assetGroupMembers = collector.assetGroupMembers.concat(item.asset_group_memberships);
      }
      collector.idsForCurrentSearch.push(item.id);
      return collector;
    },
    {
      assetGroupMembers: [],
      idsForCurrentSearch: [],
    },
  );

  if (collector.assetGroupMembers) {
    yield put(assetGroupsMembershipReceived(collector.assetGroupMembers));
  }

  if (lastQueryId && (!query.asset_types.length || query.asset_types.includes('NETWORK_SERVICE'))) {
    yield put(networkServicesReceivedIdsForAssetSearch(lastQueryId, collector.idsForCurrentSearch));
  }
}
