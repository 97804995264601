//@flow
import createReducerCreator from 'rezz/createReducerCreator';
import set from 'rezz/set';
import { createAction } from 'redux-actions';

export type RowCountState = { [string]: number | void, ... };

// $FlowFixMe FlowUpgrade
export const setRowCount = createAction<'SET_ROW_COUNT', _, _>('SET_ROW_COUNT', (query: string, rowCount: number) => ({
  query,
  rowCount,
}));

// $FlowFixMe FlowUpgrade
export default createReducerCreator({
  initialState: {},
  payloadStrategy: action => action.payload.rowCount,
  keyStrategy: action => action.payload.query,
})(set(setRowCount.toString()));
