//@flow
import tracking from 'react-tracking';
import { isDataTheoremUser } from '@dt/session';
import { type Node, memo } from 'react';
import { type User } from '@dt/user-api/users';
import type { AccountInfo } from '@dt/user-api/account_info';

type Props = {|
  accountInfo: AccountInfo,
  currentUser: User,
  children: Node,
|};

const DATATHEOREM_CUSTOMER_NAMES = ['Data Theorem', 'SideScreen'];

function getCustomerName({ currentUser, accountInfo }) {
  if (
    currentUser.login_email &&
    isDataTheoremUser(currentUser) &&
    !DATATHEOREM_CUSTOMER_NAMES.includes(accountInfo.name)
  ) {
    return '_DT_';
  } else {
    return accountInfo.name;
  }
}

export default memo<Props>(
  tracking<Props, _>((props: Props) => ({
    customer_name: getCustomerName(props),
  }))(function CustomerNameContext({ children }) {
    return children;
  }),
);
