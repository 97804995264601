// @flow
import { createSelector } from 'reselect';
import { EventTypesDefinition } from '@dt/horizon-api';
import { events, decorate } from './util';
import { getCloudResources } from '../cloud_resources/selectors';
import { getAllTasksForEvents } from '../policy_violations/selectors';
import { getAllNetworkServices } from '../network_services/selectors';
import { getRestfulAPIs } from '../restful_apis/selectors';
import { getWebAppsDecorated } from '../web_applications/selectors';

import type { Event } from './types';
import { users_list } from '../users/selectors';
import { comments } from '../policy_violations/comments/util';

import { type State } from '../store_state_type';

const aws_authenticators_list = ({ configurations }: State) => configurations.aws_authenticators_list;

const gcp_authenticators_list = ({ configurations }: State) => configurations.gcp_authenticators_list;

const azure_authenticators_list = ({ configurations }: State) => configurations.azure_authenticators_list;

const axway_authenticators_list = ({ configurations }: State) => configurations.axway_authenticators_list;

const mulesoft_authenticators_list = ({ configurations }: State) => configurations.mulesoft_authenticators_list;

const apigee_authenticators_list = ({ configurations }: State) => configurations.apigee_authenticators_list;

export const getAllEvents = createSelector<
  State,
  { ... },
  $ReadOnlyArray<Event>,
  _,
  _,
  _,
  _,
  _,
  _,
  _,
  _,
  _,
  _,
  _,
  _,
  _,
  _,
>(
  events,
  getAllNetworkServices,
  getRestfulAPIs,
  getCloudResources,
  getAllTasksForEvents,
  getWebAppsDecorated,
  aws_authenticators_list,
  gcp_authenticators_list,
  azure_authenticators_list,
  axway_authenticators_list,
  mulesoft_authenticators_list,
  apigee_authenticators_list,
  users_list,
  comments,

  (
    events,
    network_services_decorated,
    restful_apis_decorated,
    cloud_resources_decorated,
    policy_violations_decorated,
    web_applications,
    aws_authenticators,
    gcp_authenticators,
    azure_authenticators,
    axway_authenticators,
    mulesoft_authenticators,
    apigee_authenticators,
    users,
    comments,
  ) =>
    events
      .map(event =>
        decorate(
          event,
          network_services_decorated,
          restful_apis_decorated,
          cloud_resources_decorated,
          policy_violations_decorated,
          web_applications,
          aws_authenticators,
          gcp_authenticators,
          azure_authenticators,
          axway_authenticators,
          mulesoft_authenticators,
          apigee_authenticators,
          users,
          comments,
        ),
      )
      .filter(Boolean),
);

export const getDiscoveryEvents = createSelector<State, { ... }, $ReadOnlyArray<Event>, _>(
  getAllEvents,

  events =>
    events.filter(({ event_type }) =>
      [
        EventTypesDefinition.NETWORK_SERVICE_DISCOVERED,
        EventTypesDefinition.RESTFUL_API_DISCOVERED,
        EventTypesDefinition.CLOUD_RESOURCE_DISCOVERED,
        EventTypesDefinition.WEB_APPLICATION_DISCOVERED,
      ].includes(event_type),
    ),
);
