//@flow
import { type Saga } from 'redux-saga';
import { select as originalSelect } from 'redux-saga/effects';

export default function* select<T, State, Props>(
  fn: (State, Props) => T,
  props: Props,
): Saga<T> {
  // We want errors to propogate
  return yield originalSelect(fn, props); //eslint-disable-line redux-saga/no-unhandled-errors
}
