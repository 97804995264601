//@flow
import React, { PureComponent, type Node } from 'react';
import withMeasurement from './withMeasurement';

type Props = { children: Node, ... };

export default withMeasurement({
  fixedWidth: true,
})<Props>(
  class MeasureableHeightDiv extends PureComponent<Props> {
    render() {
      const { children, ...props } = this.props;
      return <div {...props}>{children}</div>;
    }
  },
);
