//@flow
import createReducerCreator from 'rezz/createReducerCreator';
import updateInList from 'rezz/updateInList';
import { createAction } from 'redux-actions';
import { type SDKFinding } from '@dt/user-api/sdk_findings';

export type SDKFindingsState = $ReadOnlyArray<SDKFinding>;

// $FlowFixMe FlowUpgrade
export const updateFindings = createAction<'SDK_ISSUES_UPDATE', _, _>(
  'SDK_ISSUES_UPDATE',
  (finding: $ReadOnlyArray<SDKFinding>): $ReadOnlyArray<SDKFinding> => finding,
);

// $FlowFixMe FlowUpgrade
export default createReducerCreator({
  initialState: ([]: SDKFindingsState),
  equalityCheck: (a, b) => a.id === b.id,
})(updateInList(updateFindings.toString()));
