// @flow
import { type ActionType } from 'redux-actions';
import {
  policiesReceived,
  policiesCreate,
  policiesCreateSuccess,
  policiesCreateError,
  policiesCreateDone,
  policiesDeleteButtonClicked,
} from './actions';
import type { Policy } from '@dt/horizon-api';
import immer from 'immer';

export type PolicyState = {|
  ids: { [string]: void | Policy, ... },

  create: {
    isLoading: boolean,
    newlyCreatedPolicyId: string | null,
    isError: boolean,
    ...
  },
|};

const initialState: PolicyState = {
  ids: {},
  create: {
    isLoading: false,
    newlyCreatedPolicyId: null,
    isError: false,
  },
};

type Actions =
  | ActionType<typeof policiesReceived>
  | ActionType<typeof policiesCreate>
  | ActionType<typeof policiesCreateSuccess>
  | ActionType<typeof policiesCreateError>
  | ActionType<typeof policiesCreateDone>
  | ActionType<typeof policiesDeleteButtonClicked>;

export default immer<PolicyState, Actions>((draft, action: Actions): void | PolicyState => {
  switch (action.type) {
    case policiesReceived.toString(): {
      action.payload.forEach(policy => {
        draft.ids[policy.id] = policy;
      });
      return;
    }

    case policiesCreate.toString(): {
      draft.create.isLoading = true;
      draft.create.newlyCreatedPolicyId = null;
      draft.create.isError = false;
      return;
    }

    case policiesCreateSuccess.toString(): {
      draft.create.isLoading = false;
      draft.create.newlyCreatedPolicyId = action.payload;
      draft.create.isError = false;
      return;
    }

    case policiesCreateError.toString(): {
      draft.create.isLoading = false;
      draft.create.newlyCreatedPolicyId = null;
      draft.create.isError = true;
      return;
    }
    case policiesCreateDone.toString(): {
      draft.create.newlyCreatedPolicyId = null;
      return;
    }
    case policiesDeleteButtonClicked.toString(): {
      draft.ids[action.payload] = void 0;
      return;
    }
  }
}, initialState);
