// @flow
import React, { memo, type Node } from 'react';
import { type Properties } from 'csstype';

type Props = {
  children: Node,
  activeStyle: Properties<>,
  ...
};

// Use case for navigation with nested items with the top-level item
// having no relevant link.
function NavWithoutLink({ children, activeStyle, ...rest }: Props) {
  return <span {...rest}>{children}</span>;
}

export default memo<Props>(NavWithoutLink);
