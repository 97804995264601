// @flow
import { createReducerCreator, updateInList } from 'rezz';
import { certificateChainsReceived } from './actions';
import type { CertificateChainList, CertificateChain } from '@dt/horizon-api';

export type CertificateChainState = CertificateChainList;

export default createReducerCreator<CertificateChainState, CertificateChain, _>({
  initialState: [],
  equalityCheck: (a, b) => a.id === b.id,
})(updateInList([certificateChainsReceived.toString()]));
