//@flow
import React, { memo, type Node } from 'react';
// $FlowFixMe[untyped-import] clsx does not have flow types
import clsx from 'clsx';
import { type Properties } from 'csstype';

const variantToElement: { [Variant]: ComponentType, ... } = {
  titleXL: 'h1',
  titleL: 'h2',
  titleM: 'h3',
  titleS: 'h4',
  titleXS: 'h5',
  body: 'p',
  bodyS: 'p',
  caption: 'span',
  label: 'span',
  labelL: 'span',
  link: 'span',
  button: 'span',
  helperText: 'span',
  chip: 'span',
  numberXL: 'span',
  code: 'code',
};

type ComponentType = 'a' | 'code' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'p' | 'span' | 'div' | 'pre' | 'li';

type Variant =
  | 'titleXL'
  | 'titleL'
  | 'titleM'
  | 'titleS'
  | 'titleXS'
  | 'body'
  | 'bodyS'
  | 'caption'
  | 'label'
  | 'labelL'
  | 'link'
  | 'button'
  | 'helperText'
  | 'chip'
  | 'numberXL'
  | 'code';

type Props = {
  children: ?Node,
  variant?: Variant,
  component?: ComponentType,
  label?: string,
  style?: Properties<>,
  color?: string,
  className?: string,
  noWrap?: boolean,
  onClick?: () => void,
};

function Text({
  variant = 'body',
  component,
  style = {},
  color,
  className = '',
  children,
  label,
  noWrap,
  onClick,
}: Props) {
  let Comp = component || variantToElement[variant];

  return (
    <Comp
      aria-label={label}
      className={clsx(`text-${variant}`, noWrap && 'text-truncate', className)}
      style={{ color, ...style }}
      onClick={onClick}
    >
      {children}
    </Comp>
  );
}

export default memo<Props>(Text);
