//@flow
import { createAction } from 'redux-actions';
import { type GraphqlApiList } from '@dt/horizon-api';

export const graphqlApisReceived = createAction<
  'graphql_apis/received',
  [GraphqlApiList],
  {
    graphql_apis: GraphqlApiList,
    ...
  },
>('graphql_apis/received', graphql_apis => ({
  graphql_apis,
}));
