//@flow
import immer from 'immer';

import type { ActionType } from 'redux-actions';
import type { IPRange } from '@dt/horizon-api';

import { ipRangesReceived } from './actions';

export type IPRangesState = {|
  +id: { [string]: void | IPRange, ... },
|};

type Actions = ActionType<typeof ipRangesReceived>;

const initialState = {
  id: {},
};

export default immer<IPRangesState, Actions>((draft, action: Actions) => {
  if (action.type === ipRangesReceived.toString()) {
    const { ip_ranges } = action.payload;

    for (const ip_range of ip_ranges) {
      draft.id[ip_range.id] = ip_range;
    }
  }
}, initialState);
