//@flow
import { EventTypesDefinition, DiscoveredVia } from '@dt/horizon-api';

import type { DiscoveredViaType } from '@dt/horizon-api';

import type { AwsAuthenticator } from '../configurations/types';
import type { GcpAuthenticator } from '../configurations/types';
import type { AzureAuthenticator } from '../configurations/types';
import type { AxwayAuthenticator } from '../configurations/types';
import type { MulesoftAuthenticator } from '../configurations/types';
import type { ApigeeAuthenticator } from '../configurations/types';
import type { Event, EventNetworkService, PolicyViolation, ApiOperation, RestfulApi, CloudResource } from './types';

const findDiscover = ({ discovered_via }: { +discovered_via: DiscoveredViaType, ... }) => {
  return (
    ({
      [DiscoveredVia.AMAZON_WEB_SERVICES]: 'AWS scanning',
      [DiscoveredVia.MANUAL_IMPORT]: 'manual import',
      [DiscoveredVia.MOBILE_APP_SCANS]: 'mobile application scanning',
      [DiscoveredVia.APIGEE]: 'Apigee scanning',
      [DiscoveredVia.CERTIFICATE_TRANSPARENCY]: 'Certificate Transparency scanning',
      [DiscoveredVia.MULESOFT]: 'MuleSoft scanning',
      [DiscoveredVia.AZURE]: 'Azure scanning',
      [DiscoveredVia.AXWAY]: 'Axway scanning',
      [DiscoveredVia.GOOGLE_CLOUD_PLATFORM]: 'GCP scanning',
      [DiscoveredVia.WEB_APPLICATION_SCANS]: 'Web Application scanning',
      [DiscoveredVia.REVERSE_IP_LOOKUP]: 'Reverse IP lookup',
      [DiscoveredVia.APPLICATION_FINGERPRINTING]: 'Blackbox Analyzer',
      [DiscoveredVia.SWAGGER_HUB]: 'SwaggerHub scanning',
    }: { +[DiscoveredViaType]: string, ... })[discovered_via] || ''
  );
};

// Each event type has two types of descriptions: In-context and Out-context

// Example is:
//  Inside Policy Violation Details, POLICY_VIOLATION_CREATED will show its description as `Policy Violation Created`
//  on Activity Dashboard, POLICY_VIOLATION_CREATED will show its description as `Policy Violation Created: "Policy Violation Title"`

// =========
// Network Service Descriptions

// In context
const networkServiceInContextDescriptions: {
  [string]: (EventNetworkService) => string,
  ...,
} = {
  [EventTypesDefinition.NETWORK_SERVICE_DISCOVERED]: network_service =>
    `API Domain discovered via ${findDiscover(network_service)}`,
  [EventTypesDefinition.NETWORK_SERVICE_WENT_OFFLINE]: () => `API Domain no longer reachable`,
  [EventTypesDefinition.NETWORK_SERVICE_WENT_ONLINE]: () => `API Domain reachable again`,
};
// Out context
const networkServiceOutContextDescriptions: {
  [string]: (EventNetworkService) => string,
  ...,
} = {
  [EventTypesDefinition.NETWORK_SERVICE_DISCOVERED]: network_service =>
    `API Domain discovered via ${findDiscover(network_service)}: ${network_service.url}`,
  [EventTypesDefinition.NETWORK_SERVICE_WENT_OFFLINE]: network_service =>
    `API Domain no longer reachable: ${network_service.url}`,
  [EventTypesDefinition.NETWORK_SERVICE_WENT_ONLINE]: network_service =>
    `API Domain reachable again: ${network_service.url}`,
};

// =========
// Policy Violation Descriptions

// In context
const policyViolationInContextDescriptions: {
  [string]: (PolicyViolation) => string,
  ...,
} = {
  [EventTypesDefinition.POLICY_VIOLATION_CREATED]: () => `Policy Violation discovered`,
  [EventTypesDefinition.POLICY_VIOLATION_RESOLVED]: () => `Policy Violation resolved`,
  [EventTypesDefinition.POLICY_VIOLATION_EXCEPTION_ADDED]: policy_violation =>
    `Policy Violation manually closed ${
      policy_violation.exception_added_by_user ? ` by "${policy_violation.exception_added_by_user.login_email}"` : ''
    }${
      policy_violation.exception_explanation
        ? ` with the following explanation: "${policy_violation.exception_explanation}"`
        : ''
    }`,
};

// Out context
const policyViolationOutContextDescriptions: {
  [string]: (PolicyViolation) => string,
  ...,
} = {
  [EventTypesDefinition.POLICY_VIOLATION_CREATED]: affectedComponent =>
    `Policy Violation discovered: "${affectedComponent.violated_policy_rule.policy_rule_type.title}"`,
  [EventTypesDefinition.POLICY_VIOLATION_RESOLVED]: affectedComponent =>
    `Policy Violation resolved "${affectedComponent.violated_policy_rule.policy_rule_type.title}"`,
  [EventTypesDefinition.POLICY_VIOLATION_EXCEPTION_ADDED]: policy_violation =>
    `Policy Violation "${policy_violation.violated_policy_rule.policy_rule_type.title}" manually closed ${
      policy_violation.exception_added_by_user ? ` by "${policy_violation.exception_added_by_user.login_email}"` : ''
    }${
      policy_violation.exception_explanation
        ? ` with the following explanation: "${policy_violation.exception_explanation}"`
        : ''
    }`,
};

// =========
// RESTful API Descriptions

// In context
const restfulAPIInContextDescriptions: {
  [string]: (RestfulApi) => string,
  ...,
} = {
  [EventTypesDefinition.RESTFUL_API_DISCOVERED]: restful_api =>
    `RESTful API discovered via ${findDiscover(restful_api)}`,
  [EventTypesDefinition.RESTFUL_API_UPDATED]: () => `RESTful API updated`,
};

// Out context
const restfulAPIOutContextDescriptions: {
  [string]: (RestfulApi) => string,
  ...,
} = {
  [EventTypesDefinition.RESTFUL_API_DISCOVERED]: restful_api =>
    `RESTful API discovered via ${findDiscover(restful_api)}: "${restful_api.base_url}"`,
  [EventTypesDefinition.RESTFUL_API_UPDATED]: restful_api => `RESTful API updated: "${restful_api.base_url}"`,
};

// =========
// Cloud Resource Descriptions

// In context
const cloudResourceInContextDescriptions: {
  [string]: (CloudResource) => string,
  ...,
} = {
  [EventTypesDefinition.CLOUD_RESOURCE_DISCOVERED]: cloud_resource =>
    `Cloud Resource discovered via ${findDiscover(cloud_resource)}`,
};

// Out context
const cloudResourceOutContextDescriptions: {
  [string]: (CloudResource) => string,
  ...,
} = {
  [EventTypesDefinition.CLOUD_RESOURCE_DISCOVERED]: cloud_resource =>
    `Cloud Resource discovered via ${findDiscover(cloud_resource)}: "${cloud_resource.asset_type_name}"`,
};

// =========
// AWS Authenticators

// In context
const awsAuthenticatorInContextDescriptions: {
  [string]: (AwsAuthenticator) => string,
  ...,
} = {
  [EventTypesDefinition.AWS_AUTHENTICATOR_CREATED]: () => `AWS Authenticator created`,
};

// Out context
const awsAuthenticatorOutContextDescriptions: {
  [string]: (AwsAuthenticator) => string,
  ...,
} = {
  [EventTypesDefinition.AWS_AUTHENTICATOR_CREATED]: aws_authenticator =>
    `AWS Authenticator created: "${aws_authenticator.role_arn}"`,
};

// =========
// GCP Authenticators

// In context
const gcpAuthenticatorInContextDescriptions: {
  [string]: (GcpAuthenticator) => string,
  ...,
} = {
  [EventTypesDefinition.GCP_AUTHENTICATOR_CREATED]: () => `GCP Authenticator created`,
};

// Out context
const gcpAuthenticatorOutContextDescriptions: {
  [string]: (GcpAuthenticator) => string,
  ...,
} = {
  [EventTypesDefinition.GCP_AUTHENTICATOR_CREATED]: gcp_authenticator =>
    `GCP Authenticator created: "${gcp_authenticator.client_email}"`,
};

// =========
// Azure Authenticators

// In context
const azureAuthenticatorInContextDescriptions: {
  [string]: (AzureAuthenticator) => string,
  ...,
} = {
  [EventTypesDefinition.AZURE_AUTHENTICATOR_CREATED]: () => `Azure Authenticator created`,
};

// Out context
const azureAuthenticatorOutContextDescriptions: {
  [string]: (AzureAuthenticator) => string,
  ...,
} = {
  [EventTypesDefinition.AZURE_AUTHENTICATOR_CREATED]: azure_authenticator =>
    `Azure Authenticator created: "${azure_authenticator.tenant_id}"`,
};

// =========
// Axway Authenticators

// In context
const axwayAuthenticatorInContextDescriptions: {
  [string]: (AxwayAuthenticator) => string,
  ...,
} = {
  [EventTypesDefinition.AXWAY_AUTHENTICATOR_CREATED]: () => `Axway Authenticator created`,
};

// Out context
const axwayAuthenticatorOutContextDescriptions: {
  [string]: (AxwayAuthenticator) => string,
  ...,
} = {
  [EventTypesDefinition.AXWAY_AUTHENTICATOR_CREATED]: axway_authenticator =>
    `Axway Authenticator created: "${axway_authenticator.client_id}"`,
};

// =========
// Mulesoft Authenticators

// In context
const mulesoftAuthenticatorInContextDescriptions: {
  [string]: (MulesoftAuthenticator) => string,
  ...,
} = {
  [EventTypesDefinition.MULESOFT_AUTHENTICATOR_CREATED]: () => `MuleSoft Authenticator created`,
};

// Out context
const mulesoftAuthenticatorOutContextDescriptions: {
  [string]: (MulesoftAuthenticator) => string,
  ...,
} = {
  [EventTypesDefinition.MULESOFT_AUTHENTICATOR_CREATED]: mulesoft_authenticator =>
    `MuleSoft Authenticator created: "${mulesoft_authenticator.organization_id}"`,
};

// =========
// Apigee Authenticators

// In context
const apigeeAuthenticatorInContextDescriptions: {
  [string]: (ApigeeAuthenticator) => string,
  ...,
} = {
  [EventTypesDefinition.APIGEE_AUTHENTICATOR_CREATED]: () => `Apigee Authenticator created`,
};

// Out context
const apigeeAuthenticatorOutContextDescriptions: {
  [string]: (ApigeeAuthenticator) => string,
  ...,
} = {
  [EventTypesDefinition.APIGEE_AUTHENTICATOR_CREATED]: apigee_authenticator =>
    `Apigee Authenticator created: "${apigee_authenticator.organization}"`,
};

// =========
// User Descriptions

// In context
const userInContextDescriptions: {
  [string]: ({ +login_email: string, ... }) => string,
  ...,
} = {
  [EventTypesDefinition.USER_LOGGED_IN]: () => `User logged in`,
};

// Out context
const userOutContextDescriptions: {
  [string]: ({ +login_email: string, ... }) => string,
  ...,
} = {
  [EventTypesDefinition.USER_LOGGED_IN]: user => `User logged in: "${user.login_email}"`,
};

const commentInContextDescriptions: {
  [string]: ({ +content: ?string, ... }, ?{ +login_email: string, ... }) => string,
  ...,
} = {
  [EventTypesDefinition.COMMENT_CREATED]: (comment, user) =>
    `Comment added${user ? ` by "${user.login_email}"` : ''}: ${
      comment.content ? `"${comment.content}"` : 'Comment Deleted'
    }`,
  [EventTypesDefinition.COMMENT_DELETED]: (comment, user) =>
    `Comment deleted${user ? ` by "${user.login_email}"` : ''}`,
};

const commentOutContextDescriptions: {
  [string]: ({ +content: ?string, ... }, ?{ +login_email: string, ... }) => string,
  ...,
} = {
  [EventTypesDefinition.COMMENT_CREATED]: (comment, user) => `Comment added${user ? ` by "${user.login_email}"` : ''}`,
  [EventTypesDefinition.COMMENT_DELETED]: (comment, user) =>
    `Comment deleted${user ? ` by "${user.login_email}"` : ''}`,
};

// =========
// API Descriptions

const apiOpsInContextDescriptions: {
  [string]: (ApiOperation, RestfulApi) => string,
  ...,
} = {
  [EventTypesDefinition.API_OPERATION_UPDATED]: api_operation =>
    `API Operation updated: (${api_operation.http_method}) ${api_operation.path}`,
  [EventTypesDefinition.API_OPERATION_NO_LONGER_ACCESSIBLE]: api_operation =>
    `API Operation no longer accessible: (${api_operation.http_method}) ${api_operation.path}`,
  [EventTypesDefinition.API_OPERATION_CREATED]: api_operation =>
    `API Operation discovered: (${api_operation.http_method}) ${api_operation.path}`,
};

const apiOpsOutContextDescriptions: {
  [string]: (ApiOperation, RestfulApi) => string,
  ...,
} = {
  [EventTypesDefinition.API_OPERATION_UPDATED]: (api_operation, restful_api) =>
    `API Operation updated: (${api_operation.http_method}) ${restful_api.base_url}${api_operation.path}`,
  [EventTypesDefinition.API_OPERATION_NO_LONGER_ACCESSIBLE]: (api_operation, restful_api) =>
    `API Operation no longer accessible: (${api_operation.http_method}) ${restful_api.base_url}${api_operation.path}`,
  [EventTypesDefinition.API_OPERATION_CREATED]: (api_operation, restful_api) =>
    `API Operation discovered: (${api_operation.http_method}) ${restful_api.base_url}${api_operation.path}`,
};

type Params = {
  event: Event,
  contextId?: string,
  ...
};

export default ({ event, contextId }: Params): string => {
  const { event_type } = event;

  const eventType = EventTypesDefinition[event_type];

  if (!eventType) {
    if (process.env.NODE_EMV !== 'production' && process.env.NODE_ENV !== 'test')
      console.warn(process.env.NODE_ENV, 'Missing event type=', event);

    return '';
  }

  try {
    if (event.network_service) {
      return contextId === event.network_service.id
        ? networkServiceInContextDescriptions[eventType](event.network_service)
        : networkServiceOutContextDescriptions[eventType](event.network_service);
    }

    if (event.restful_api && event.api_operation) {
      // API Operation context are actually inside restful api context
      return contextId === event.restful_api.id
        ? apiOpsInContextDescriptions[eventType](event.api_operation, event.restful_api)
        : apiOpsOutContextDescriptions[eventType](event.api_operation, event.restful_api);
    }

    if (event.restful_api) {
      return contextId === event.restful_api.id
        ? restfulAPIInContextDescriptions[eventType](event.restful_api)
        : restfulAPIOutContextDescriptions[eventType](event.restful_api);
    }

    if (!event.comment && event.policy_violation) {
      return contextId === event.policy_violation.id
        ? policyViolationInContextDescriptions[eventType](event.policy_violation)
        : policyViolationOutContextDescriptions[eventType](event.policy_violation);
    }

    if (event.cloud_resource) {
      return contextId === event.cloud_resource.id
        ? cloudResourceInContextDescriptions[eventType](event.cloud_resource)
        : cloudResourceOutContextDescriptions[eventType](event.cloud_resource);
    }

    if (event.aws_authenticator) {
      return contextId === event.aws_authenticator.id
        ? awsAuthenticatorInContextDescriptions[eventType](event.aws_authenticator)
        : awsAuthenticatorOutContextDescriptions[eventType](event.aws_authenticator);
    }

    if (event.gcp_authenticator) {
      return contextId === event.gcp_authenticator.id
        ? gcpAuthenticatorInContextDescriptions[eventType](event.gcp_authenticator)
        : gcpAuthenticatorOutContextDescriptions[eventType](event.gcp_authenticator);
    }

    if (event.azure_authenticator) {
      return contextId === event.azure_authenticator.id
        ? azureAuthenticatorInContextDescriptions[eventType](event.azure_authenticator)
        : azureAuthenticatorOutContextDescriptions[eventType](event.azure_authenticator);
    }

    if (event.axway_authenticator) {
      return contextId === event.axway_authenticator.id
        ? axwayAuthenticatorInContextDescriptions[eventType](event.axway_authenticator)
        : axwayAuthenticatorOutContextDescriptions[eventType](event.axway_authenticator);
    }

    if (event.mulesoft_authenticator) {
      return contextId === event.mulesoft_authenticator.id
        ? mulesoftAuthenticatorInContextDescriptions[eventType](event.mulesoft_authenticator)
        : mulesoftAuthenticatorOutContextDescriptions[eventType](event.mulesoft_authenticator);
    }

    if (event.apigee_authenticator) {
      return contextId === event.apigee_authenticator.id
        ? apigeeAuthenticatorInContextDescriptions[eventType](event.apigee_authenticator)
        : apigeeAuthenticatorOutContextDescriptions[eventType](event.apigee_authenticator);
    }

    if (event.comment) {
      // Comments context are actually inside Policy Violation context
      return contextId === event.comment.related_policy_violation_id
        ? commentInContextDescriptions[eventType](event.comment, event.user)
        : commentOutContextDescriptions[eventType](event.comment, event.user);
    }

    // This is last for a reason. User can be in multiples
    if (event.user) {
      return contextId === event.user.id
        ? userInContextDescriptions[eventType](event.user)
        : userOutContextDescriptions[eventType](event.user);
    }
  } catch (err) {
    if (process.env.NODE_EMV !== 'production' && process.env.NODE_ENV !== 'test')
      console.warn(process.env.NODE_ENV, 'Missing event type=', event, err);
  }

  return '';
};
