// @flow
import { type Saga } from 'redux-saga';

import { select } from '@dt/redux-saga-wrapped-effects';

import type { State } from '../store_state_type';
export function* isUserViewingVisualization(): Saga<boolean> {
  const { isViewingVisualization } = yield* select((state: State) => state.dashboard);
  return isViewingVisualization;
}
