// @flow
import { createSelector } from 'reselect';
import { createObjectSelector } from 'reselect-map';
import type { State } from '../store_state_type';
import { decorate, web_applications } from './util';
import { values } from '@dt/functions';
import type { WebApplicationDecorated, WebApplicationDecoratedList } from './types';
import { getAllTasksWithAffectedComponents } from '../policy_violations/selectors';
import { getRestfulAPIs } from '../restful_apis/selectors';
import { getAllNetworkServices } from '../network_services/selectors';
import { getCurrentSearchId, getResultsOfSearch } from '../inventory/search/selectors';

export const getWebAppsDecoratedObj = createObjectSelector<State, { ... }, ?WebApplicationDecorated, _, _, _, _, _>(
  state => state.web_applications.id,
  getAllTasksWithAffectedComponents,
  getRestfulAPIs,
  getAllNetworkServices,
  (state, props = {}) => props,
  (web_application, policy_violations_decorated, restful_apis_decorated, network_services_decorated, filters) => {
    if (
      filters &&
      filters.network_service_id &&
      !(filters.network_service_id && filters.network_service_id === web_application.network_service_id)
    ) {
      return null;
    }

    return decorate(
      web_application,
      policy_violations_decorated,
      restful_apis_decorated,
      network_services_decorated.find(s => s.id === web_application.network_service_id),
    );
  },
);

export const getWebAppsDecorated = createSelector<
  State,
  { ... } | { +network_service_id: string, ... },
  WebApplicationDecoratedList,
  _,
  _,
  _,
  _,
  _,
>(getWebAppsDecoratedObj, web_applications => {
  return values(web_applications).filter(Boolean);
});

export const getWebApplicationById = createSelector<State, { id: string, ... }, ?WebApplicationDecorated, _, _>(
  (state, props = {}) => props.id,
  getWebAppsDecorated,
  (id, web_applications_decorated) => {
    return web_applications_decorated.find(w => w && w.id === id);
  },
);

export const getWebAppsDecoratedForInventory = createSelector<
  State,
  { ... } | { +network_service_id: string, ... },
  WebApplicationDecoratedList,
  _,
  _,
  _,
  _,
  _,
  _,
>(
  web_applications,
  getCurrentSearchId,
  getAllTasksWithAffectedComponents,
  getRestfulAPIs,
  getAllNetworkServices,
  (state, props = {}) => (props.network_service_id ? props.network_service_id : null),
  (
    web_applications_state,
    currentSearchId,
    policy_violations_decorated,
    restful_apis_decorated,
    network_services_decorated,
    networkServiceIdFiltered,
  ) => {
    let web_applications_filtered = values(getResultsOfSearch(currentSearchId, web_applications_state));

    if (networkServiceIdFiltered)
      web_applications_filtered = web_applications_filtered.filter(web_app => {
        return networkServiceIdFiltered === web_app.network_service_id;
      });

    return web_applications_filtered
      .map(w =>
        decorate(
          w,
          policy_violations_decorated,
          restful_apis_decorated,
          network_services_decorated.find(s => s.id === w.network_service_id),
        ),
      )
      .filter(Boolean);
  },
);
