//@flow
import type { Params } from './types';

function localeStringSort(a: string, b: string) {
  return a.localeCompare(b);
}

function stringFromParametricRequest(params: Params) {
  const keys = Object.keys(params)
    .sort(localeStringSort)
    .filter(key => key !== 'cursor');
  const values = keys
    .map((k: string) => params[k])
    .filter(Boolean)
    .sort(localeStringSort);
  return `${keys.join('-')}-${values.join('-')}`;
}

export default function getKey(params: Params) {
  return stringFromParametricRequest(params);
}
