// @flow
import { createAction } from 'redux-actions';
import { type WebApplicationList } from '@dt/horizon-api';

export const webApplicationsReceived = createAction<
  'web_applications/received',
  [WebApplicationList],
  {
    web_applications: WebApplicationList,
    ...
  },
>('web_applications/received', web_applications => ({
  web_applications,
}));

export const webApplicationDetailsMounted = createAction<
  'mounted/webApplicationDetails',
  [string],
  {
    webApplicationId: string,
    key: 'webApplicationDetails',
    ...
  },
>('mounted/webApplicationDetails', webApplicationId => ({
  webApplicationId,
  key: 'webApplicationDetails',
}));

export const inventorySearchWebApplicationsReceived = createAction<
  'web_applications/search/received',
  [string, WebApplicationList],
  {
    search_id: string,
    web_applications: WebApplicationList,
    ...
  },
>('web_applications/search/received', (search_id, web_applications) => ({
  search_id,
  web_applications,
}));

export const reachedEndOfWebApplicationsList = createAction<'web_applications/list/reached_end', [], void>(
  'web_applications/list/reached_end',
  () => undefined,
);

export const reachedEndOfWebApplicationsListForAssetSearchId = createAction<
  'web_applications/list/search/reached_end',
  [string],
  string,
>('web_applications/list/search/reached_end', search_id => search_id);

export const webApplicationsReceivedIdsForAssetSearch = createAction<
  'web_applications/search/ids/received',
  [string, Array<string>],
  {|
    searchId: string,
    ids: Array<string>,
  |},
>('web_applications/search/ids/received', (searchId, ids) => ({
  searchId,
  ids,
}));
