// @flow
import { finance } from '@dt/user-api/uploads';
export { renewal_reminder_dismissal } from '@dt/user-api/current_user';
export { default as notify_billing_contact } from '@dt/user-api/renewal_reminders';
export { inventory_stats } from '@dt/horizon-api';
export { list as mobile_apps_list } from '@dt/user-api/mobile_apps';

type financeUploadArgs = {|
  file: File | Blob,
  name: string,
  purpose: string,
  size: number,
  origin: string,
|};

type PoUploadArgs = {|
  path: string,
  file: File | Blob,
|};

type GoogleStorageSuccess = { ... };

type GoogleStorageFailure = {|
  error: string,
|};

type GoogleStorageResult = GoogleStorageSuccess | GoogleStorageFailure;

async function uploadToGoogleStorage(
  poUploadArgs: PoUploadArgs,
): Promise<GoogleStorageResult> {
  const { path, file } = poUploadArgs;

  /**
   * there is problem with preflighted CORS request on chrome
   * even when file uploads on google storage successfully
   * it return CORS error
   */
  return fetch(path, {
    method: 'PUT',
    body: file,
  }).then(response => response.json());
}

export async function upload_po_file_to_google_storage(
  args: financeUploadArgs,
): Promise<{ ... }> {
  const { file, name: filename, purpose, size, origin } = args;
  if (!file || !filename || !purpose || !size || !origin) {
    throw new Error('Invalid params supplied to create upload request');
  }
  try {
    const upload_url = await finance.create({
      filename,
      purpose,
      size,
      origin,
    });
    return uploadToGoogleStorage({ path: upload_url, file });
  } catch (error) {
    return Promise.reject(error);
  }
}
