// @flow
import { type Saga } from 'redux-saga';
import { put, call } from 'redux-saga/effects';
import { events, type EventsListParams } from '@dt/horizon-api';
import { callPromise } from '@dt/redux-saga-wrapped-effects';
import { paginationBeginRequest, paginationEndRequest } from '@dt/pagination';

import { withCache, handleNormalizedResponse } from './../resource_fetch/sagas';
import resourceType from './resourceType';

export const getEventsList = withCache<EventsListParams>('events_list', function* getEventsList(
  params: EventsListParams,
): Saga<void> {
  yield put(paginationBeginRequest(resourceType, { ...params }));
  const response = yield* callPromise(events.list, params);

  if (response._type === 'error') {
    throw new Error(response.title);
  }

  yield call(handleNormalizedResponse, response.body);
  yield put(paginationEndRequest(resourceType, { ...params }, response.body.pagination_information));
});
