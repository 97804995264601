//@flow
import createReducerCreator from 'rezz/createReducerCreator';
import updateInList from 'rezz/updateInList';
import { createAction } from 'redux-actions';
import { type Application } from '@dt/user-api/mobile_apps';

export type AppsState = $ReadOnlyArray<Application>;

// $FlowFixMe FlowUpgrade
export const updateApp = createAction<'APPS_UPDATE', _, _>(
  'APPS_UPDATE',
  (app: $ReadOnlyArray<Application>): $ReadOnlyArray<Application> => app,
);

export default createReducerCreator<AppsState, Application, _>({
  initialState: [],
  equalityCheck: (a, b) => a.id === b.id,
})(updateInList(updateApp.toString()));
