// @flow
import config from '@dt/config';

import fetch, { parse, type APIResult as FetchResponse } from '@dt/fetch';
import { type PolicyRuleTypeList } from './types';

const api = config.horizonApiBaseUrl;
const version = 'v1';

export type PolicyRuleTypeListResponse = {|
  +policy_rule_types: PolicyRuleTypeList,
|};

export async function list(): FetchResponse<PolicyRuleTypeListResponse> {
  return fetch(`${api}/${version}/policy_rule_types`).then(parse);
}
