// @flow
import immer from 'immer';
import type { ActionType } from 'redux-actions';
import { domainNamesReceived } from './actions';
import type { DomainName } from '@dt/horizon-api';

export type DomainNameState = {
  +id: { [string]: void | DomainName, ... },
  ...
};

const initialState = {
  id: {},
};

type Actions = ActionType<typeof domainNamesReceived>;

export default immer<DomainNameState, Actions>((draft, action: Actions) => {
  if (action.type === domainNamesReceived.toString()) {
    for (const domain_name of action.payload) {
      draft.id[domain_name.id] = domain_name;
    }
  }
}, initialState);
