// @flow
import { createAction } from 'redux-actions';

export const inventoryMounted = createAction<'mounted/inventory', [], { key: 'inventory', ... }>(
  'mounted/inventory',
  () => ({
    key: 'inventory',
  }),
);

export const inventoryRowsRequested = createAction<
  'rows-requested/inventory',
  [{ search_id?: ?string }],
  { search_id?: ?string, ... },
>('rows-requested/inventory', search_id => search_id);
