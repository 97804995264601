// @flow
import { createSelector } from 'reselect';
import { type State } from '../../store_state_type';
import { type PaginationInfo } from '@dt/pagination';
import { values } from '@dt/functions';
import restful_apis from '../../restful_apis/resourceType';
import network_services from '../../network_services/resourceType';
import cloud_resources from '../../cloud_resources/resourceType';
import web_applications from '../../web_applications/resourceType';
import { getResultsOfSearch } from '../search/selectors';

export const inventory_export = ({ inventory_export }: State) => inventory_export;

export const getPaginationForRestfulApis = createSelector<State, { searchId: ?string }, ?PaginationInfo, _, _>(
  (state, props) => props.searchId,
  state => state.pagination.requests,
  (searchId, requests) => {
    if (searchId && requests[restful_apis]) {
      return requests[restful_apis]['search_id-' + searchId];
    } else if (requests[restful_apis]) {
      return requests[restful_apis]['-'];
    }
  },
);

export const getPaginationForNetworkServices = createSelector<State, { searchId: ?string }, ?PaginationInfo, _, _>(
  (state, props) => props.searchId,
  state => state.pagination.requests,
  (searchId, requests) => {
    if (searchId && requests[network_services]) {
      return requests[network_services]['search_id-' + searchId];
    } else if (requests[network_services]) {
      return requests[network_services]['-'];
    }
  },
);

export const getPaginationForCloudResources = createSelector<State, { searchId: ?string }, ?PaginationInfo, _, _>(
  (state, props) => props.searchId,
  state => state.pagination.requests,
  (searchId, requests) => {
    if (searchId && requests[cloud_resources]) {
      return requests[cloud_resources]['search_id-' + searchId];
    } else if (requests[cloud_resources]) {
      return requests[cloud_resources]['-'];
    }
  },
);

export const getPaginationForWebApplications = createSelector<State, { searchId: ?string }, ?PaginationInfo, _, _>(
  (state, props) => props.searchId,
  state => state.pagination.requests,
  (searchId, requests) => {
    if (searchId && requests[web_applications]) {
      return requests[web_applications]['search_id-' + searchId];
    } else if (requests[web_applications]) {
      return requests[web_applications]['-'];
    }
  },
);

export const isAnyPaginationFetched = createSelector<State, { searchId: ?string }, Array<?string>, _, _, _, _, _>(
  getPaginationForRestfulApis,
  getPaginationForNetworkServices,
  getPaginationForCloudResources,
  getPaginationForWebApplications,
  (restfulApispagination, networkServicesPagination, cloudResourcesPagination, webApplicationsPagination) =>
    [restfulApispagination, networkServicesPagination, cloudResourcesPagination, webApplicationsPagination].reduce(
      (status, pagination) => {
        if (pagination && !pagination.loading && !pagination.error) {
          return status.concat(pagination.type);
        } else {
          return status;
        }
      },
      [],
    ),
);

export const isAnyPaginationFailed = createSelector<
  State,
  { searchId: ?string },
  Array<{
    resource_type: ?string,
    cursor: ?string,
  }>,
  _,
  _,
  _,
  _,
  _,
>(
  getPaginationForRestfulApis,
  getPaginationForNetworkServices,
  getPaginationForCloudResources,
  getPaginationForWebApplications,
  (restfulApispagination, networkServicesPagination, cloudResourcesPagination, webApplicationsPagination) =>
    [restfulApispagination, networkServicesPagination, cloudResourcesPagination, webApplicationsPagination].reduce(
      (status, pagination) => {
        if (pagination && !pagination.loading && pagination.error) {
          return status.concat({
            resource_type: pagination.type,
            cursor: pagination.next_cursor,
          });
        } else {
          return status;
        }
      },
      [],
    ),
);

export const getFetchedAssetsCount = createSelector<State, { searchId: ?string }, number, _, _, _, _, _>(
  (state, props) => props.searchId,
  state => state.restful_apis,
  state => state.cloud_resources,
  state => state.web_applications,
  state => state.network_services,
  (searchId, restfulApisState, cloudResourcesState, webApplicationsState, networkServicesState) => {
    if (searchId) {
      return [
        values(getResultsOfSearch(searchId, restfulApisState)).length,
        values(getResultsOfSearch(searchId, cloudResourcesState)).length,
        values(getResultsOfSearch(searchId, webApplicationsState)).length,
        values(getResultsOfSearch(searchId, networkServicesState)).length,
      ].reduce<number>((total, resourceIdCount) => {
        return resourceIdCount ? resourceIdCount + total : total;
      }, 0);
    } else {
      return [
        Object.keys(restfulApisState.id).length,
        Object.keys(cloudResourcesState.id).length,
        Object.keys(webApplicationsState.id).length,
        Object.keys(networkServicesState.id).length,
      ].reduce<number>((total, resourceIdCount) => {
        return resourceIdCount ? resourceIdCount + total : total;
      }, 0);
    }
  },
);

export const getTotalAssetsCount = createSelector<State, { searchId: ?string }, number, _, _, _, _, _>(
  getPaginationForRestfulApis,
  getPaginationForNetworkServices,
  getPaginationForCloudResources,
  getPaginationForWebApplications,
  (restfulApispagination, networkServicesPagination, cloudResourcesPagination, webApplicationsPagination) =>
    [
      restfulApispagination,
      networkServicesPagination,
      cloudResourcesPagination,
      webApplicationsPagination,
    ].reduce<number>((total, pagination) => {
      return pagination ? total + pagination.total_count : total;
    }, 0),
);

export const isExportDone = createSelector<State, { searchId: ?string }, boolean, _, _, _, _, _>(
  getPaginationForRestfulApis,
  getPaginationForNetworkServices,
  getPaginationForCloudResources,
  getPaginationForWebApplications,
  (restfulApispagination, networkServicesPagination, cloudResourcesPagination, webApplicationsPagination) =>
    [
      restfulApispagination,
      networkServicesPagination,
      cloudResourcesPagination,
      webApplicationsPagination,
    ].reduce<boolean>((status, pagination) => {
      if (!pagination || (pagination && !pagination.complete)) {
        return false;
      } else {
        return status;
      }
    }, true),
);

export const exportStats = createSelector<
  State,
  { searchId: ?string },
  {
    fetchedCount: number,
    totalCount: number,
    inProgress: boolean,
    resetSagaRequest: boolean,
  },
  _,
  _,
  _,
>(getFetchedAssetsCount, getTotalAssetsCount, inventory_export, (fetchedCount, totalCount, inventory_export) => ({
  fetchedCount,
  totalCount,
  inProgress: inventory_export.inProgress,
  resetSagaRequest: inventory_export.resetSagaRequest,
}));
