// @flow
import config from '@dt/config';
import fetch, { parse, type APIResult as FetchResponse } from '@dt/fetch';
import type { IPRangeList, IPRangeScanList } from './types';

const api = config.horizonApiBaseUrl;
const version = 'v1';

export type IPRangesCreateResponse =
  | {|
      +ip_ranges: IPRangeList,
      +ip_range_scans: IPRangeScanList,
    |}
  // TODO: The backend should support the noraml error workflow.
  //       Currently the backend doesn't provide an error status code for requesting a local IP address range.
  | {| title: $ReadOnlyArray<string> |};

export async function create(params: {|
  +ip_range: string,
|}): FetchResponse<IPRangesCreateResponse> {
  return fetch(`${api}/${version}/ip_range_scans`, {
    method: 'POST',
    body: JSON.stringify(params),
  }).then(parse);
}
