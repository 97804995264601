//@flow

const status = {
  COMPLETED: 'COMPLETED',
  NOT_COMPLETED: 'NOT_COMPLETED',
  UNKNOWN: 'UNKNOWN',
  BEYOND_SUBSCRIPTION: 'BEYOND_SUBSCRIPTION',
};

export default status;

export type TaskStatusEnum = $Keys<typeof status>;
