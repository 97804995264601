// @flow
import { createAction } from 'redux-actions';
import type { UploadAppResponse } from './sagas';

export const appFileAccepted = createAction<'APP_FILE_ACCEPTED', [UploadAppResponse], UploadAppResponse>(
  'APP_FILE_ACCEPTED',
  response => response,
);

export const appFileRejected = createAction<'APP_FILE_REJECTED', [string], string>('APP_FILE_REJECTED', error => error);

export const appFileSubmitted = createAction<
  'APP_FILE_SUBMITTED',
  [File | null, string, string, string, boolean, string],
  {
    file: File | null,
    username: string,
    password: string,
    comments: string,
    isEnterpriseInternal: boolean,
    linkedAppStoreAppId: ?string,
    ...
  },
>('APP_FILE_SUBMITTED', (file, username, password, comments, isEnterpriseInternal, linkedAppStoreAppId) => ({
  file,
  username,
  password,
  comments,
  isEnterpriseInternal,
  linkedAppStoreAppId,
}));
