//@flow
export type DB = {
  [string]: {
    id: { [string]: { ... }, ... },
  },
};

export default function(
  db: DB,
  insertData: { +[string]: mixed },
  fieldNameToTypeNameMap: { +[string]: string, ... },
): DB {
  const cloneDb = { ...db };
  for (const key of Object.keys(insertData)) {
    const index_name = fieldNameToTypeNameMap[key];
    const top_level_item = insertData[key];
    if (!top_level_item || !Array.isArray(top_level_item)) {
      continue;
    }
    if (!cloneDb[index_name]) {
      cloneDb[index_name] = { id: {} };
    } else {
      cloneDb[index_name] = { id: { ...cloneDb[index_name].id } };
    }
    const index = cloneDb[index_name].id;
    for (const item of top_level_item) {
      if (!item || typeof item !== 'object') {
        continue;
      }
      const id = item.id;
      if (!id || typeof id !== 'string') {
        continue;
      }
      index[id] = item;
    }
  }
  return cloneDb;
}
