// @flow
import { type Saga } from 'redux-saga';
import { type ActionType } from 'redux-actions';
import { all, call, spawn, put, takeEvery } from 'redux-saga/effects';

import { getAllAssetGroups, getAssetGroupDetails } from './resource_fetch.sagas';
import {
  takePatternAndFetch,
  watchForLoadMoreAndFetchNextPage,
  handleNormalizedResponse,
} from '../resource_fetch/sagas';
import {
  assetGroupsListMounted,
  assetGroupsDetailsMounted,
  assetGroupsReceived,
  assetGroupsWizardListMounted,
  assetGroupsCreate,
  assetGroupsCreateSuccess,
  assetGroupsCreateError,
  assetGroupChangePolicy,
  assetGroupDeleteClicked,
} from './actions';
import resourceType from './resourceType';
import watchForResourceFetchingMemberships, { assetGroupMembershipCreateSaga } from './memberships/sagas';
import { getPolicyList } from '../policies/resource_fetch.sagas';
import { callPromise } from '@dt/redux-saga-wrapped-effects';
import { asset_groups } from '@dt/horizon-api';
import { assetGroupMembershipCreate } from './memberships/actions';

export default function* watchForResourceFetching(): Saga<void> {
  yield all([
    spawn(watchForResourceFetchingMemberships),
    call(takePatternAndFetch, assetGroupsListMounted.toString(), function*(): Saga<void> {
      yield call(getAllAssetGroups, {});
    }),
    call(takePatternAndFetch, assetGroupsWizardListMounted.toString(), function*(): Saga<void> {
      yield call(getPolicyList, {});
    }),
    call(watchForLoadMoreAndFetchNextPage, resourceType, function*({ cursor }): Saga<void> {
      yield call(getAllAssetGroups, { cursor });
    }),

    call(takePatternAndFetch, assetGroupsDetailsMounted.toString(), function*(
      action: ActionType<typeof assetGroupsDetailsMounted>,
    ): Saga<void> {
      yield call(getAssetGroupDetails, action.payload.assetGroupId);
    }),
    takeEvery(assetGroupsCreate.toString(), assetGroupsCreateSaga),
    takeEvery(assetGroupDeleteClicked.toString(), assetGroupsDeleteSaga),
    takeEvery(assetGroupChangePolicy.toString(), assetGroupPatchSaga),
  ]);
}

function* assetGroupsCreateSaga(action: ActionType<typeof assetGroupsCreate>): Saga<void> {
  try {
    const payload = action.payload;

    const result = yield* callPromise(asset_groups.create, {
      name: payload.name,
      description: payload.description,
      attached_policy_id: payload.attached_policy_id,
    });

    if (result._type === 'error') {
      throw new Error(result.title);
    }

    let asset_group = result.body.asset_groups && result.body.asset_groups[0];
    if (asset_group) {
      yield put(assetGroupsReceived([asset_group]));
    } else {
      throw new Error('Error while creating asset group');
    }

    // Check if need to assign any asset
    if (payload.assets) {
      yield call(
        assetGroupMembershipCreateSaga,
        assetGroupMembershipCreate({
          assetGroupId: asset_group.id,
          assets: payload.assets,
        }),
      );
    }

    yield put(assetGroupsCreateSuccess(asset_group.id));
  } catch (e) {
    yield put(assetGroupsCreateError('Could not add asset group'));
  }
}

function* assetGroupsDeleteSaga(action: ActionType<typeof assetGroupDeleteClicked>): Saga<void> {
  const payload = action.payload;
  yield* callPromise(asset_groups.remove, payload);
}

function* assetGroupPatchSaga(action: ActionType<typeof assetGroupChangePolicy>): Saga<void> {
  const payload = action.payload;

  const response = yield* callPromise(asset_groups.patch, payload.assetGroupId, {
    attached_policy_id: payload.policyId,
  });

  if (response._type === 'error') {
    throw new Error(response.title);
  }

  yield call(handleNormalizedResponse, response.body);
}
