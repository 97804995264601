// @flow
import { type Saga } from 'redux-saga';
import { shared_links } from '@dt/horizon-api';
import { callPromise } from '@dt/redux-saga-wrapped-effects';
import { putResolve, call } from 'redux-saga/effects';

import { withCache, handleNormalizedResponse } from '../resource_fetch/sagas';
import { paginationBeginRequest, paginationEndRequest } from '@dt/pagination';

import resourceType from './resourceType';

export const getSharedLinks = withCache<{ ... }>('shared_links', function* getSharedLinks(params): Saga<void> {
  yield putResolve(paginationBeginRequest(resourceType, params));
  const response = yield* callPromise(shared_links.list);

  if (response._type === 'error') {
    throw new Error(response.title);
  }

  yield call(handleNormalizedResponse, response.body);
  if (response.body.pagination_information) {
    yield putResolve(paginationEndRequest(resourceType, params, response.body.pagination_information));
  }
});
