//@flow
import type { APIOperation, RestfulAPI } from '@dt/horizon-api';

import { getAggregatedRelevance } from '../policy_violations/util';
import {
  type PolicyViolationWithAffectedComponentList,
  type PolicyViolationWithAffectedComponent,
} from '../policy_violations/types';

import type { APIOperationDecorated } from './types';

export function decorate(
  operation: APIOperation,
  restful_api: RestfulAPI,
  policy_violations_decorated: PolicyViolationWithAffectedComponentList,
): APIOperationDecorated {
  const matching_policy_violations_decorated = (operation.policy_violation_ids || [])
    .map<PolicyViolationWithAffectedComponent | void>(id => policy_violations_decorated.find(task => task.id === id))
    .filter(Boolean);

  return {
    ...operation,
    restful_api_id: restful_api.id,
    policy_violations_decorated: matching_policy_violations_decorated,
    aggregated_relevance: getAggregatedRelevance({
      policy_violations_decorated: matching_policy_violations_decorated,
    }),
  };
}
