// @flow
import type { GenericParamsType } from './types';

const sortByKey = (a, b) => {
  if (a[0] > b[0]) return 1;
  if (a[0] < b[0]) return -1;

  // this should never happen because first argument is the key, and the key is unique on the object
  return 0;
};

const convertMixedToString = (mixedVar: mixed): string => {
  if (typeof mixedVar === 'string') return mixedVar;
  if (typeof mixedVar === 'number') return mixedVar.toString();
  if (typeof mixedVar === 'boolean') return mixedVar ? 'true' : 'false';
  return '';
};

// Generate an unique string based on the params given
// It sorts first to cover all cases where object shallow comparison succeeds
export const getContract = (params: GenericParamsType) =>
  Object.entries(params)
    .sort(sortByKey)
    .map<string>(([key, value]) => `${key}:${convertMixedToString(value)}`)
    .join(';');

// shallowEqual copied from redux repo -> https://raw.githubusercontent.com/reduxjs/react-redux/master/src/utils/shallowEqual.js
const hasOwn = Object.prototype.hasOwnProperty;

function is(x, y) {
  if (x === y) {
    return x !== 0 || y !== 0 || 1 / x === 1 / y;
  } else {
    return x !== x && y !== y;
  }
}

export function shallowEqual(objA: { ... }, objB: { ... }): boolean {
  if (is(objA, objB)) return true;

  if (typeof objA !== 'object' || objA === null || typeof objB !== 'object' || objB === null) {
    return false;
  }

  const keysA = Object.keys(objA);
  const keysB = Object.keys(objB);

  if (keysA.length !== keysB.length) return false;

  for (let i = 0; i < keysA.length; i++) {
    if (!hasOwn.call(objB, keysA[i]) || !is(objA[keysA[i]], objB[keysA[i]])) {
      return false;
    }
  }

  return true;
}

export function refEqual(objA: mixed, objB: mixed): boolean {
  return objA === objB;
}
