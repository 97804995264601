// @flow
import React, { memo } from 'react';
import { SharedLinksButtonPolicyViolation } from './SharedLinksButtonPolicyViolation';
import { SharedLinksButtonAsset } from './SharedLinksButtonAsset';
import { SharedLinksButtonMobileApplication } from './SharedLinksButtonMobileApplication';
import { SharedLinksButtonSecurityFinding } from './SharedLinksButtonSecurityFinding';
import { SharedLinksButtonOpenscanMobileApplication } from './SharedLinksButtonOpenscanMobileApplication';
import { SharedLinksButtonOnboarding } from './SharedLinksButtonOnboarding';

type CommonProps = {|
  +variant?: 'text' | 'outlined' | 'contained',
  +size?: 'small' | 'medium' | 'large',
|};

type Props =
  | {|
      ...CommonProps,
      +policy_violation_id: string,
    |}
  | {|
      ...CommonProps,
      +web_application_id: string,
    |}
  | {|
      ...CommonProps,
      +restful_api_id: string,
    |}
  | {|
      ...CommonProps,
      +network_service_id: string,
    |}
  | {|
      ...CommonProps,
      +cloud_resource_id: string,
    |}
  | {|
      ...CommonProps,
      +graphql_api_id: string,
    |}
  | {|
      ...CommonProps,
      +mobile_application_id: string,
    |}
  | {|
      ...CommonProps,
      +mobile_application_id: string,
      +security_finding_id: string,
    |}
  | {|
      ...CommonProps,
      +openscan_mobile_application_id: string,
      +openscan_mobile_application_name: string,
    |}
  | {|
      ...CommonProps,
      +onboarding: boolean,
      +useManagementPath?: boolean,
      +summaryText?: boolean,
      +onboardingVariant?: 'AMAZON_WEB_SERVICES',
    |};

/*
 * Facade component used to aggregate a common inteface to allow the user to create a shared link.
 *
 * Shared links have different boundaries and thus have different endpoints used to create a shared link.
 *
 * @example - To share a policy violation.
 *     <SharedLinksButton policy_violation_id={policy_violation.id} />
 *
 * @example - To share a web application.
 *     <SharedLinksButton web_application_id={web_application.id} />
 *
 * @example - To share a restful api.
 *     <SharedLinksButton restful_api_id={restful_api.id} />
 *
 * @example - To share a network service.
 *     <SharedLinksButton network_service_id={network_service.id} />
 *
 * @example - To share a cloud resource.
 *     <SharedLinksButton cloud_resource_id={cloud_resource.id} />
 *
 * @example - To share a graphql api.
 *     <SharedLinksButton graphql_api_id={graphql_api.id} />
 *
 * @example - To share a mobile application.
 *     <SharedLinksButton mobile_application_id={mobile_application.id} />
 *
 * @example - To share a security finding.
 *     <SharedLinksButton
 *       mobile_application_id={mobile_application.id}
 *       security_finding_id={security_finding.id} />
 *
 * @example - To share a openscan mobile applications.
 *     <SharedLinksButton
 *       openscan_mobile_application_id={openscan_mobile_application.id}
 *       openscan_mobile_application_name={openscan_mobile_application.name} />
 *
 * @example - To share onboarding cloud authenticators.
 *     <SharedLinksButton onboarding />
 */
const SharedLinksButtonComponent = function SharedLinksButton(props: Props) {
  if (props.policy_violation_id) {
    return (
      <SharedLinksButtonPolicyViolation
        policy_violation_id={props.policy_violation_id}
      />
    );
  } else if (props.web_application_id) {
    return (
      <SharedLinksButtonAsset web_application_id={props.web_application_id} />
    );
  } else if (props.restful_api_id) {
    return <SharedLinksButtonAsset restful_api_id={props.restful_api_id} />;
  } else if (props.network_service_id) {
    return (
      <SharedLinksButtonAsset network_service_id={props.network_service_id} />
    );
  } else if (props.cloud_resource_id) {
    return (
      <SharedLinksButtonAsset cloud_resource_id={props.cloud_resource_id} />
    );
  } else if (props.graphql_api_id) {
    //TODO: Needs some work to get gql assets working this was discussed briefly in the PR for POR-2006 and will be added in a later JIRA.
    //return <SharedLinksButtonAsset cloud_resource_id={props.graphql_api_id} />;
    return null;
  } else if (props.security_finding_id && props.mobile_application_id) {
    return (
      <SharedLinksButtonSecurityFinding
        mobile_application_id={props.mobile_application_id}
        security_finding_id={props.security_finding_id}
      />
    );
  } else if (props.mobile_application_id) {
    return (
      <SharedLinksButtonMobileApplication
        mobile_application_id={props.mobile_application_id}
      />
    );
  } else if (props.openscan_mobile_application_id) {
    return (
      <SharedLinksButtonOpenscanMobileApplication
        openscan_mobile_application_id={props.openscan_mobile_application_id}
        openscan_mobile_application_name={
          props.openscan_mobile_application_name
        }
      />
    );
  } else if (props.onboarding) {
    return (
      <SharedLinksButtonOnboarding
        summaryText={props?.summaryText}
        onboardingVariant={props?.onboardingVariant}
        useManagementPath={props.useManagementPath || false}
      />
    );
  }

  // Should never happen, props are explicitly required.
  // Any malformed props will be caught by the type checker.
  throw new Error('No shared link component found.');
};

export const SharedLinksButton = memo<Props>(SharedLinksButtonComponent);
