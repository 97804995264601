// @flow
import { type SearchResults } from '@dt/user-api/openscan';

import { receivedSearchResults } from './../actions';

export type SearchResultsState = { [key: string]: ?SearchResults, ... };

export default function(
  state: SearchResultsState = {},
  action: {
    type: string,
    payload: {
      search: string,
      search_results: SearchResults,
      ...
    },
    ...
  },
): SearchResultsState {
  if (action.type !== receivedSearchResults.toString()) {
    return state;
  }

  return {
    ...state,
    [action.payload.search]: action.payload.search_results,
  };
}
