//@flow
import React, { PureComponent, type Node } from 'react';

import type { FindingTargetStatusEnum } from '@dt/enums/FindingTargetStatusEnum';

import CardLine from './../CardLine';
import TargetStatus from './../TargetStatus';

type Props = {
  status: ?FindingTargetStatusEnum,
  children: Node,
  date?: string | Date,
};

export default class CardStatusLine extends PureComponent<Props> {
  render() {
    const { status, children, date } = this.props;

    if (!status) {
      return null;
    }

    return (
      <CardLine indicator={<TargetStatus status={status} />} date={date}>
        {children}
      </CardLine>
    );
  }
}
