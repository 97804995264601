//@flow
import gql from 'graphql-tag';

const list = gql`
  query BulkActionMoveToAssetGroupList(
    $cursor: String
    $filter_by_status: BulkActionsStatusEnum
  ) {
    bulk_action_move_to_asset_group_list(
      cursor: $cursor
      filter_by_status: $filter_by_status
    ) {
      pagination_information {
        next_cursor
        total_count
      }
      bulk_actions {
        action_type
        completed_tasks_count
        date_completed
        date_created
        id
        move_to_group_result
        secure_share_policy_violations_result
        status
        total_tasks_count
      }
    }
  }
`;

const create = gql`
  mutation BulkActionMoveToAssetGroupCreate(
    $body: BulkActionMoveToAssetGroupParams!
  ) {
    bulk_action_move_to_asset_group_create(body: $body) {
      action_type
      status
    }
  }
`;

const shadow_assets_triage_actions = gql`
  mutation ShadowAssetsTriageActions($body: ShadowAssetsTriageActionsParams) {
    shadow_assets_triage_actions(body: $body) {
      action_type
      status
    }
  }
`;

export default {
  assets: {
    move_to_asset_group_actions: {
      list,
      create,
    },
    shadow_assets_triage_actions,
  },
};
