// @flow
import { call } from 'redux-saga/effects';
import { paginateToEnd } from '../resource_fetch/sagas';
import { getIpRangesList } from './resource_fetch.sagas';
import resourceType from './resourceType';

import type { Saga } from 'redux-saga';

export const getAllIpRanges = function*(): Saga<void> {
  // NOTE: If we want to paginate correctly we need to redesign this page slighlty.
  yield call(paginateToEnd, getIpRangesList, resourceType, {});
};
